const HeaderReducer = {
    top_menu:[],
}

export default(state = HeaderReducer,action) =>{
    switch (action.type) {
        case 'PUB_INIT_TOPMENU':
            
            return {
                ...state,
                top_menu:action.data
            }
       
        default:
            return state;
            
    }
}