import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { image_url } from '../../../config/Api'
import { getMostPopularNews } from '../../actions/Publication';
import Timestamp from 'react-timestamp';

class FifthSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: []
    }
  }

  componentDidMount = () => {
    const requestData = {
      take: 8,
      skip: '',
    };
    const req = this.props.getMostPopularNews(requestData).then((data) => {
      this.setState({
        content: data
      })
    });


  }

  static getDerivedStateFromProps(props, state) {

    return {
      home_layout: props.home_layout
    }
  }

  render() {
    const { content } = this.state;

    return (

      <section className="most-popular">

        <div className="container">

          <div className="section-title-one">

            <h1>Most Popular <i className="fa fa-long-arrow-right"></i></h1>

          </div>

          <div className="row">


            {content.length ? <Fragment>

              {content.map((row, key) => {
                return <Fragment key={key}>


                  <div className="col-md-3">

                    <div className="our-latest-news-single most-popular-single">


                      {row.image && <Fragment>
                        <img src={`${row.image.feature_image}`} />
                      </Fragment>}


                      <div className="our-latest-news-content">

                        {/* <h6>News <span>{row.published_time}</span></h6> */}
                        <h6>News <span>{row.published_time&&<Timestamp date={row.published_time} format='full' />}</span></h6>



                        <h3>
                          {row.title.length > 75 &&
                            <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`}>{row.title.substring(0, 72)}...</Link>
                          }
                          {row.title.length <= 75 &&
                            <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`}>{row.title}</Link>
                          }
                        </h3>

                        {row.short_description.length > 107 && <p>
                          {row.short_description.substring(0, 104)}... </p>
                        }
                        {row.short_description.length <= 107 && <p>
                          {row.short_description} </p>
                        }

                      </div>

                    </div>

                  </div>


                </Fragment>
              })}
            </Fragment> : ''}


          </div>

        </div>

      </section>
    );
  }
}


const mapStateToProps = (state) => ({
  setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
  getMostPopularNews: (requestData) => dispatch(getMostPopularNews(requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FifthSection);