import React, { Component, Fragment } from 'react';
import Marquee from 'react-fast-marquee';
import { connect } from 'react-redux';
import { setLayout, setPath, setPathLayout } from '../../actions/Utils';
class NewsBulletin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            home_layout: {
                content: null
            },

        }

    }
    componentDidMount=()=>{
        //console.log("hello ticker");
        /* $('.js-conveyor-3').jConveyorTicker({
            reverse_elm: true
          }); */
    }
    static getDerivedStateFromProps(props, state) {

        return {
            home_layout: props.home_layout
        }
    }

    render() {
        const content = this.state.home_layout.content;
        var section_content = [];
        if (content.page_data) {

            var page_section = content.page_data.section;

            const section_data = page_section.find((row) => {
                if (row.name.toLowerCase() == 'news buletin') {
                    return true;
                }
                else {
                    return false;
                }
            });
            if (section_data) {
                var section_content = section_data.contents;
                
            }
        }

        return (
            <section className="update-news">


                    <div className="d-wrap">

                        <aside className="d-playbox">

                            <div className="d-demo-wrap">

                                <div className="jctkr-label">

                                    Latest 

                                </div>

                                <div className="js-conveyor-3 jctkr-wrapper jctkr-initialized" ref={el => this.el = el}>

                                    
                                        <Marquee 
                                        pauseOnHover={true}
                                        speed={50}
                                        gradient={false}
                                        >
                                            {section_content && section_content.map((row,key) => {
                                                return (
                                                    <Fragment key={key}>

                                                        <p>

                                                            <a href={`${process.env.PUBLIC_URL}`+row.slug}>
                                                                <i className="fa fa-chevron-double-right"></i>
                                                                {row.title}
                                                            </a>
                                                            {
                                                                row.is_hot_news==1?
                                                                <span className="just_in">Just In</span>
                                                                :''
                                                            }
                                                        </p>
                                                    </Fragment>
                                                )
                                            })}
                                        </Marquee>
                                    

                                </div>

                            </div>

                        </aside>

                    </div>
           
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    headers: state.header,
    setting: state.utils.setting,
    home_layout: state.contents.home_layout,
})

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(NewsBulletin);