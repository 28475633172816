/* import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app"; */
import $ from 'jquery';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getAllNavigation, getHeaderNavigation, getTopNavigation, setLayout, setPath, setPathLayout, setSearchKey, retrieveToken } from '../../../actions/Utils';
import Logo from '../../../img/logo.svg';
import {AuthConfig as authconfig} from '../../../config/sso.config.ts';


/* const firebaseConfig = {
    apiKey: "AIzaSyC2F98cN5bkPaOX_QXO27y4t-u_IWrV2wI",
    authDomain: "icab-1970a.firebaseapp.com",
    projectId: "icab-1970a",
    storageBucket: "icab-1970a.appspot.com",
    messagingSenderId: "1026076957928",
    appId: "1:1026076957928:web:e79f79e16db1b1e556fd5d",
    measurementId: "G-7ELGC2ZT4E"
  };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app) */

class Commonheader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            header: {},
            top_menu: [],
            header_menu: [],
            megamenu_class: style.display_none,
            search: '',
            accept_cookies: false
        }
        this.megaMenuShow = this.megaMenuShow.bind(this);
        this.megaMenuHide = this.megaMenuHide.bind(this);
    }

    componentDidMount = () => {
        console.log("===================================================================");
        $(document).ready(function ($) {
            var wHeight = window.innerHeight;
            //search bar middle alignment
            $('#mk-fullscreen-searchform').css('top', wHeight / 2);
            //reform search bar
            $(window).resize(function () {
                wHeight = window.innerHeight;
                $('#mk-fullscreen-searchform').css('top', wHeight / 2);
            });
            // Search
            $('#search-button').click(function (e) {
                e.preventDefault();
                $("div.mk-fullscreen-search-overlay").addClass("mk-fullscreen-search-overlay-show");
            });
            $("a.mk-fullscreen-close").click(function (e) {
                e.preventDefault();
                $("div.mk-fullscreen-search-overlay").removeClass("mk-fullscreen-search-overlay-show");
            });
        });
        this.props.getTopNavigation({ navigation_name: 'top menu' }).then((res) => {
            this.setState({
                top_menu: res
            })
        });
        this.props.getHeaderNavigation({ navigation_name: 'header' }).then((res) => {
            this.setState({
                header_menu: res
            })
        });
        var accept_cookiess = Cookies.get('accept_cookies');
        if (accept_cookiess && accept_cookiess == true) {
            this.setState({ accept_cookies: true });
        }

        var login_token = Cookies.get('login_token');
        if(!login_token || login_token !== localStorage.getItem('token'))
        {
            localStorage.removeItem("token");
            localStorage.removeItem("member_id");
            localStorage.removeItem("member_pass");
        }
        this.authLogin();
    }

    authLogin(){
        let isLoggedIn = this.checkCredentials();
        let i = window.location.href.indexOf('code');
        if (!isLoggedIn && i != -1) {
            this.props.retrieveToken(window.location.href.substring(i + 5)).then((res)=>{debugger;
                var expireDate = new Date().getTime() + (authconfig.sessionTime * res.expires_in);
                Cookies.set("access_token", res.access_token, new Date(expireDate));
                Cookies.set("login_token", res.access_token, new Date(expireDate));
                localStorage.setItem('token', res.access_token);
                // console.log('Obtained Access token');
                const decodedToken = jwtDecode(res.access_token);
                // Extract the "user_name" claim from the decoded token
                const userNameClaim = decodedToken.user_name;

                // Extract just "1819" from the "user_name" claim
                const userName = userNameClaim.split('(')[0];
                localStorage.setItem('member_id', userName);
                window.location.href = window.location.origin+"/member/dashboard";
            })
          }
    }

    loginByICABAuthenticationSystem() {    
        window.location.href = authconfig.login_url;
      }

    

    checkCredentials =() =>{
        return Cookies.get('access_token') == '';
      }

    handleChange = event => {
        const { value, name } = event.target;
        this.setState({ [name]: value });
    };

    handleSubmit = event => {
        event.preventDefault();
        const { history, match } = this.props;
        const { search } = this.state;
        this.setState({ search: '' });
        history.push(`${process.env.PUBLIC_URL}${match.url}search/${(search)}`);
        $("div.mk-fullscreen-search-overlay").removeClass("mk-fullscreen-search-overlay-show");
    };
    acceptCookies = (e) => {
        e.preventDefault();
        Cookies.set('accept_cookies', 1,{ expires: 365 });
        this.setState({ accept_cookies: true });
    }
    static getDerivedStateFromProps(props, state) {

        return {
            header: props.headers
        }
    }



    megaMenuShow = () => {
        // console.log("Hello Mobarak Yes hsow")
        this.setState({
            megamenu_class: style.display_block,
        })
    }
    megaMenuHide = () => {
        this.setState({
            megamenu_class: style.display_none,
        })
    }

    handleLogout = () =>{
        try{
            localStorage.removeItem("token");
            localStorage.removeItem("member_id");
            localStorage.removeItem("member_pass");
            Cookies.remove('access_token')
            Cookies.remove('login_token')
        }catch(e){

        }
        // this.props.history.push(`${process.env.PUBLIC_URL}/`)
        window.location.href=authconfig.logout_url
    }

    render() {

        const top_menu = this.props.headers.top_menu;
        const header_menu = this.props.headers.header_menu;
        // console.log("data header =", this.state);
        const { megamenu_class } = this.state;

        return (
            <Fragment>

                {/* <!-- Cookies Start --> */}
                {
                    !this.state.accept_cookies ?
                        <div className="alert alert-warning alert-dismissible fade show cookies" role="alert">

                            <div className="container">
                                <p>
                                    This site uses cookies and other tracking technologies to assist with navigation and your ability to provide feedback, analyse your use of our products and services, assist with our promotional and marketing efforts, and provide content from third parties.

                                </p>

                                <a href="" onClick={this.acceptCookies.bind(this)} className="btn btn-primary"><i className="fa fa-check"></i> Accept Cookies</a>

                                {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">

                            <i className="fa fa-times-circle"></i>

                            </button> */}
                            </div>

                        </div>
                        :

                        ''
                }


                {/*  <!-- Cookies End --> */}

                <header>

                    <div className="header-top">

                        <div className="container">

                            <div className="row">


                                <div className="col-md-7">

                                    <ul className="nav">
                                        <li> <Link to={`${process.env.PUBLIC_URL}/`} className="nav-link">Home</Link> </li>
                                        {top_menu && top_menu.map((row, key) => {
                                            return (
                                                <Fragment key={key}>
                                                    <li>
                                                        {row.link_type == 1 && <Link to={{ pathname: `${process.env.PUBLIC_URL}${row.url}` }} className="nav-link">{row.title}</Link>}
                                                        {row.link_type == 0 && <Link to={{ pathname: `${row.url}` }} target={row.url_target ? row.url_target : '_blank'} className="nav-link">{row.title} </Link>}
                                                    </li>
                                                </Fragment>
                                            )
                                        })}
                                    </ul>

                                </div>

                                <div className="col-md-5">

                                    <div className="searchbar">

                                        <div className="overlay">

                                            <div className="mk-search-trigger mk-fullscreen-trigger" href="" id="search-button-listener">
                                                <div id="search-button"><i className="fa fa-search"></i></div>
                                            </div>

                                            <div className="mk-fullscreen-search-overlay" id="mk-search-overlay">

                                                <div href="" className="mk-fullscreen-close" id="mk-fullscreen-close-button"><i className="fa fa-times"></i></div>
                                                <div id="mk-fullscreen-search-wrapper">

                                                    <form method="get" id="mk-fullscreen-searchform" onSubmit={this.handleSubmit}>

                                                        <input type="text" name='search' value={this.state.search}
                                                            required onChange={this.handleChange} placeholder="Search..." id="mk-fullscreen-search-input" />
                                                        <i className="fa fa-search fullscreen-search-icon">
                                                            <input onClick={this.handleSubmit} type="submit" value="" /></i>

                                                    </form>

                                                </div>

                                            </div>

                                        </div>

                                    </div>


                                    <div className="social-top">

                                        <a href="https://www.facebook.com/CA.Bangladesh" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>

                                        <a href="https://www.youtube.com/channel/UCRI2aiAKwB4YsMaJtMM60gQ" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i></a>

                                        <a href="https://www.linkedin.com/school/the-institute-of-chartered-accountants-of-bangladesh/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>

                                        <a href="https://www.instagram.com/ca_bangladesh/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>

                                    </div>
                                    <ul className="log-reg nav">

                                        {localStorage.getItem('token')&&<li>
                                            {/* <a href="javascript:void(0)" onClick={()=>this.handleLogout()}>Logout</a> */}
                                            <Link to={`${process.env.PUBLIC_URL}/member/dashboard`}>My ICAB</Link>
                                            <ul className="dashboard-user-info">
                                                <Link to={`${process.env.PUBLIC_URL}/member/change-password`}>Change Password</Link>
                                                <Link to={ '' } onClick={this.handleLogout.bind(this)}>Logout</Link>
                                            </ul>
                                        </li>}
                                        {!localStorage.getItem('token')&&<li>
                                            <Link  to={`${process.env.PUBLIC_URL}/login`}>Member's Login</Link>
                                            {/* <div onClick={this.loginByICABAuthenticationSystem} style={{ cursor: 'pointer' }}>
                                                Member's Login
                                            </div> */}
                                        </li>}
                                       
                                        {/* /
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/register`}>Register</Link>
                                        </li> */}

                                    </ul>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="sticky-header">

                        <div className="logo">

                            <Link to={`${process.env.PUBLIC_URL}/`}><img src={Logo} /></Link>

                        </div>

                        <div className="container">

                            <div className="main-menu">

                                <div id="main-nav" className="stellarnav">

                                    <nav>

                                        <ul>
                                            {header_menu && header_menu.map((hrow, hkey) => {
                                                return (
                                                    <Fragment key={hkey}>
                                                        <li >
                                                            {hrow.sub_nav.length > 0 ? <Link to={'#'} >{hrow.title}</Link> : ''}
                                                            {hrow.sub_nav.length == 0 ?
                                                                <Fragment>
                                                                    {hrow.link_type == 1 && <Link to={{ pathname: `${process.env.PUBLIC_URL}${hrow.url}` }} >{hrow.title}</Link>}
                                                                    {hrow.link_type == 0 && <Link to={{ pathname: `${hrow.url}` }} target={hrow.url_target ? hrow.url_target : '_blank'}>{hrow.title}</Link>}
                                                                </Fragment> : ''}
                                                            {hrow.sub_nav.length ? <Fragment>

                                                                <ul style={megamenu_class}>

                                                                    <div className="mega-menu">
                                                                        <div className="container">
                                                                            <div className="row">
                                                                                <div className="col-md-3 mega-menu-left-content">

                                                                                    <h3>{hrow.title}</h3>

                                                                                    <p>
                                                                                        {hrow.short_description}
                                                                                    </p>

                                                                                    {hrow.link_type == 1 && <Link to={{ pathname: `${process.env.PUBLIC_URL}${hrow.url}` }}>Learn More<i className="fa fa-angle-right"></i></Link>}
                                                                                    {hrow.link_type == 0 && <Link to={{ pathname: `${hrow.url}` }} target={hrow.url_target ? hrow.url_target : '_blank'}>Learn More<i className="fa fa-angle-right"></i></Link>}

                                                                                    <div className="triangle"></div>

                                                                                </div>

                                                                                <div className="col-md-9">
                                                                                    <div className="mega-menu-sub">
                                                                                        {/* <h3>Governance</h3> */}
                                                                                        <div className="mega-menu-grid">
                                                                                            {hrow.sub_nav.map((hsrow, hskey) => {
                                                                                                return (
                                                                                                    <Fragment key={hskey}>
                                                                                                        <div className="mega-menu-block">
                                                                                                            <ul>
                                                                                                                <li className={hsrow.sub_nav.length ? "parent-menu" : 'parent-menu'}>

                                                                                                                    <Fragment>
                                                                                                                        {hsrow.link_type == 1 && <Link to={`${process.env.PUBLIC_URL}${hsrow.url}`} className={hsrow.sub_nav.length ? 'menu_parent' : ''}>{hsrow.title}</Link>}
                                                                                                                        {hsrow.link_type == 0 && <Link to={{ pathname: `${hsrow.url}` }} className={hsrow.sub_nav.length ? 'menu_parent' : ''} target={hsrow.url_target ? hsrow.url_target : '_blank'}>{hsrow.title}</Link>}

                                                                                                                    </Fragment>
                                                                                                                </li>

                                                                                                                {hsrow.sub_nav.length ?
                                                                                                                    hsrow.sub_nav.map((hs3row, hs3key) => {
                                                                                                                        return (
                                                                                                                            <Fragment key={hs3key}>
                                                                                                                                <li>
                                                                                                                                    {hs3row.link_type == 1 && <Link to={`${process.env.PUBLIC_URL}${hs3row.url}`}>{hs3row.title}</Link>}
                                                                                                                                    {hs3row.link_type == 0 && <Link to={{ pathname: `${hs3row.url}` }} target={hs3row.url_target ? hs3row.url_target : '_blank'}>{hs3row.title}</Link>}


                                                                                                                                </li>
                                                                                                                            </Fragment>
                                                                                                                        )
                                                                                                                    })
                                                                                                                    : ''}
                                                                                                            </ul>
                                                                                                        </div>

                                                                                                    </Fragment>
                                                                                                )
                                                                                            })}
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </div>



                                                                </ul>
                                                            </Fragment> : ''
                                                            }




                                                        </li>
                                                    </Fragment>
                                                )
                                            })}




                                        </ul>

                                    </nav>

                                </div>

                            </div>



                        </div>

                    </div>

                </header>


            </Fragment>

        );
    }
}

const mapStateToProps = (state) => ({
    headers: state.header,
    setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
    setSearchKey: (requestData) => { dispatch(setSearchKey(requestData)) },
    getAllNavigation: (requestData) => dispatch(getAllNavigation(requestData)),
    getTopNavigation: (requestData) => dispatch(getTopNavigation(requestData)),
    getHeaderNavigation: (requestData) => dispatch(getHeaderNavigation(requestData)),
    retrieveToken: (requestData) => dispatch(retrieveToken(requestData)),

})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Commonheader));

const style = {
    display_none: {
        display: 'none'
    },
    display_block: {
        display: 'block'
    }
};