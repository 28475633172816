import React from 'react';
import { Link } from 'react-router-dom';
export default  ({bread_curmbs})=> {
    return (
        <section className="breadcrumb">

        <div className="container">
  
          <ul className="nav">
            {
                bread_curmbs.slice(0).reverse().map((row,key)=>{
                    return <li  key={key}>
                        {
                        bread_curmbs.length-1!=key?
                            <Link to={`${process.env.PUBLIC_URL}`+row.url} >{row.title}</Link>
                        :
                        row.title
                        }
                    </li>
                })
            }
            
          </ul>
          
        </div>
  
      </section>
    )
}
