import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getSetting, NewsContent, setPathLayout,WebNewsCategory,WebNewsFilter } from "../actions/Utils";
import NewsCommon from '../components/news/layout/NewsCommon';

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
            header:{},
            container:{},
            footer:{},
            layout_id:'',
            news_content:{},
            is_filter:false,
            filter_loading:false,
            filter_data:[],
            userfilterdata:{}
        };
        this.textInput = React.createRef();
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const {location} = this.props.history;
        const {slug} = this.props.match.params;
        this.props.NewsContent(slug).then((res)=>{

        });
        this.props.WebNewsCategory();
    }
    componentDidUpdate=(prevProps)=>
    {
        const {slug} = this.props.match.params;
        const {slug:prevSlug} = prevProps.match.params;

        if(prevSlug!=slug)
        {

            this.props.NewsContent(slug).then((res)=>{

            });
        }
    }

    Filter = (requestdata) => {
        console.log('requestdata = ',requestdata);
        this.setState({
            filter_loading:true,
            is_filter:true,
            userfilterdata:requestdata
        })
        this.props.WebNewsFilter(requestdata).then((res)=>{
            this.setState({
                filter_loading:false,
            })
        });
    }
    handleContentLoad = (requestdata) => {
       
        this.setState({
            filter_loading:false,
            is_filter:false
        })
        
    }
    render() {
        const {news_data}=this.props.news_content.content;
        const category = this.props.news_content.category;
        const filterdata = this.props.news_content.filterdata;
        
        
        if(news_data)
        {
            return (
                <Fragment>
                    {
                        <NewsCommon news_data={news_data} 
                            category={category} 
                            ref={this.textInput} 
                            filter={this.Filter} 
                            is_filter={this.state.is_filter}
                            filter_loading={this.state.filter_loading}
                            filterdata={filterdata}
                            handleContentLoad={this.handleContentLoad}
                            userfilterdata={this.state.userfilterdata}/>
                    }
                    
                </Fragment>
            )
        }
        return (
            <Fragment>
                Loading ---
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    news_content:state.contents.news_content,
    utils:state.utils,
    header:state.header,
    contents:state.contents,
    footer:state.footer,
    layout_id:state.utils.layout_id,
    routemenu:state.utils.routemenu,
    setting:state.utils.setting,
    
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
    NewsContent: (requestData) => dispatch(NewsContent(requestData)),
    WebNewsCategory: (requestData) => dispatch(WebNewsCategory(requestData)),
    WebNewsFilter: (requestData) => dispatch(WebNewsFilter(requestData)),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(News));