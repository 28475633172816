import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getSetting } from "../../actions/Utils";
import FirstSection from '../components/commonlist/FirstSection';

class CommonList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
            slug:''
        };
    }
    componentDidMount = () => {

        // console.log('here');
        
        const {slug} = this.props.match.path;
        this.setState({
            slug:slug
        })
    } 
   
    render() {
      
        return (
            <Fragment>
             <FirstSection slug={this.props.match.path}/>
           </Fragment> 
        );
    }
}

const mapStateToProps = state => ({
    utils:state.utils,
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CommonList));