
export const AuthConfig = {
    // Url of the Identity Provider
    issuer: 'https://auth.icab.org.bd/oauth/authorize/',
    token_url: 'https://auth.icab.org.bd/oauth/token',
    login_url: 'https://auth.icab.org.bd/oauth/authorize?response_type=code&client_id=ICAB-WEBSITE&scope=ADMIN',
    logout_url: 'https://auth.icab.org.bd/logout?redirect_url=http://localhost:3000',

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin+'/',

    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: 'server.code',
    clientId: 'ICAB-WEBSITE',

    // Just needed if your auth server demands a secret. In general, this
    // is a sign that the auth server is not configured with SPAs in mind
    // and it might not enforce further best practices vital for security
    // such applications.
    ClientSecret: 'cpd_user_icab',
    sessionTime: 1000000000,
    grant_type: 'authorization_code',

    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: 'ADMIN',

    showDebugInformation: false,
  };
