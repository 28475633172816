import React from 'react';
const Pagination =({total_data,per_page,page_no,prev,next,page_no_click}) =>{
    var total_page=Math.floor(total_data/per_page);
    var page_start=page_no-8<=0?1:page_no-8;
    var page_end=page_start+9>=total_page?total_page:page_start+9;
    let pagination_li=[];

    if(page_start!=1  && total_page>0)
        pagination_li.push(<li key={"first"} className="page-item count-first"><a className="page-link " href="#"  onClick={page_no_click.bind(this,1)}>1</a></li>);

    for(var i=page_start;i<=page_end;i++)
    {
        pagination_li.push(<li key={i} className={"page-item "+(page_no==i?"active":"")}><a className="page-link " href="#"  onClick={page_no_click.bind(this,i)}>{i}</a></li>);
    }
    if(page_end!=total_page  && total_page>0)
        pagination_li.push(<li key={"last"} className="page-item count-last"><a className="page-link " href="#"  onClick={page_no_click.bind(this,total_page)}>{total_page}</a></li>);

    return (
        <nav aria-label="Page navigation example" className="mt-5 mb-5">
            <ul className="pagination  justify-content-center">

                {
                    page_no !=1  && total_page>0?

                    <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous" onClick={prev}>
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                    </a>
                    </li>
                    :''
                }
                {
                    pagination_li
                }
                {
                    page_no!=total_page && total_page>0?
                    <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next" onClick={next}>
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                    </a>
                    </li>
                    :
                    ''
                }

            </ul>
        </nav>
    )
}

export default Pagination
