import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { icabInstance } from '../../config/Api';
const MemberLeftsidebar = (props) => {

  let history = useHistory();
  const [member_data, setmember_data] = useState({});
  
  useEffect(()=>{
    if(localStorage.getItem('member_id'))
    {

      const requestData = {
        EnNo: localStorage.getItem('member_id')
      }
      icabInstance.get('IcabApi/TWCPDevent/GetMemberDetails',{params:requestData }).then(({ data }) => {
        // member_data= data;
        setmember_data(data);
      });
    }
  }, []);
  
  const memberdata = Object.keys(member_data).length ? member_data.payload[0] : false;
  
  
  function handleLogout() {
    localStorage.removeItem("token");
    history.push(`${process.env.PUBLIC_URL}/`);

  }

  if(localStorage.getItem("token") == null) {
    history.push(`${process.env.PUBLIC_URL}/login`);
  }

  return (
    
      Object.keys(memberdata).length>0?
      <ul>
        <li className={props.activemenu === 'dashboard' ? 'active' : ''}>
          <Link to={{ pathname: `/member/dashboard` }}>Dashboard</Link>
        </li>
        <li className={props.activemenu === 'memberprofile' ? 'active' : ''}>
          <Link to={{ pathname: `/member/profile` }}>My Profile</Link>
        </li>
        <li className={props.activemenu === 'member-directory' ? 'active' : ''}>
          <Link to={{ pathname: `/member/member-directory` }}>Members Directory</Link>
        </li>
        <li className={props.activemenu === 'billingstatement' ? 'active' : ''}>
          <Link to={{ pathname: `/member/billing-statement` }}>Billing Statement</Link>
        </li>
        <li className={props.activemenu === 'onlinepayment' ? 'active' : ''}>
          <Link to={{ pathname: `/member/onlinepayment` }}>Online Payment</Link>
        </li>
        {/* <li className={props.activemenu === 'cpdcredithours' ? 'active' : ''}>
          <Link to={{ pathname: `/member/cpd-credit-hours` }}>CPD Credit Hours</Link>
        </li> */}
        {/* <li className={props.activemenu === 'claimcpdcredithours' ? 'active' : ''}>
          <Link to={{ pathname: `/member/cliam-cpd-credit-hours` }}>Claim CPD Credit Hours</Link>
        </li> */}
        {(memberdata.acA_FCA=='ACA')?
        <li className={props.activemenu === 'fcaadmissionprocess' ? 'active' : ''}>
          <Link to={{ pathname: `/member/fca-admission-process` }}>Apply For FCA</Link>
        </li>:''}
        {((memberdata.acA_FCA=='ACA' && memberdata.occoupation_Status=='Not In Practice' ) || (memberdata.acA_FCA=='FCA' && memberdata.occoupation_Status=='Not In Practice' ))?
        <li className={props.activemenu === 'copcertificate' ? 'active' : ''}>
          <Link to={{ pathname: `/member/cop-certificate` }}>Apply for COP</Link>
        </li>:''}
        {((memberdata.acA_FCA=='ACA' && memberdata.occoupation_Status=='In Practice' ) || (memberdata.acA_FCA=='FCA' && memberdata.occoupation_Status=='In Practice' ))?
        <li className={props.activemenu === 'permission-to-train-articled-student' ? 'active' : ''}>
          <Link to={{ pathname: `/member/permission-to-train-articled-student` }}>Permission to train articled student</Link>
        </li>:''}

        {/* <li className={props.activemenu === 'coprenewal' ? 'active' : ''}>

          <Link to={{ pathname: `/member/cop-renewal` }}>Apply for COP Renewal</Link>
        </li> */}
        {/* <li className={props.activemenu === 'goodstandingcertificate' ? 'active' : ''}>
          <Link to={{ pathname: `/member/good-standing-certificate` }}>Apply for Good Standing Certificate</Link>
        </li> */}
        {/* <li className={props.activemenu === 'others' ? 'active' : ''}>
          <Link to={{ pathname: `/member/others` }}>Others</Link>
        </li> */}
        {/* <li>
          <Link to={ '' } onClick={handleLogout}>Logout</Link>
        </li> */}
      </ul>
      :
      ''
    
  )
}


export default MemberLeftsidebar;