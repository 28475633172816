import React, { Fragment } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { image_url } from '../../config/Api';
import { Link } from 'react-router-dom';

const MemberShip = ({sliderdata}) => {
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        arrows:true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 300,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };

  return (
    <section className="global-network">

      <div className="container">
        <div className="section-title-one">
            
            <h1>Global Network</h1>

          </div>

        <div className="membership-slider">
          <Slider {...settings}>

            
            {sliderdata && sliderdata.map((row,key)=>{
              return(
                <Fragment key={key}>
                      <div>

                      <div className="global-network-single">
                        {(row.external_link  && row.internal_link == null)&& <Link to={{pathname:row.external_link}} target="_blank">
                          <img src={`${image_url}${row.long_image}`} /></Link>}
                          {(row.internal_link  && row.external_link == null)&& <Link to={{pathname:row.internal_link}} target="_blank">
                          <img src={`${image_url}${row.long_image}`} /></Link>}
                          {(row.internal_link==null  && row.external_link == null)&& 
                          <img src={`${image_url}${row.long_image}`} />}

                      </div>

                      </div>
                </Fragment>
              )
            })}
          </Slider>

        </div>
      </div>
    </section>

  );
}

export default MemberShip
