import React, { Fragment } from 'react';
import BannerLeft from '../BannerLeft';
import BreadCrumbs from '../BreadCrumbs';
import Form from '../Form';


const Common=({page_data})=> {
    const {title}=page_data;
    const {bread_curmbs}=page_data;
    const{image:banner_img=""}=page_data;
    const{contents}=page_data;
    const {short_description=" "}=contents!=null?contents:{};

    return (
        <Fragment>
            <BannerLeft title={title} short_description={short_description} menu_name={bread_curmbs[0].title} banner={banner_img}/>
            <BreadCrumbs bread_curmbs={bread_curmbs} />
            <Form />
        </Fragment>
    )
}

export default Common
