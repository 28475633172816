import InnerHTML from 'dangerously-set-inner-html';
import React, { Fragment } from 'react';

const Content =({content}) =>{
    return (
        <Fragment>
            {
                content?
                <InnerHTML html={content} />
                :
                ''
            }
        </Fragment>
    )
}

export default Content
