import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getSetting, pageSectionContent, Search, setPathLayout,SetMetaData } from "../actions/Utils";
import '../App.css';
import BreadCrumbs from '../components/page/BreadCrumbs';
import SearchComponent from '../components/Search/search';
import { image_url } from '../config/Api';
import defaultBanner from '../img/banner/bg3.png';

class SearchLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '',
      header: {},
      container: {},
      footer: {},
      layout_id: '',
      contactlayout: null,
      search_data:{},
      pagecontent:{}
    };
  }
  componentDidMount = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const { location } = this.props.history;
    var { pathname } = location;
    const requestData = {
      slug: '/search'
    }
    this.props.pageSectionContent(requestData).then((res) => {
       
        this.setState({
            pagecontent: res
        })

    });

    const {slug} = this.props.match.params;
    this.props.Search(slug).then((data) => {
      this.setState({
        search_data:data
      })


      const metadata = {
        meta_title:'Search | '+slug,
        meta_description:'',
        meta_keywords:'ICAB',
      }
      this.props.SetMetaData(metadata);
      
  });

    

  }

  componentDidUpdate=(prevProps)=>
    {
        const {slug} = this.props.match.params;
        const {slug:prevSlug} = prevProps.match.params;

        if(prevSlug!=slug)
        {

          this.props.Search(slug).then((data) => {
            this.setState({
              search_data:data
            })
          });


          const metadata = {
            meta_title:'Search | '+slug,
            meta_description:'',
            meta_keywords:'ICAB',
          }
          this.props.SetMetaData(metadata);
        }
    }

  setPathLayout = (parms) => {
    this.props.setPathLayout(parms);
  }
  getSetting = async () => {
    let data = await this.props.getSetting();
  }

  getContentData = (menu_id) => {

    const requestData = {
      menu_id: menu_id,
    }
    this.props.sectionandContent(requestData);
  }

  render() {
    
      var { path, pagecontent } = this.state;
      var search_data={};
      var data={};
      if(Object.keys(this.state.search_data).length > 0)
      {
        search_data=this.state.search_data;
        
        data=search_data.data;
      }
      
      return(

          <Fragment>
            {Object.keys(pagecontent).length && <Fragment>
                     <section className="innerpage-header">
                        <img src={pagecontent.image ? image_url+pagecontent.image : defaultBanner}  alt="Banner"/>
                    </section>
                </Fragment>}

                {Object.keys(pagecontent).length && <Fragment>
                    {pagecontent.bread_cumbs && <BreadCrumbs bread_curmbs={pagecontent.bread_cumbs} />}
                </Fragment>}
            {
              data.length > 0 ? 
                <section className="search-page">

                  <div className="container">


                    <h1>Search results for <span>“{search_data.search_param}”</span></h1>

                    <h3 className="total-result">Total Search Result: {search_data.total_data}</h3>
                    
                      <Fragment>
                        {
                          data.map((row,key)=>{
                            
                            return (
                              <Fragment key={key} >
                                {
                                    row.data.length> 0 ?
                                    <SearchComponent title={row.title} data={row.data}/>
                                    :''
                                } 
                                
                              </Fragment>
                            )
                          })
                        }
                      </Fragment>
                  </div>
                </section>
                :
                ''
            }
          </Fragment>
        
      )
  }
}

const mapStateToProps = state => ({
  utils: state.utils,
  header: state.header,
  contents: state.contents,
  footer: state.footer,
  layout_id: state.utils.layout_id,
  routemenu: state.utils.routemenu,
  setting: state.utils.setting,

})

const mapDispatchToProps = dispatch => ({
  getSetting: () => dispatch(getSetting()),
  setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
  Search: (requestData) =>  dispatch(Search(requestData)) ,
  pageSectionContent: (requestData) =>  dispatch(pageSectionContent(requestData)) ,
  SetMetaData: (requestData) =>  dispatch(SetMetaData(requestData)) ,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchLayout));