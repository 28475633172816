import React, { Fragment } from 'react';


const Form=()=> {
    return (
        <Fragment>
            <section className="article-submission-page">
                <div className="container">

                    <div className="article-submission-form">
                    
                        <h2>Online Form for Submission of Article</h2>

                        <form>
                            <h4>1.  Name of Author(s)</h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Author's Name</label>
                                    <input type="text" name="d" className="form-control"/>
                                </div>
                                <div className="col-md-6">
                                    <label>Profession</label>
                                    <input type="text" name="" className="form-control" />
                                </div>
                                <div className="col-md-6">
                                    <label>Designation</label>
                                    <input type="text" name="" className="form-control" />
                                </div>
                                <div className="col-md-6">
                                    <label>Organization</label>
                                    <input type="text" name="" className="form-control" />
                                </div>

                                <div className="col-md-6">
                                    <label>Mobile</label>
                                    <input type="number" name="" className="form-control" />
                                </div>

                                <div className="col-md-6">
                                    <label>Email</label>
                                    <input type="email" name="" className="form-control" />
                                </div>

                                <div className="col-md-6">
                                    <label>Brief Profile of writer (not more than 100 words)</label>
                                    <textarea className="form-control"></textarea>
                                </div>
                                <div className="col-md-6">
                                    <label>Add more Author's Name (if any)</label>
                                    <input type="text" name="" className="form-control" />
                                </div>
                            </div>

                            <h4>2.  Article Submission for</h4>

                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label" for="exampleCheck1">a. The Bangladesh Accountant </label>
                            </div>

                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck2" />
                                <label className="form-check-label" for="exampleCheck2">b. Bangladesh Economia </label>
                            </div>

                            <div className="row">
                            
                                <div className="col-md-6">
                                
                                    <h4>3.  Summary of Article</h4>

                                    <textarea className="form-control"></textarea>

                                </div>

                                <div className="col-md-6">
                                
                                    <h4>4.  Category of Article</h4>

                                    <div className="form-check form-check-inline">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck3" />
                                    <label className="form-check-label" for="exampleCheck3">Categories  1</label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck4" />
                                    <label className="form-check-label" for="exampleCheck4">Categories 2</label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck5" />
                                    <label className="form-check-label" for="exampleCheck5">Categories 3</label>
                                    </div>

                                </div>

                            </div>
                        
                            <div className="row">
                            
                                <div className="col-md-6">
                                
                                    <label>Upload Photo</label>

                                    <input type="file" className="btn btn-outline-secondary" name="" />

                                </div>

                                <div className="col-md-6">
                                
                                    <label>Upload Articles</label>

                                    <input type="file" className="btn btn-outline-secondary" name="" />

                                </div>

                            </div>

                            <input type="submit" className="btn btn-primary" name="" />

                        </form>

                    </div>

                </div>
            </section>

        </Fragment>
    )
}

export default Form
