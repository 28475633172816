import React, { Fragment } from 'react';
import HtmlHelmet from '../../common/HtmlHelmet';
import BannerLeft from '../BannerLeft';
import BreadCrumbs from '../BreadCrumbs';
import Member from '../Member';
import Pagination from '../Pagination';


const Common=({page_data,member,per_page,page_no,prev,next,page_no_click,Search,Search_text_change})=> {
    const {title}=page_data;
    const {bread_curmbs}=page_data;
    const{image:banner_img=""}=page_data;
    const{contents}=page_data;
    const {short_description=" "}=contents!=null?contents:{};
    const {memberinfo={}}=member;
    const meta_data = {
        meta_title:'Member Dashboard',
        meta_description:'',
        meta_keywords:'ICAB, ICAB Member Dashboard',
      }
    return (
        <Fragment>
            <HtmlHelmet custom_data = {meta_data} />
            <BannerLeft title={title} short_description={short_description} menu_name={bread_curmbs[0].title} banner={banner_img}/>
            <BreadCrumbs bread_curmbs={bread_curmbs} />
            <section className="member-list inner-content">

                <div className="container">

                    <div className="member-search-box">
                    
                    <form onSubmit={Search}>
                        
                        <input type="text" placeholder="enter name or enrollment number" name="" onChange={Search_text_change.bind(this)}/>

                        <input type="submit" className="btn btn-primary" value="Search" onClick={Search}/>

                    </form>

                    </div>

                    <div className="clearfix"></div>

                    <h1>List of Members</h1>
                    {
                        memberinfo.length>0?
                        <Member member={memberinfo}/>
                        :
                        "No Member Found"
                    }
                    <Pagination total_data={member.datacount} per_page={per_page} page_no={page_no} prev={prev} next={next} page_no_click={page_no_click}/>
                    

                </div>

                </section>
        </Fragment>
    )
}

export default Common
