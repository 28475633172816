import $ from 'jquery';
import qs from 'query-string';
import React, { Component, Fragment } from 'react';
import Popover from "react-popover";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getCslDheerajapi, memberDetails } from '../../actions/Utils';
import axiosInstance, { version } from '../../config/Api';
import MemberLeftsidebar from './MemberLeftsidebar';
class OnlinePayment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      content_loading: true,
      is_open_modal: false,
      modal_content: null,
      show_all_member: false,
      show_all_tor: false,
      current_tab: 'contactdetails-data',
      billing:[],
      selected_bill:[],
      payment_amount:{},
      checked_bill:{},
      no_invoice:[],
      no_invoice_head:{},
      no_invoice_selected_code:0,
      no_invoice_selected_name:null,
      no_invoice_amount:0,
      msg:'',
      msg_type:'',
      memberinfo: [],
      annual_payment_sl:{},
      isOpen: []
    }

  }

  componentDidMount = () => {
   
    const requestData = {
      EnNo: localStorage.getItem('member_id'),
      
    }
    this.props.memberDetails(requestData).then((data) => {
      
      this.setState({
        memberinfo: data
      })
    });
      
    var msg=qs.parse(this.props.location.search).msg;
    var msg_type=qs.parse(this.props.location.search).msg_type;
    if(typeof msg!='undefined')
    {
      this.setState({msg:msg});
    }
    if(typeof msg_type!='undefined')
    {
      this.setState({msg_type:msg_type});
    }
    const billingRequest = {
      userkeyid: localStorage.getItem('member_id'),
      customerType: 'Member',
      "_": new Date().getTime()
    }
    this.props.getCslDheerajapi(billingRequest, 'GetInvoice', 'get').then((data) => {
        this.setState({
          billing:data.Result
        });
        this.state.billing.map((row,key)=>{
          this.setState({checked_bill:{...this.state.checked_bill,[key]:false}});
          this.setState({isOpen:{...this.state.isOpen,[key]:false}});
          this.setState({payment_amount:{...this.state.payment_amount,[key]:row.DuesAmount}});
        });
    }); 
    
    this.props.getCslDheerajapi(billingRequest, 'GetNonInvoiceAccount', 'get').then((data) => {
      

        this.setState({
          no_invoice_head:data.Result
        });
    });
  }



/*   static getDerivedStateFromProps(props, state) {
   
  } */

  handleCheckBox = (event,sl,row) => {
    var invoice_amount=0;
    const { memberinfo } = this.state;
    const memberdata = Object.keys(memberinfo).length ? memberinfo.payload[0] : false;
    if(this.state.payment_amount[sl] <=0){
      this.setState({payment_amount:{...this.state.payment_amount,[sl]:1}});
      invoice_amount=1;
    }
    else
    {
      invoice_amount=this.state.payment_amount[sl];
    }
    this.setState({checked_bill:{...this.state.checked_bill,[sl]:!this.state.checked_bill[sl]}});
   
    if(!this.state.checked_bill[sl]==true){
      var obj={};
      obj.sl=sl;
      obj.inv_no=row.InvoiceNo;
      obj.member_name=row.MemberName;
      obj.member_email=memberdata.cellPhone?memberdata.emailAddress1:memberdata.emailAddress2?memberdata.emailAddress2:'';
      obj.member_phone=memberdata.cellPhone?memberdata.cellPhone:memberdata.cellPhone2?memberdata.cellPhone2:'';
      obj.office_address=memberdata.address;
      obj.res_address=memberdata.reS_Addrress;
      obj.member_code=row.MemberCode;
      obj.account_code=row.AccountCode;
      obj.ac_head_name=row.Particulars;
      obj.is_invoice=1;
      obj.inv_amount=invoice_amount;
      const { selected_bill} = this.state;
      const working_selected_bill = [...selected_bill];
      working_selected_bill.push(obj);
      this.setState((prevState) => {
          return {
            selected_bill: working_selected_bill,
          };
      },()=>{
        $('.invoice_payment').prop('postdata',this.state.selected_bill )
      });
    }
    else
    {
      const { selected_bill} = this.state;
      const working_selected_bill = [...selected_bill];
      
      const scoopIndex = working_selected_bill.findIndex((sc) => sc.sl === sl);

      working_selected_bill.splice(scoopIndex, 1);
      this.setState((prevState) => {
          return {
            selected_bill: working_selected_bill,
          };
      },()=>{
        $('.invoice_payment').prop('postdata',this.state.selected_bill )
      });
    }   
  }
  handleDues = (event,sl,row) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      /* if(event.target.value <=0){
        this.setState({payment_amount:{...this.state.payment_amount,[sl]:1}});
      }
      else */ if(row.DuesAmount<event.target.value)
      {
        this.setState({payment_amount:{...this.state.payment_amount,[sl]:row.DuesAmount}});
      }
      else{
        this.setState({payment_amount:{...this.state.payment_amount,[sl]:event.target.value}});
      }
    }
    else{
      this.setState({payment_amount:{...this.state.payment_amount,[sl]:1}});
    }
  }

  handleNoInvoiceHeadChange=(e)=>{
    this.setState({no_invoice_selected_code:e.target.value});
    this.setState({no_invoice_selected_name:e.target.selectedOptions[0].innerText});
    const { no_invoice} = this.state;
    const { memberinfo } = this.state;
    const memberdata = Object.keys(memberinfo).length ? memberinfo.payload[0] : false;
    var obj={};
      obj.inv_no='no invoice';
      obj.member_name=memberdata.memberNameFul+" "+memberdata.acA_FCA+" ("+memberdata.enNo+")";
      obj.member_code=localStorage.getItem('member_id');
      obj.member_email=memberdata.cellPhone?memberdata.emailAddress1:memberdata.emailAddress2?memberdata.emailAddress2:'';
      obj.member_phone=memberdata.cellPhone?memberdata.cellPhone:memberdata.cellPhone2?memberdata.cellPhone2:'';
      obj.office_address=memberdata.address;
      obj.res_address=memberdata.reS_Addrress;
      obj.account_code=e.target.value;
      obj.ac_head_name=e.target.selectedOptions[0].innerText;
      obj.inv_amount=this.state.no_invoice_amount;
      obj.is_invoice=0;
      no_invoice[0]=obj;
      this.setState((prevState) => {
        return {
          no_invoice: no_invoice,
        };
    },()=>{
      $('.pay_non_invoice').prop('postdata',this.state.no_invoice )
    });
  }

  handleNoInvoiceAmountChange=(e)=>{
    this.setState({no_invoice_amount:e.target.value});
    const { no_invoice} = this.state;
    const { memberinfo } = this.state;
    const memberdata = Object.keys(memberinfo).length ? memberinfo.payload[0] : false;
    var obj={};
      obj.inv_no='no invoice';
      obj.member_name=memberdata.memberNameFul+" "+memberdata.acA_FCA+" ("+memberdata.enNo+")";
      obj.member_code=localStorage.getItem('member_id');
      obj.member_email=memberdata.cellPhone?memberdata.emailAddress1:memberdata.emailAddress2?memberdata.emailAddress2:'';
      obj.member_phone=memberdata.cellPhone?memberdata.cellPhone:memberdata.cellPhone2?memberdata.cellPhone2:'';
      obj.office_address=memberdata.address;
      obj.res_address=memberdata.reS_Addrress;
      obj.member_code=localStorage.getItem('member_id');
      obj.account_code=this.state.no_invoice_selected_code;
      obj.ac_head_name=this.state.no_invoice_selected_name;
      obj.inv_amount=e.target.value;
      obj.is_invoice=0;
      no_invoice[0]=obj;
      this.setState((prevState) => {
        return {
          no_invoice: no_invoice,
        };
    },()=>{
      $('.pay_non_invoice').prop('postdata',this.state.no_invoice)
    });
  }

  handlePayOnline = () => {
    axiosInstance.post(version+'pay_online',{params:this.state.selected_bill}).then(({ data }) => {
      if(data.status =='SUCCESS' && data.GatewayPageURL)
      {
        window.location.href =data.GatewayPageURL;
      }

    });
  }
  handleNonInvoicePayOnline = () => {
    axiosInstance.post(version+'pay_online',{params:this.state.no_invoice}).then(({ data }) => {
      if(data.status =='SUCCESS' && data.GatewayPageURL)
      {
        window.location.href =data.GatewayPageURL;
      }

    });
  }

  handleMessageClose = () => {
    const { history, match } = this.props;
    this.setState({ search: '' });
    history.push(`${process.env.PUBLIC_URL}${match.url}`);
    this.setState({msg:'',msg_type:''})
  }
  togglePopover = (sl) => {
    // this.setState({ isOpen: !this.state.isOpen });
    this.setState({isOpen:{...this.state.isOpen,[sl]:!this.state.isOpen[sl]}});
  };
  render() {

    const { content_loading, is_open_modal, show_all_member, show_all_tor, current_tab } = this.state;

    const content_committee = this.props.committe;
    const content = content_committee.content;
    const { billing } = this.state;
    var breadcurmbs = [];

    var last_annual_fee_sl='';

    setTimeout(() => {
      this.setState({
        content_loading: false
      });
    }, 3000);

    return (
      <Fragment>
        {content_loading? 
        
        <center>
          <div id="loader-1" className='loader-online-page'>
              <span></span><span></span><span></span><span></span><span></span>
          </div>
          </center> :
        <section>
          <div className="container">

            <div className="row">

              <div className="col-md-3">

                <div className="member-dashboard-menu mb-5">

                  <MemberLeftsidebar activemenu="onlinepayment" />

                </div>

              </div>
              <div className="col-md-9">
                <div className="member-dashboard-content">
                  <div className="row">
                    {this.state.msg !='' && this.state.msg_type =='success' && <div className="col-sm-12" >
                            <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                {this.state.msg}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}
                        {this.state.msg !='' && this.state.msg_type =='danger' && <div className="col-sm-12">
                            <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                {this.state.msg}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}
                  </div>
                  <div className="members-title">

                    <h3>Payment for Annual Fees, IDC & QAD</h3>

                  </div>

                  <div className="table-style table-responsive member-online-payment-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>SL</th>
                          <th>Invoice Info</th>
                         {/*  <th>Year</th>
                          <th>Particulars</th>
                          <th>Inv. Date</th> */}
                          <th>Invoice<br/>Amount</th>
                          <th>Paid <br/>Amount</th>
                          <th>Due <br/>Amount</th>
                          <th>Payment <br/>Amount</th>
                          <th>Pay<br/> Online</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        
                        billing.map((row,key)=>{
                          var last_anual_sl=last_annual_fee_sl;
                         
                          if(row.Particulars.toString().trim().toLowerCase()==="annual fee")
                          {
                            last_annual_fee_sl= key;
                          }
                          return (
                            <Fragment key={key}>
                              <tr>
                                <td>{key+1}</td>
                                <td className="text-left">
                                  <b>Invoice No :</b> {row.InvoiceNo}<br/>
                                  <b>Financial Year :</b> {row.FinancialYear}<br/>
                                  <b>Particular :</b> {row.Particulars}<br/>
                                  <b>Invoice Date : </b> {
                                     new Date(row.InvoiceDate).toLocaleDateString('en-GB') //Or any other methods that date supports
                                   
                                  // row.InvoiceDate
                                  }
                                </td>
                                <td>{row.InvoiceAmount}</td>
                                <td>{row.PaidAmount}</td>
                                <td>{row.DuesAmount}</td>
                                <td><input type="number" className="payment_amount form-control" disabled={this.state.checked_bill[key]}  onChange={(e)=>this.handleDues(e,key,row)} value={this.state.payment_amount[key]} /> </td>
                                <td>
                                  {
                                    row.Particulars.toString().trim().toLowerCase()==="annual fee" && last_anual_sl!=='' && !this.state.checked_bill[last_anual_sl]?
                                      
                                    <Popover place ="left" isOpen={this.state.isOpen[key]}  body={<div className="Balloon-popover">Please select previous invoice first. </div>} >
                                       
                                        <div style={{
                                          position:'relative',
                                          width: '50%',
                                          margin: 'auto'
                                          }}>
                                          <div onClick={(e)=>this.togglePopover(key)}
                                            style={{
                                              width: '20px',
                                              height: '20px',
                                              position: 'absolute',
                                              left: '8px',
                                              top: '3px'
                                            }}
                                           />
                                        </div>
                                        <input type="checkbox" disabled={true}   />
                                    </Popover>

                                    :
                                    <input type="checkbox"    onChange={(e)=>this.handleCheckBox(e,key,row)} />
                                    
                                  }
                                  
                                </td>
                              </tr>
                            </Fragment>
                        )
                      })
                    }
                      </tbody>
                    </table>
                  </div>

                  <div className="pay-online-btn">
                      {/* this.state.selected_bill.length>0? */}
                      {/* <button className="btn-primary invoice_payment" id="sslczPayBtn"
                        token="if you have any token validation"
                        postdata="json"
                        order="If you already have the transaction generated for current order"
                        endpoint={axiosInstance.defaults.baseURL+version+"pay_online"}
                        disabled={ this.state.selected_bill.length<=0? 'disabled':''}
                        > Pay Online
                      </button> */}
                    <button title='Click the Pay Online checkbox and button to proceed to the next payment procedure.'  className="btn-primary" onClick={()=>this.handlePayOnline()} disabled={ this.state.selected_bill.length<=0? 'disabled':''}> Pay Online </button>

                  </div>
                  {
                    this.state.no_invoice_head.length>0?
                    <Fragment>
                      <div className="members-title">

                        <h3>Payment for Others</h3>

                      </div>
                      <div className="member-box">
                        <div className="form-group no-invoice-head">
                          <label>Payment Purpose <span>*</span></label>
                          <select className="form-control" onChange={(e)=>this.handleNoInvoiceHeadChange(e)}>
                          <option value="0">--- Select ---</option>
                            {this.state.no_invoice_head.map((row,key)=>{
                              return (
                                <option value={row.AccountCode}>{row.AccountName}</option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                            <label>Payment Amount <span>*</span></label>
                          <input type="number" className="form-control" onChange={(e)=>this.handleNoInvoiceAmountChange(e)} value={this.state.no_invoice_amount}/>
                        </div>
                        <div className="pay-online-btn">
                           {/*  <button className="btn-primary pay_non_invoice" id="sslczPayBtn"
                              token="if you have any token validation"
                              postdata="json"
                              order="If you already have the transaction generated for current order"
                              endpoint={axiosInstance.defaults.baseURL+version+"pay_online"}
                              disabled={ this.state.no_invoice_amount <=0? 'disabled':''}
                              > Pay Online
                            </button> */}
                            <button  className="btn-primary" onClick={()=>this.handleNonInvoicePayOnline()} disabled={ this.state.no_invoice_amount <=0 || this.state.no_invoice_selected_code==0? 'disabled':''}> Pay Online </button>
                        </div>
                      </div>
                      
                    </Fragment>
                    :''
                  }
                </div>
                
              </div>
            </div>
          </div>
        </section>}

      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  committe: state.contents.committe_content.singlecommitte,
})

const mapDispatchToProps = (dispatch) => ({
  getCslDheerajapi: (requestData, actionName, RequestMethod) => dispatch(getCslDheerajapi(requestData, actionName, RequestMethod)),
  memberDetails: (requestData) => dispatch(memberDetails(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnlinePayment));