import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { image_url } from '../../../config/Api';
import { getCategoryNews } from '../../actions/Publication';
import Timestamp from 'react-timestamp';

class FirstSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      slug:'',
      loading:false,
    }
    this.contentLoad = this.contentLoad.bind(this);

  }

  componentDidMount = () => {
    var slug = this.props.slug;
    if(slug.charAt(0) == '/') {
      slug = slug.substring(1,slug.length);
    }
  
    const requestData = {
      page: 1
    }

    const req = this.props.getCategoryNews(slug, requestData).then((data) => {
      this.setState({
        content: data,
        slug:slug
      })
    });


  }

  contentLoad = () => {
    const {content,slug} = this.state;
     const current_page = content.current_page;
     const to = content.to;
     const total = content.total;
     const requestData = {
       page: current_page+1
     }
     const req = this.props.getCategoryNews(slug, requestData).then((data) => {
      
     
      const getcontent = data;
      const newdata = data.data;
      delete data.data;
  
      var newstate = {
        ...this.state,
        content:{
          ...data,
          data:[
            ...this.state.content.data,
            ...newdata
          ]
        }
      }
      this.setState({
        ...newstate
      })

     });
  }



  // static getDerivedStateFromProps(props, state) {


  // }

  render() {

    const { content } = this.state;

  
    return (
      <section className="news-bulletin">
      
      <div className="container">

        <div className="news-bulletin-title">
        
          <h1>Annual reports</h1>

        </div>

        <div className="table-style table-responsive accounting-table">
        
          <table className="table text-center" width="100%">

            <thead>
              
              <th colspan="3">Annual Reports</th>

            </thead>
            
            <tbody>
              
              <tr>
                
                <td>2020</td>
                <td><p>Annual Report 2020 & Financial Statements 2019-2020  </p></td>
                <td> <a href="#">Download <img src={require('../../img/icon/pdf.svg').default} height="25" /></a></td>

              </tr>

              <tr>
                
                <td>2019</td>
                <td><p>Annual Report 2020 & Financial Statements 2018-2019  </p></td>
                <td> <a href="#">Download <img src={require('../../img/icon/pdf.svg').default} height="25" /></a></td>

              </tr>

              <tr>
                
                <td>2018</td>
                <td><p>Annual Report 2020 & Financial Statements 2017-2018  </p></td>
                <td> <a href="#">Download <img src={require('../../img/icon/pdf.svg').default} height="25" /></a></td>

              </tr>

              <tr>
                
                <td>2017</td>
                <td><p>Annual Report 2020 & Financial Statements 2016-2017  </p></td>
                <td> <a href="#">Download <img src={require('../../img/icon/pdf.svg').default} height="25" /></a></td>

              </tr>

              <tr>
                
                <td>2016</td>
                <td><p>Annual Report 2020 & Financial Statements 2015-2016  </p></td>
                <td> <a href="#">Download <img src={require('../../img/icon/pdf.svg').default} height="25" /></a></td>

              </tr>

            </tbody>

          </table>
        
        </div>

      </div>

    </section>

    );
  }
}


const mapStateToProps = (state) => ({
  setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
  getCategoryNews: (slug, requestData) => dispatch(getCategoryNews(slug, requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FirstSection);