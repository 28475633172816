const contenerReducer = {
    home:{
        firstsection:[],
        secondsection:[],
        thirdsection:[],
        fourthsection:[],
        fifthsection:[],
    },
    details:{
      is_loading:true,
      content:{},
    },
   
}

export default(state = contenerReducer,action) =>{
    switch (action.type) {
        
        
        case 'INIT_PUB_HOME':
            
            return {
                ...state,
                home:{
                    ...state.pubhome,
                    content_loading:true,
                    content:action.data,
                }
            }
        case 'PUB_ADD_DETAILS':
            
            return {
                ...state,
                details:{
                    content_loading:false,
                    content:action.data,
                }
            }
       
        default:
            return state;
            
    }
}