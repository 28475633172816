import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getSetting, pageSectionContent, sectionandContent, setPathLayout } from "../actions/Utils";
import '../App.css';
import EventLanding from '../components/content/EventLanding';
import SeeAllNews from '../components/content/SeeAllNews';
import StudentLanding from '../components/content/StudentLanding';
import PhotosAlbum from '../components/gallery/PhotosAlbum';
import VideoAlbum from '../components/gallery/VideoAlbum';
import Htmlcontent from '../components/Htmlcontent';
import BreadCrumbs from '../components/page/BreadCrumbs';
import { image_url } from '../config/Api';
import defaultBanner from '../img/banner/bg3.png'
import Sitemap from '../components/page/Sitemap';
class CommonLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            header: {},
            container: {},
            pagecontent: {}
        };
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const { location } = this.props.history;
        var { pathname } = location
        const requestData = {
            slug: pathname
        }
        this.props.pageSectionContent(requestData).then((res) => {
            
            this.setState({
                pagecontent: res
            })
        });
    }


    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id
        };
    }
    getContentData = (menu_id) => {
        const requestData = {
            menu_id: menu_id,
        }
        this.props.sectionandContent(requestData).then((res) => {
           
        });
    }

    render() {
        var { path, pagecontent } = this.state;
       

        return (
            <Fragment>

                

                {Object.keys(pagecontent).length ?<Fragment>
                    {pagecontent.image?<section className="innerpage-header header-left-align">

                        <img src={`${image_url}${pagecontent.image}`} />

                    </section>:<section className="innerpage-header header-left-align"><img src={`${defaultBanner}`} alt="Banner" /></section>}

                    
                </Fragment>:''}

                {Object.keys(pagecontent).length ?<Fragment>
                    {pagecontent.bread_cumbs && <BreadCrumbs bread_curmbs={pagecontent.bread_cumbs} />}
                </Fragment>:''}



                {Object.keys(pagecontent).length ? <Fragment>
                    {pagecontent.pagesection ?<Fragment>
                    {pagecontent.pagesection.map((row, key) => {
                        return (
                            <Fragment key={key}>
                                {row.section.section_type.toLowerCase() == 'html' && <Fragment>
                                    {Object.keys(row.section.contents).length ? <Fragment>
                                        <Htmlcontent data={row.section.contents.description} />
                                    </Fragment> : ''}
                                </Fragment>}
                                {row.section.section_type.toLowerCase() == 'moduler event' && <Fragment>
                                    <EventLanding content={pagecontent} />
                                </Fragment>}
                                {row.section.section_type.toLowerCase() == 'explore update area specific content' && 
                                <Fragment>
                                    <StudentLanding page_content={pagecontent} sec_content={row} />
                                </Fragment>}

                                {row.section.section_type.toLowerCase() == 'see all news update' && 
                                <Fragment>
                                    <SeeAllNews category={'all'} />
                                </Fragment>}

                                {row.section.section_type.toLowerCase() == 'see all event' && 
                                <Fragment>
                                    <SeeAllNews category={'2'} />
                                </Fragment>}

                                {row.section.section_type.toLowerCase() == 'photo gallery' && 
                                <Fragment>
                                    <PhotosAlbum page_content={pagecontent} sec_content={row} />
                                </Fragment>}
                                {row.section.section_type.toLowerCase() == 'video gallery' && 
                                <Fragment>
                                    <VideoAlbum page_content={pagecontent} sec_content={row} />
                                </Fragment>}
                                {row.section.section_type.toLowerCase() == 'sitemap' && 
                                <Fragment>
                                    <Sitemap page_content={pagecontent} sec_content={row} />
                                </Fragment>}

                            </Fragment>
                        )
                    })}
                    </Fragment>:''}
                </Fragment> : ''}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    homelayout: state.homelayout,
    utils: state.utils,
    header: state.header,
    contents: state.contents,
    footer: state.footer,
    layout_id: state.utils.layout_id,
    routemenu: state.utils.routemenu,
    setting: state.utils.setting,

})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    sectionandContent: (requestData) => dispatch(sectionandContent(requestData)),
    pageSectionContent: (requestData) => dispatch(pageSectionContent(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommonLanding));