import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { parseString } from 'xml2js';
import { getSingleOfCommitte ,SetMetaData} from '../../actions/Utils';
import { casInstance, caversion, processMaker } from '../../config/Api';
import MemberLeftsidebar from './MemberLeftsidebar';
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = 
    {
      content: {},
      content_loading: true,
      validator: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      message: '',
      message_type: '',
    }
    this.refs = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    const metadata = {
      meta_title:'ICAB | Change Passwrod',
      meta_description:'',
      meta_keywords:'ICAB,Change Passwrod',
    }
    this.props.SetMetaData(metadata);
  }



  static getDerivedStateFromProps(props, state) {
    return {
      committe: props.committe
    }
  }

  handleMessageClose = () => {
    this.setState({ message: '', message_type: '' })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const userFormData = {
      oldPassword: this.oldPassword.value,
      newPassword: this.newPassword.value,
      confirmPassword: this.confirmPassword.value,
    }

    const resetValidator = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
    var validation = {};

    

    if (userFormData.oldPassword == '') {
      validation.oldPassword = 'Old password field is required.';
    }
    if (userFormData.oldPassword && userFormData.oldPassword != localStorage.getItem('member_pass')) {
      validation.oldPassword = 'Old Password Not Match';
    }
    if (userFormData.newPassword == '') {
      validation.newPassword = 'New password field is required.';
    }
    if (userFormData.confirmPassword == '') {
      validation.confirmPassword = 'Confirm password field is required.';
    }
    
    if (userFormData.newPassword && userFormData.confirmPassword && userFormData.newPassword != userFormData.confirmPassword) {
      validation.confirmPassword = 'Password not match';
    }


    if (Object.keys(validation).length) {
      this.setState({ validator: { ...resetValidator, ...validation }, message: '' });

    }
    else {
      const post_data={
        'newpass':userFormData.newPassword,
        'conpass':userFormData.confirmPassword
      };
      const headers = {
        'AuthorizationHeader': 'Bearer '+localStorage.getItem('token')
      }
      casInstance.get(caversion + 'dopasschange',{headers: headers,params:post_data}).then(({data})=>{
        if(data.status==200)
        {
          this.oldPassword.value='';
          this.newPassword.value='';
          this.confirmPassword.value='';
         
          
          var process_maker_login_xml_body='<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:www="http://www.processmaker.com">\
          <x:Header/>\
          <x:Body>\
          <www:login>\
          <www:userid>test</www:userid>\
          <www:password>asd123</www:password>\
          </www:login>\
          </x:Body>\
        </x:Envelope>\
        ';
        processMaker.post('',
          process_maker_login_xml_body,
        {headers:
          {'Content-Type': 'text/xml; charset=utf-8','soapAction':'http://www.processmaker.com/login'}
        }).then(res=>{
          
          parseString(res.data,(err,result)=>{
            var status=result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:loginResponse']['0']['ns1:status_code']['0'];
            if(status==0)
            {
              var session_id=result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:loginResponse']['0']['ns1:message']['0'];
              var bodyFormData = new FormData();
              bodyFormData.append('sessionID', session_id);
              bodyFormData.append('userName', localStorage.getItem('member_id'));
              bodyFormData.append('passWord', userFormData.newPassword);

              axios.post('https://studentservices.icab.org.bd/change-password.php',bodyFormData).then((data)=>{
                
              });
            }
          })
        }).catch(err=>{ console.log('process maker error');console.log(err)});
        localStorage.setItem('member_pass', userFormData.newPassword);
        this.setState({ message: data.message, message_type: 'success', validator: { ...resetValidator } });
        }
        else{
          this.setState({ message: 'Something wrong! please try again... ', message_type: 'danger', validator: { ...resetValidator } });
        }
      }).catch(error => {

        if (error.response) {
          var html = '';
          if (Object.keys(error.response.data).length) {

            for (const [key, value] of Object.entries(error.response.data)) {
              //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
              html += `${value}`;
            }

          }

        }
        if (html) {
         
          // this.setState({ message: error.response.data.message, message_type: 'danger', validator: { ...resetValidator } })
          this.setState({ message: 'Something wrong! please try again... ', message_type: 'danger', validator: { ...resetValidator } })
        }

      });
    }
  }

  render() {
    const { validator } = this.state;
    return (
      <Fragment>
        <section className="dashboard">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="member-dashboard-menu mb-5">
                  <MemberLeftsidebar activemenu="others" />
                </div>
              </div>
              <div className="col-md-9">
                <div className="member-dashboard-content">
                  <div className="members-title">
                    <h3>Change Password</h3>
                  </div>
                  {/* <div className="row"> */}
                  <div className="form-title">
                    {this.state.message != '' && this.state.message_type == 'success' && <div className="col-sm-12" >
                    <div className="alert  alert-success alert-dismissible fade show" role="alert">
                        {this.state.message}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>}
                    {this.state.message != '' && this.state.message_type == 'danger' && <div className="col-sm-12">
                      <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                        {this.state.message}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>}
                  {/* </div> */}
                  <div className="change-password">
                    <form className="">
                      <div className="form-group">
                        <label>Old Password <span>*</span></label>
                        <input type="password" ref={(input) => this.oldPassword = input} className={validator.oldPassword ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="Password" />

                        {validator.oldPassword && <span className="help-block form-text text-danger">{validator.oldPassword}</span>}
                      </div>
                      <div className="form-group">
                        <label>New Password <span>*</span></label>
                        <input type="password" ref={(input) => this.newPassword = input} className={validator.newPassword ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="Password" />

                        {validator.newPassword && <span className="help-block form-text text-danger">{validator.newPassword}</span>}
                      </div>
                      <div className="form-group">
                        <label>Confirm Password <span>*</span></label>
                        <input type="password" ref={(input) => this.confirmPassword = input} className={validator.confirmPassword ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="Password" />

                        {validator.confirmPassword && <span className="help-block form-text text-danger">{validator.confirmPassword}</span>}
                      </div>
                      <input
                        type="submit"
                        value="Change"
                        className="btn"
                        onClick={this.handleSubmit}
                      />
                    </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  committe: state.contents.committe_content.singlecommitte,
})

const mapDispatchToProps = (dispatch) => ({
  getSingleOfCommitte: (requestData) => dispatch(getSingleOfCommitte(requestData)),
  SetMetaData: (requestData) => dispatch(SetMetaData(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));