
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
class EventlSlider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            home_layout: {
                content: null
            },

        }

    }

    static getDerivedStateFromProps(props, state) {

        return {
            home_layout: props.home_layout
        }
    }

    render() {
        // const slider = this.props.slider;
        const content = this.state.home_layout.content;
        var sliderdata = [];
        var chunkSlider = [];
        var sectioncontent = false;
        if (content.page_data) {

            var page_section = content.page_data.section;

            const section_data = page_section.find((row) => {
                if (row.name.toLowerCase() == 'latest update') {
                    return true;
                }
                else {
                    return false;
                }
            });
            sectioncontent = section_data;
            if (section_data) {
                var sliderdata = section_data.contents;

            }
        }



        // console.log('sectioncontent event slider =', sectioncontent);
        var settings = {
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            arrows: true,
            loop: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 300,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <section className="latest-news">


                <div className="container">

                    <div className="latest-news-title">

                        {sectioncontent &&
                            <h1>

                                {sectioncontent.title}

                            </h1>
                        }

                        {sectioncontent && <p>

                            {sectioncontent.sec_description}

                        </p>}
                        {
                            sectioncontent.landing_page_url ?
                                <Link to={{ pathname: `${process.env.PUBLIC_URL}` + sectioncontent.landing_page_url }} className="btn-primary">See all Updates</Link>
                                :
                                <Link to={{ pathname: `${process.env.PUBLIC_URL}/` }} className="btn-primary">See all Update</Link>
                        }

                    </div>

                    <div className="latest-news-slider ">
                        <Slider
                            {...settings}
                        >
                            {
                                sliderdata.map((row, key) => {
                                    var isblinkbg = row.blink_effect == 1?'single-news-content blink-bg':'single-news-content';
                                    return (
                                        <Fragment key={key}>
                                            <div>

                                                <div className={`${isblinkbg}`}>
                                                   
                                                    {row.category.toLowerCase() == 'news' && <div className={`news-category news`}>

                                                        <h5>{row.category}</h5>

                                                    </div>}
                                                    {row.category.toLowerCase() == 'event' && <div className={`news-category event`}>

                                                        <h5>{row.category}</h5>

                                                    </div>}
                                                    {row.category.toLowerCase() == 'notice' && <div className={`news-category notice`}>

                                                        <h5>{row.category}</h5>

                                                    </div>}


                                                    {row.category.toLowerCase() == 'circular' && <div className={`news-category circular`}>

                                                        <h5>{row.category}</h5>

                                                    </div>}

                                                    {row.category.toLowerCase() == 'election' && <div className={`news-category election`}>

                                                        <h5>{row.category}</h5>

                                                    </div>}

                                                    <p>{row.news_for} <span><i className="fa fa-circle"></i> {row.publish_date}</span></p>

                                                    <h3>

                                                        <Link to={{ pathname: `${process.env.PUBLIC_URL}` + row.slug }}>{row.short_description.length > 122 ? row.short_description.substring(0, 125) + '...' : row.short_description}</Link>
                                                    </h3>

                                                    <Link to={{ pathname: `${process.env.PUBLIC_URL}` + row.slug }} className="LatestUpdateReadmore">
                                                        Read More <i className="fa fa-angle-right"></i>
                                                    </Link>

                                                </div>



                                            </div>
                                        </Fragment>
                                    )
                                })

                            }
                        </Slider>
                    </div>

                </div>

            </section>
        );
    }
}


const mapStateToProps = (state) => ({
    layout_id: state.utils.layout_id,
    home_layout: state.contents.home_layout,
})



export default connect(mapStateToProps)(EventlSlider);