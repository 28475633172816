import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import { getAlbums, SetMetaData } from '../../actions/Utils';
import { image_url } from '../../config/Api';
import NoImage from '../../img/no_image.jpeg';


class VideoAlbum extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      content_loading: true,

    }
    this.handleLoadContent = this.handleLoadContent.bind(this);

  }

  componentDidMount = () => {
    const sec_content = this.props.sec_content;
    const contents = sec_content.section.contents;

    console.log(" contents =", contents.data);
    this.setState({
      content: contents,
      content_loading: false,
    })

    const metadata = {
      meta_title:'ICAB | Video Gallery',
      meta_description:'',
      meta_keywords:'ICAB,Video Gallery',
    }
    this.props.SetMetaData(metadata);
  }


  // static getDerivedStateFromProps(props, state) {

  //   return false;
  // }

  handleLoadContent = () => {

    const { content, slug } = this.state;
    const current_page = content.current_page;

    const requestData = {
      page: current_page + 1,
      album_type_id: 2
    }
    const req = this.props.getAlbums(requestData).then((data) => {

      const getcontent = data;
      const newData = data.data;
      delete data.data;

      var newState = {
        ...this.state,
        content: {
          ...data,
          data: [
            ...this.state.content.data,
            ...newData
          ]
        }
      }
      this.setState({
        ...newState
      })

    });

  }
  render() {


    const { content, content_loading } = this.state;
    const sec_content = this.props.sec_content;

    console.log(" content =", content);
    return (

      <Fragment>

        <section className="gallery">



          <div className="container">

            <div className="inner-content">

              <div className="gallery-content">

                <h1>{sec_content.section.title}</h1>

                <div className="row">
                  {content_loading ? <Fragment>Loading...</Fragment> : <Fragment>
                    {Object.keys(content).length ? <Fragment>
                      {content.data.map((row, key) => {
                        return (
                          <div className="col-md-3">

                            <div className="gallery-single">


                              <Link to={`videos/${row.id}/album`}>

                                {row.image ? <Fragment> <img src={`${image_url}${row.image}`} /></Fragment> : <img src={`${NoImage}`} />}

                                <p>{row.name}</p>


                              </Link>

                            </div>

                          </div>
                        )
                      })}



                    </Fragment> : ''}

                  </Fragment>}
                </div>

                {Object.keys(content).length ? <Fragment>
                  {content.to < content.total && <Fragment>
                    <div className="text-center">
                      <button className="btn btn-primary" onClick={this.handleLoadContent}>Load More</button>
                    </div>
                  </Fragment>}
                </Fragment> : ''}

              </div>

            </div>

          </div>

        </section>
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
})

const mapDispatchToProps = (dispatch) => ({
  getAlbums: (requestData) => dispatch(getAlbums(requestData)),
  SetMetaData: (requestData) => dispatch(SetMetaData(requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoAlbum);