

export const getSubcategory = (data,categoryslug) => {
   
    if(!categoryslug)
        {
            return false;
        }
    else
        {
            const result = data.find( ({ slug }) => slug === categoryslug );
            if(!result) {
                const subdata = data.find( ({subcategory}) => {
                   return subcategory.find(({slug}) => {
                        if(slug == categoryslug) {
                            return subcategory;
                        }
                    })
                })
               return subdata;
            }
            return result;
        }
       
}