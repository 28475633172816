import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { contactusLayoutContent, getSetting, homeLayoutContent, setPathLayout } from "../actions/Utils";
import '../App.css';
import Contactform from '../components/contactus/Contactform';
import Htmlcontent from '../components/Htmlcontent';
import BreadCrumbs from '../components/page/BreadCrumbs';
import { image_url } from '../config/Api';





class Contactus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '',
      header: {},
      container: {},
      footer: {},
      layout_id: '',
      contactlayout: null,
    };
  }
  componentDidMount = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const { location } = this.props.history;
    var { pathname } = location

    this.props.homeLayoutContent().then((res) => {

    })

    this.props.contactusLayoutContent({ 'slug': pathname });

  }

  setPathLayout = (parms) => {
    this.props.setPathLayout(parms);
  }
  getSetting = async () => {
    let data = await this.props.getSetting();
  }
  static getDerivedStateFromProps(props) {
    return {
      path: props.utils.path,
      header: props.header,
      container: props.container,
      footer: props.footer,
      layout_id: props.utils.layout_id,
      contactlayout: props.contactuslayout
    };
  }
  getContentData = (menu_id) => {

    const requestData = {
      menu_id: menu_id,
    }
    this.props.sectionandContent(requestData);
  }

  render() {

  //  console.log("this contactuslayout = ", this.props.contactuslayout);
    const contactuslayout = this.props.contactuslayout.content;
    var top_html = '';
    var contactus_message = '';
    var contactus_map = '';
    var addresssocialicon = '';

    if (contactuslayout) {
      const pagesection = contactuslayout.pagesection;


      if (pagesection) {
        const section_data = pagesection.filter((row, key) => {

          if (row.section.name.toLowerCase() == 'contactus top banner') {
            top_html = row.section;
          }
          else if (row.section.name.toLowerCase() == 'contactus office location and social link') {
            addresssocialicon = row.section;
          }
          else if (row.section.name.toLowerCase() == 'contactus message') {
            contactus_message = row.section;
          }
          else if (row.section.name.toLowerCase() == 'contactus map') {
            contactus_map = row.section;
          }
          else {
            return false;
          }

        });
      }

    
    }

 


    return (
      <Fragment>

        {/* <section className="innerpage-header">
          {top_html && <Fragment>
            <Htmlcontent data={top_html.contents.description} />

          </Fragment>}

        </section>
        <Breadcrumb /> */}

        {contactuslayout.image && <section className="innerpage-header">

          <img src={`${image_url}${contactuslayout.image}`} />

        </section>}


        {contactuslayout.bread_cumbs&&<BreadCrumbs bread_curmbs={contactuslayout.bread_cumbs} />}


        <section className="council-members-page">

          <div className="container">

            <section className="contact-from-section">

              <div className="box">

                <div className="row">

                  <div className="col-md-6">
                    {contactus_message && <Contactform data={contactus_message} />}
                  </div>

                  <div className="col-md-6">

                    <div className="contact-right-img">

                      {contactus_message && <Fragment>
                        {contactus_message.contents && <Fragment>
                          {contactus_message.contents.images && contactus_message.contents.images.map((row, key) => {
                            return (
                              <Fragment key={key}>
                                <img src={`${image_url}${row.image}`} />
                              </Fragment>
                            )
                          })}
                        </Fragment>}
                      </Fragment>}


                    </div>

                  </div>

                </div>

              </div>

            </section>

            <section className="contact-address-section">

              {addresssocialicon && <Fragment>
                <Htmlcontent data={addresssocialicon.contents.description} />

              </Fragment>}

            </section>

          </div>

          <section className="map">
            {contactus_map && <Fragment>
              <Htmlcontent data={contactus_map.contents.description} />
            </Fragment>}

          </section>

        </section>


      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  contactuslayout: state.contents.contactus_layout,
  utils: state.utils,
  header: state.header,
  contents: state.contents,
  footer: state.footer,
  layout_id: state.utils.layout_id,
  routemenu: state.utils.routemenu,
  setting: state.utils.setting,

})

const mapDispatchToProps = dispatch => ({
  getSetting: () => dispatch(getSetting()),
  setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
  homeLayoutContent: (requestData) => dispatch(homeLayoutContent(requestData)),
  contactusLayoutContent: (requestData) => dispatch(contactusLayoutContent(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contactus));