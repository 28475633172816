import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getNews,getNewsDetails } from '../../actions/Publication';
import { image_url } from '../../../config/Api'
import Timestamp from 'react-timestamp';

class FirstSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content: []
        }

    }

    componentDidMount = () => {
        const requestData = {
            take: 3,
            skip: '',
        };
        const req = this.props.getNews(requestData).then((data) => {
            this.setState({
                content: data
            })
        });

    }

    handleLoadContent = (catid,id) => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const req = this.props.getNewsDetails(catid,id).then((data) => {
         
        });
    
    
      }

    render() {

        const { content } = this.state;

        return (

            <section className="just-in">

                <div className="container">

                    <h3 className="border-title">JUST IN</h3>

                    <div className="row">
                        {content.length ? <Fragment>
                            {content.map((row, key) => {
                                return (
                                    <Fragment key={key}>

                                        <div className="col-md-4">

                                            <div className="just-in-single">

                                                <div className="row">

                                                    <div className="col-md-6">


                                                        {row.image && <Fragment>
                                                            <img src={`${row.image.highlighted_image}`} />
                                                        </Fragment>}

                                                    </div>

                                                    <div className="col-md-6">

                                                        <h4>
                                                            {row.title.length > 53 &&
                                                                <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`} onClick={() => this.handleLoadContent(row.categories[0]['id'],row.id)}>{row.title.substring(0, 50)}...</Link>
                                                            }
                                                            {row.title.length <= 53 &&
                                                                <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`} onClick={() => this.handleLoadContent(row.categories[0]['id'],row.id)}>{row.title}</Link>
                                                            }
                                                        </h4>

                                                        {row.published_time&&<p><Timestamp relative date={row.published_time} autoUpdate /></p>}

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </Fragment>
                                )
                            })}
                        </Fragment>

                            : ''}

                    </div>

                </div>

            </section>
        );
    }
}


const mapStateToProps = (state) => ({
    setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
    getNews: (requestData) => dispatch(getNews(requestData)),
    getNewsDetails: (catid,slug) => dispatch(getNewsDetails(catid,slug)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FirstSection);