import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { image_url } from '../../../config/Api';
import { getNews } from '../../actions/Publication';
import Timestamp from 'react-timestamp';

class SecondSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: []
    }

  }

  componentDidMount = () => {
    const requestData = {
      take: 3,
      skip: 2,
    };
    const req = this.props.getNews(requestData).then((data) => {
      this.setState({
        content: data
      })
    });


  }

  // static getDerivedStateFromProps(props, state) {


  // }

  render() {

    const { content } = this.state;
    return (
      <section className="latest-news-last-three">

        <div className="container">

          <hr className="top-hr" />

          <div className="row">

            {content.length ? <Fragment>

              {content.map((row, key) => {
                return <Fragment key={key}>


                  <div className="col-md-4">

                    <div className="latest-news-last-six-single">

                      <h3>
                        {row.title.length > 75 &&
                          <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`}>{row.title.substring(0, 73)}...</Link>
                        }
                        {row.title.length <= 75 &&
                          <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`}>{row.title}</Link>
                        }
                      </h3>

                      {row.short_description.length > 123 && <p>
                        {row.short_description.substring(0, 120)}... <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`} > Read More</Link></p>
                      }
                      {row.short_description.length <= 123 && <p>
                        {row.short_description} <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`} > Read More</Link></p>
                      }


                      {row.published_time &&<p> <Timestamp relative date={row.published_time} autoUpdate /></p>}

                    </div>

                  </div>


                </Fragment>
              })}
            </Fragment> : ''}

          </div>

        </div>

      </section>

    );
  }
}


const mapStateToProps = (state) => ({
  setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
  getNews: (requestData) => dispatch(getNews(requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SecondSection);