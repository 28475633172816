import InnerHTML from 'dangerously-set-inner-html';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from 'react-router-dom';
import { setLayout, setPath, setPathLayout } from '../../actions/Utils';
import { host_name } from '../../config/Api';
import NoImage from '../../img/no_image.jpeg';


class PresidentMessageAndPublication extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      home_layout: {
        content: null
      },

    }

  }

  // componentDidMount = () => {



  // }

  static getDerivedStateFromProps(props, state) {

    return {
      home_layout: props.home_layout
    }
  }

  render() {
    const content = this.state.home_layout.content;
    var presidentMessage = [];
    if (content.page_data) {

      var page_section = content.page_data.section;

      const section_data = page_section.find((row) => {
        if (row.name.toLowerCase() == 'president message') {
          return true;
        }
        else {
          return false;
        }
      });
      if (section_data) {
        var presidentMessage = section_data.contents;
     
      }
      var pub_section_data = page_section.find((row) => {
        if (row.name.toLowerCase() == 'publication') {
          return true;
        }
        else {
          return false;
        }
      });

      if (pub_section_data) {
        var publication = pub_section_data.contents;
     
      }
    }

    

    return (
      <section className="message-publication">

        <div className="container">

          <div className="row">

            <div className="col-md-4">

              <div className="president-message">
                {presidentMessage.length&&<Fragment>
               
                {presidentMessage[0] != null&&<InnerHTML html={presidentMessage[0]['description']} />}
                
                </Fragment>}
              </div>

            </div>

            <div className="col-md-8">
            
            <div className="publications">
              
              {pub_section_data && <h1><span>{pub_section_data.title}</span></h1>}

              <Link to={{pathname:`${host_name}publication/`}} target="_blank" className="btn btn-primary">See More</Link>

              <div className="row">
                {
                  publication && publication.map((row,key)=>{
                    return(
                      <Fragment key={key}>
                             <div className="col-md-4">
                  
                                <div className="publications-single">
                                  
                                  <div className="publications-banner">
                                    
                                    <img src={row.image && row.image.feature_image.length>0 ? row.image.feature_image : NoImage} />

                                    <p className="p-category"> {row.categories[0].title}</p>

                                  </div>

                                  <h4>

                                    <Link to={{pathname:`${host_name}publication/news/${row.categories[0]['id']}/${row.id}/details`}} target="_blank">
                                      {
                                        row.title.length>72?row.title.substring(0,72)+"...":row.title
                                      }
                                    </Link>
                                    
                                  </h4>

                                </div>

                              </div>

                      </Fragment>
                     
                    )
                  })
                }
               
              </div>

            </div>

          </div>
          </div>

        </div>

      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  headers: state.header,
  setting: state.utils.setting,
  home_layout: state.contents.home_layout,
})

const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => { dispatch(setPath(requestData)) },
  setLayout: (requestData) => { dispatch(setLayout(requestData)) },
  setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(PresidentMessageAndPublication);