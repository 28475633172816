import InnerHTML from 'dangerously-set-inner-html';
import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import { image_url } from '../../config/Api';
const Member =({member}) =>{
    
  const [hidden, setHidden] = useState({});
    const toggleHide = index => {
        setHidden({ hidden, [index]: !hidden[index] });
      };
    return (
        <Fragment>
            {
                member.length>0? 
                    member.map((row,key)=>{
                        return(
                            <Fragment key={key}>
                            <div className="col-md-3">

                                <div className="council-members-single">
                                    <img src={row.image?image_url+row.image:''} alt="Profile" />

                                    <h3>{row.name} </h3>

                                    <h5>{row.council_designation}</h5>
                                    {!hidden[key] && <Link className="Showdetails" onClick={e => toggleHide(key)}>Details <i className="fa fa-plus-circle"></i></Link>}
                                    {!!hidden[key] &&  <Link className="Hideclose" onClick={e => toggleHide(key)}>Close <i className="fa fa-times-circle"></i></Link> }
                                    
                                </div>
                                {!!hidden[key] && 
                                    <div className="council-members-single member-details-info">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <h3>{row.name}  </h3>

                                                <h5>{row.council_designation}</h5>
                                                <InnerHTML html={row.contact_details} />
                                            </div>
                                            <div className="col-md-7">
                                                <div className="member-details-description">

                                                    <InnerHTML html={row.description} />
                                                </div>
                                                
                                            </div>
                                        </div>
                                       
                                    </div>
                                }
                            </div>
                            </Fragment>
                        )
                    })
                :
                ''
            }
        </Fragment>
    )
}

export default Member
