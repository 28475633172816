//https://levelup.gitconnected.com/show-push-notifications-in-react-449949e98e01
import firebase from 'firebase/app';
import 'firebase/messaging';
import { addPushWebToken } from '../actions/Utils';
import configureStore from '../store/Configurstore';

const storenofity = configureStore();
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC2F98cN5bkPaOX_QXO27y4t-u_IWrV2wI",
  authDomain: "icab-1970a.firebaseapp.com",
  projectId: "icab-1970a",
  storageBucket: "icab-1970a.appspot.com",
  messagingSenderId: "1026076957928",
  appId: "1:1026076957928:web:e79f79e16db1b1e556fd5d",
  measurementId: "G-7ELGC2ZT4E"
};


let messaging=null;
if (firebase.messaging.isSupported()){
  firebase.initializeApp(firebaseConfig);
  messaging = firebase.messaging();
}


const { REACT_APP_VAPID_KEY } = process.env
// const publicKey = REACT_APP_VAPID_KEY;
const publicKey = 'BMyOuFlcaW-y_7l9J2Ko0j3g6VrAAOKneoqk-ZaxkSJHAlPgaDCX6ZvSI_E07Rtl1bwhgooDFEWGjKbpfsXAAkM';


export const getToken = async (setTokenFound) => {
  let currentToken = '';
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
      handlePushNotification(currentToken);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log('An error occurred while retrieving token.', error);
  }
  return currentToken;
};


export const onMessageListener = () =>

  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      console.log("notification on payload", payload);
      resolve(payload);
    });
  });


  
  const handlePushNotification = (token) => {
    var requestData = {
      device_id: token
    }
    storenofity.dispatch(addPushWebToken(requestData)).then((res) => {
      console.log("handle push notification", res);
    });
  }