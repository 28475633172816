import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getGroupOfCommitte } from '../../actions/Utils';
import { image_url } from '../../config/Api';
import Htmlcontent from '../Htmlcontent';
import BannerCenter from '../page/BannerCenter';
import BreadCrumbs from '../page/BreadCrumbs';
import ActivityModal from './ActivityModal';

class GroupOfCommitte extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: {},
            content_loading: true,
            is_modal: false,
            is_open_modal: false,
            modal_content: null,
            show_all_member: false,
            show_all_tor: false,
            current_tab:'',
            show_all_member_index:'',
            show_all_tor_index:''
        }
    }

    componentDidMount = () => {

        const slug = this.props.match.params.slug;
        const requestData = {
            slug: slug
        }
        this.props.getGroupOfCommitte(requestData).then((data) => {

            this.setState({
                content: data,
                content_loading: false,
            })
        });
    }


    static getDerivedStateFromProps(props, state) {
        return {
            committe: props.committe
        }
    }

    handleActivityModal = (content) => {
        this.setState({
            modal_content: content,
            is_open_modal: true
        })
    }

    handleCloseModal = (status) => {

        this.setState({
            modal_content: null,
            is_open_modal: false
        })

    }
    handleMemberShowHide = (status,key) => {

        this.setState({
            show_all_member: status,
            show_all_member_index:key
        })

    }
    handleTorShowHide = (status,key) => {

        this.setState({
            show_all_tor: status,
            show_all_tor_index: key,
        })

    }

    scrollToTestDiv = (id) => {
        const divElement = document.getElementById(id);
        divElement.scrollIntoView({ behavior: 'smooth'});
        if(isMobile)
            window.scrollTo(0, divElement.offsetTop-document.getElementById("main-nav").offsetHeight+200);
        else
            window.scrollTo(0, divElement.offsetTop-document.getElementById("main-nav").offsetHeight+490);
        // document.getElementById("root").scrollTop -= 500;
        this.setState({
          current_tab: id
        })
      }

    render() {


        const { content_loading, is_open_modal, show_all_member, show_all_tor,current_tab,show_all_member_index,show_all_tor_index } = this.state;

        const content_committee = this.props.committe;
        const content = content_committee.content;

        var breadcurmbs = [];

        if (content.length) {
            breadcurmbs = [
                {
                    url: content[0].parentcommitte.slug,
                    title: content[0].parentcommitte.name,
                },
                {
                    url: '/',
                    title: 'Committee',
                },
                {
                    url: '/',
                    title: 'Home',
                },
            ]
        }


        return (



            <Fragment>
                <BannerCenter title={''} menu_name="" banner="" />
                <BreadCrumbs bread_curmbs={breadcurmbs} />

                <section className="who-we-are-page">
                    <div className="container">

                        {content_loading === true && <div className="loader">

                            <div className="sp sp-wave"></div>

                        </div>}

                        {(content_loading == false && content.length) ? <div className="inner-content tabcontent">

                            <h1>{content[0].parentcommitte.name}</h1>

                            {content.map((contentrow, key) => {

                                return (
                                    <Fragment key={key}>


                                        <div className="group-commitee-single" id={`contactdetails-data${key}`}>
                                            <h4 className="committee-title"> {Object.keys(contentrow.committee).length && <Fragment>{contentrow.committee.name}</Fragment>}</h4>


                                            <div className="committee-tabmenu">
                                                <ul className="nav nav-pills committe-nav" id="pills-tab" role="tablist">
                                                    <li className={current_tab === `contactdetails-data${key}` ? 'nav-item active' : 'nav-item'}>
                                                        <a className="nav-link" onClick={() => this.scrollToTestDiv(`contactdetails-data${key}`)}>Contact Details</a>
                                                    </li>
                                                    <li className={current_tab === `member-data${key}` ? 'nav-item active' : 'nav-item'} >
                                                        <a className="nav-link" onClick={() => this.scrollToTestDiv(`member-data${key}`)}>Member</a>
                                                    </li>
                                                    <li className={current_tab === `tor-data${key}` ? 'nav-item active' : 'nav-item'}>
                                                        <a className="nav-link" onClick={() => this.scrollToTestDiv(`tor-data${key}`)}>TOR</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="row profile-committee" >
                                                {contentrow.chairman ? <Fragment>
                                                    <div className="col-md-3">
                                                        {contentrow.chairman.image && <img src={`${image_url}${contentrow.chairman.image}`} />}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h3 className="committee-member-name">
                                                            {contentrow.chairman.name}
                                                            
                                                        </h3>
                                                        <h4 className="committee-member-designation">
                                                            {contentrow.chairman.designation}
                                                        </h4>


                                                        <h3 className="border-title">Contact Details</h3>

                                                        <div className="committee-contact-details">
                                                            <Htmlcontent data={contentrow.chairman.contact_details} />
                                                        </div>


                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="committe-whats-news">
                                                            <h3 className="border-title">What's New </h3>
                                                            <ul className="committee-activity">

                                                                {contentrow.committee_activity.length ? <Fragment>
                                                                    {contentrow.committee_activity.map((row, key) => {
                                                                        return (

                                                                            <Fragment>
                                                                                {row.content_type == 1 && <li key={key}>
                                                                                    <i className="fas fa-angle-double-right"></i>
                                                                                    <a className="activity-pdf-link" href={`${image_url}${row.pdf}`} target="_blank">{row.title} </a>
                                                                                </li>}
                                                                                {row.content_type == 0 && <li key={key} onClick={() => this.handleActivityModal(row)}>
                                                                                    <i className="fas fa-angle-double-right"></i>
                                                                                    {row.title}
                                                                                </li>}

                                                                            </Fragment>
                                                                        )

                                                                    })}

                                                                </Fragment> : ''}
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </Fragment> : ''}
                                            </div>

                                            {contentrow.co_chairman ? <div className="table-style commitiee-table table-responsive">
                                                <table className="table cochairmantable" id="cochairman-data">
                                                    <thead>
                                                        <tr>
                                                            <th>Co-Chairman</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>

                                                                {contentrow.co_chairman.image && <div className="co-chairman"><img src={`${image_url}${contentrow.co_chairman.image}`} /> </div>}
                                                                {contentrow.co_chairman.name}

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> : ''}

                                            {contentrow.member ? <div className="table-style commitiee-table table-responsive">
                                                <table className="table" id={`member-data${key}`}>
                                                    <thead>
                                                        <tr>
                                                            <th colspan="2">Members</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {contentrow.member.map((row, mkey) => {
                                                            return (
                                                                <Fragment>
                                                                    {(mkey < 4 && (show_all_member == false || show_all_member_index != key)) && <Fragment>
                                                                        <tr>
                                                                            <td>{mkey + 1}</td>
                                                                            <td>

                                                                                <div className="committee-member-td">
                                                                                    <div className="name">{row.name}</div>
                                                                                    {row.contact_details && <div className="address">

                                                                                        <Htmlcontent data={row.contact_details} />

                                                                                    </div>}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>}
                                                                    {(show_all_member == true && show_all_member_index == key) && <Fragment>
                                                                        <tr>
                                                                            <td>{mkey + 1}</td>
                                                                            <td>
                                                                                <div className="committee-member-td">
                                                                                    <div className="name">{row.name}</div>
                                                                                    {row.contact_details && <div className="address">

                                                                                        <Htmlcontent data={row.contact_details} />

                                                                                    </div>}
                                                                                </div>



                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>}

                                                                </Fragment>
                                                            )
                                                        })}


                                                    </tbody>
                                                </table>
                                            </div> : ''}

                                            {(contentrow.member.length > 3 && (show_all_member == false || show_all_member_index != key ))&& <div className="show-more" onClick={() => this.handleMemberShowHide(true,key)}>See More</div>}

                                            {(show_all_member == true && show_all_member_index == key) && <div className="show-less" onClick={() => this.handleMemberShowHide(false,'')}>See Less</div>}


                                            {contentrow.secretary.length ? <p className="mt-3"><strong>Member Secretary : </strong>
                                                {contentrow.secretary.map((row, key) => {
                                                    return (
                                                        <Fragment>
                                                            {row.name},&nbsp;{row.designation} &nbsp;
                                                        </Fragment>
                                                    )
                                                    return false;
                                                })}
                                            </p> : ''}


                                            {(Object.keys(contentrow.committee).length && contentrow.committee.tor)? <div id={`tor-data${key}`} className={(show_all_tor == true && show_all_tor_index == key) ? 'tor-wrap tor-load-more' : 'tor-wrap tor-load-less'}>

                                                <h3 className="border-title">TERMS OF REFERENCE (TOR)</h3>
                                                <div className="committee-tor">
                                                    <Htmlcontent data={contentrow.committee.tor} />
                                                </div>

                                                {contentrow.committee.tor.length > 700 && <Fragment>
                                                    {(show_all_tor == false ||  show_all_tor_index != key) && <div className="show-more" onClick={() => this.handleTorShowHide(true,key)}>See More</div>}

                                                    {(show_all_tor == true && show_all_tor_index == key) && <div className="show-less" onClick={() => this.handleTorShowHide(false,'')}>See Less</div>}
                                                </Fragment>}

                                            </div> : ''}






                                        </div>

                                        <br />
                                        <hr />
                                        <br />
                                    </Fragment>
                                )
                            })}
                            {is_open_modal && <ActivityModal handleCloseModal={this.handleCloseModal} content={this.state.modal_content} />}

                        </div> : ''}
                    </div>
                </section>

            </Fragment>

        );
    }
}

const mapStateToProps = (state) => ({
    committe: state.contents.committe_content.groupcommitte,
})

const mapDispatchToProps = (dispatch) => ({
    getGroupOfCommitte: (requestData) => dispatch(getGroupOfCommitte(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupOfCommitte));