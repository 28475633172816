

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getCslDheerajapi } from '../../actions/Utils';
import MemberLeftsidebar from './MemberLeftsidebar';
class BillingStatement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      content_loading: true,
      is_open_modal: false,
      modal_content: null,
      show_all_member: false,
      show_all_tor: false,
      current_tab: 'contactdetails-data',
      startdate:new Date(),
      enddate:new Date(),
      ledger_data:[]
    }

  }

  componentDidMount = () => {
    
    
  }



  static getDerivedStateFromProps(props, state) {
    return {
      committe: props.committe
    }
  }

  handleActivityModal = (content) => {
    this.setState({
      modal_content: content,
      is_open_modal: true
    })
  }

  handleCloseModal = (status) => {

    this.setState({
      modal_content: null,
      is_open_modal: false
    })

  }
  handleMemberShowHide = (status) => {
    this.setState({
      show_all_member: status
    })

  }
  handleTorShowHide = (status) => {

    this.setState({
      show_all_tor: status
    })

  }

  scrollToTestDiv = (id) => {
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });

    this.setState({
      current_tab: id
    })
  }

  setStartDate=(date)=>{
    this.setState({
      startdate: date
    })
  }

  setEndDate=(date)=>{
    this.setState({
      enddate: date
    })
  }
  SearchResult=()=>{
    const billingRequest = {
      customerCode: localStorage.getItem('member_id'),
      fromDate: new Date(this.state.startdate).toLocaleDateString("en-US"),
      toDate: new Date(this.state.enddate).toLocaleDateString("en-US")
    };
    this.props.getCslDheerajapi(billingRequest, "LedgerAccount", 'get').then((data) => {
      console.log(data);
      this.setState({
        ledger_data:data.Result
      });
      /* // $('#billing').DataTable().clear();
      $('#billing').DataTable().destroy();
      $(document).ready(function () {
        setTimeout(function(){
        $('#billing').DataTable({
          searching: false, 
          paging: false, 
          info:false, 
          sort:false,
          responsive: true
        });
         } ,1000);
      }); */
    });
  }
  render() {
    
    const ledger_data=this.state.ledger_data;
    return (
      <Fragment>
        <section className="dashboard">
          <div className="container">

            <div className="row">

              <div className="col-md-3">

                <div className="member-dashboard-menu mb-5">

                  <MemberLeftsidebar activemenu="billingstatement" />

                </div>

              </div>

              <div className="col-md-9">
                <div className="member-dashboard-content">
                  <div className="members-title">
                    <h3>Billing Statement</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      {<h2>Billing Statement In Progress</h2>}
                        {/* <div className="form-group">
                          <label htmlFor="dateFrom">Date From :</label>
                          <DatePicker className="form-control" selected={this.state.startdate} onChange={(date) => this.setStartDate(date)} />
                        </div>
                        <div className="form-group">
                          <label htmlFor="dateFrom">Date To :</label>
                          <DatePicker className="form-control" selected={this.state.enddate} onChange={(date) => this.setEndDate(date)} />
                        </div>
                        <div className="form-group">
                          <button className="btn btn-info" onClick={()=>this.SearchResult()}>Search Result</button>
                        </div>
                        <div className="table-style  member-online-payment-table mt-3">
                          {
                            ledger_data.length>0?
                            <table id= "billing" className="table display nowrap">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th></th>
                                  <th>Particular</th>
                                  <th>Year</th>
                                  <th>Vch Type</th>
                                  <th>Vch No.</th>
                                  <th>Inv No./Ref/Money Recipts</th>
                                  <th>Debit</th>
                                  <th>Cridit</th>
                                  <th>Balance</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  ledger_data.map((row,key)=>{
                                  return(
                                    <Fragment key={key}>
                                    <tr>
                                      <td>
                                        <Moment format="DD/MM/YYYY">
                                          {new Date(row.VoucherDate)}
                                        </Moment>
                                      </td>
                                      <td>{row.Particulars}</td>
                                      <td>{row.AccountName}</td>
                                      <td>{row.DisplayBatchYear}</td>
                                      <td>{row.VoucherCategoryName}</td>
                                      <td>{row.VoucherNo}</td>
                                      <td>{row.InvNoRefMoneyReceipt}</td>
                                      <td>{row.Debit}</td>
                                      <td>{row.Seq!=1?row.Credit:0}</td>
                                      <td>{row.Debit - row.Seq!=1?row.Credit:0}</td>
                                    </tr>
                                    </Fragment>
                                  )
                                  })
                                }
                              </tbody>
                            </table>
                            :''
                          } 
                        </div>
                        */}
                    </div>



                  </div>

                </div>

              </div>


            </div>

          </div>
        </section>

      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  committe: state.contents.committe_content.singlecommitte,
})

const mapDispatchToProps = (dispatch) => ({
  getCslDheerajapi: (requestData, actionName, RequestMethod) => dispatch(getCslDheerajapi(requestData, actionName, RequestMethod)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BillingStatement));