const userReducer = {loginuser:{},isLogin:false}

export default(state = userReducer,action) =>{
    switch (action.type) {
        case 'ADD_USER':
            return {
                ...state,
                loginuser: {...action.user}
            }
        case 'SET_LOGEDUSER':
          
            return {
                ...state,
                loginuser: {...action.user}
            }
        case 'SET_LOGOUT':
        
            return {
                ...state,
                loginuser: {},
                isLogin:false,
            }
        case 'SET_IS_LOGIN':
            return {
                ...state,
                isLogin:action.status
            }
        default:
            return state;
            
    }
}