// import logo from './logo.svg';
import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router, Route, Switch
} from "react-router-dom";

import Cookies from 'js-cookie';
import * as qs from 'query-string';

import { addPushWebToken, getRoutes, getSetting } from './actions/Utils';
import './App.css';
import GroupOfCommitte from './components/committe/GroupOfCommitte';
import SingleCommitte from './components/committe/SingleCommitte';
import Footer from './components/common/Footer';
import CommonHeader from './components/common/header/Commonheader';
import PhotoGallery from './components/gallery/PhotoGallery';
import VideoGallery from './components/gallery/VideoGallery';
import BillingStatement from './components/memberpanel/BillingStatement';
import ClaimCPDCreditHours from './components/memberpanel/ClaimCPDCreditHours';
import CopCertificate from './components/memberpanel/CopCertificate';
import CopRenewal from './components/memberpanel/CopRenewal';
import CpdCreditHours from './components/memberpanel/CpdCreditHours';
import FcaAdmissionProcess from './components/memberpanel/FcaAdmissionProcess';
import GoodStandingCertificate from './components/memberpanel/GoodStandingCertificate';
import MemberDashbaord from './components/memberpanel/MemberDashbaord';
import MemberDirectory from './components/memberpanel/MemberDirecotry';
import MemberProfile from './components/memberpanel/MemberProfile';
import OnlinePayment from './components/memberpanel/OnlinePayment';
import Others from './components/memberpanel/Others';
import PermissionTrainArticledStudent from './components/memberpanel/PermissionTrainArticledStudent';
import { base_path } from './config/Api';
import AricleSubmissionForm from './layout/AricleSubmissionForm';
import Contactus from './layout/Contactus';
import Faq from './layout/Faq';
import FindCA from './layout/FindCA';
import FindCAFirm from './layout/FindCAFirms';
import HomeLayout from './layout/Home';
import Login from './layout/Login';
import MemberDesboard from './layout/MemberDesboard';
import Member from './layout/MemberLayout';
import NewsLayout from './layout/News';
import PageLayout from './layout/Page';
import Register from './layout/Register';
import SearchLayout from './layout/SearchLayout';
import CommonLanding from './page/CommonLanding';
import AnnualReport from './publication/page/AnnualReport';
import CommonList from './publication/page/CommonList';
// Publication component start
import PublicationHome from './publication/page/Home';
import NewsBulletin from './publication/page/NewsBulletin';
// Publication component end
import configureStore from './store/Configurstore';

import ChangePassword from './components/memberpanel/ChangePassword';
import ForgotPassword from './layout/ForgotPassword';
import ResetPassword from './layout/ResetPassword';

// import firebase from './config/Firebase';
// import { getMessaging, getToken } from "firebase/messaging";

// import firebase from 'firebase/compat/app';
// import { getMessaging, getToken ,onMessage} from "firebase/messaging";

import Fader from './components/Notifications/Fader';
import { onMessageListener, onBackgroundMessage } from './config/FirebaseInit'
import Notifications from './components/Notifications/Notifications';
import ReactNotificationComponent from './components/Notifications/ReactNotification';

import Htmlhelmet from './components/common/HtmlHelmet';

import PageNotFound from './page/Page404';

const store = configureStore();

// const messagingab = getMessaging()
// onMessage(messagingab, (payload) => {
//   console.log("message firebsae payload",payload);
// })

function User(props) {
  if (props.location.search) {
    const parsed = qs.parse(props.location.search);
    var login_token = Cookies.get('login_token');
    var callBackUrl = parsed.callbackUrl;
    if (callBackUrl.indexOf('http') == -1) {
      callBackUrl = 'http://' + callBackUrl;
    }
    if (login_token && login_token == localStorage.getItem('token')) {
      if (callBackUrl.indexOf("?") == -1) {
        callBackUrl += "?token=" + login_token;
      } else {
        callBackUrl += "&&token=" + login_token;
      }
    }

    console.log("APP USER IF=", callBackUrl);
    window.location.href = callBackUrl;
  }
  else {
    console.log("APP USER ELSE=");
    props.history.push(`${process.env.PUBLIC_URL}/`);
  }
  return true;
}





class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utils: store.getState().utils,
      routemenu: store.getState().utils.routemenu,
      pubroutemenu: store.getState().utils.pubroutemenu,
      notificaitonshow: false,
      notification: {
        title: '',
        body: ''
      }
    };


  }

  handlePushNotification = (token) => {
    var requestData = {
      device_id: token
    }
    store.dispatch(addPushWebToken(requestData)).then((res) => {
      console.log("handle push notification", res);
    });
  }



  componentDidMount = () => {



    //  const messaging = getMessaging();
    // getToken(messaging, { vapidKey: 'BMyOuFlcaW-y_7l9J2Ko0j3g6VrAAOKneoqk-ZaxkSJHAlPgaDCX6ZvSI_E07Rtl1bwhgooDFEWGjKbpfsXAAkM' }).then((currentToken) => {
    //   if (currentToken) {
    //     console.log("firebase token=", currentToken);
    //     // this.handlePushNotification(currentToken);
    //   } else {
    //     // Show permission request UI
    //     console.log('No registration token available. Request permission to generate one.');
    //     // ...
    //   }
    // }).catch((err) => {
    //   console.log('An error occurred while retrieving token. ', err);
    //   // ...
    // }); 



    // onMessageListener().then((playload) => {
    //     console.log("notification playload:",playload);
    // })
    //   .catch((error) => {
    //     console.log("notification Failed:", error);
    //   })


    store.dispatch(getRoutes()).then((res) => {
      // console.log(" route data = ",res);
      this.setState({ routemenu: res })
    });
    // store.dispatch(getPubRoutes()).then((res) => {
    //   // console.log(" route data = ",res);
    //   this.setState({ pubroutemenu: res })
    // });




    // store.dispatch(getTopMenu()).then((res) => {
    //   // console.log('Header data = ',res);
    // });
    // store.dispatch(getRightSiteMenu()).then((res) => {
    //   // console.log('Header data = ',res);
    // });
    // // store.dispatch(getHeaderSection()).then((res) => {
    // //   // console.log('Header data = ',res);
    // // });

    // store.dispatch(getFooterSection()).then((res) => {
    //   // console.log('Footer data = ',res);
    // });
    store.dispatch(getSetting()).then((res) => {
      // console.log('Footer data = ',res);
    });



  }

  render() {
    // console.log('app routemenu js file = ',this.state.routemenu);
    const routemenu = this.state.routemenu;
    const pubroutemenu = [];//this.state.pubroutemenu;

    var site = base_path.split("/");
    // let pathName = this.state.utils.path;

    // console.log("routemenu =",routemenu);

    var basename = "/";
    if (site.includes("www.icab.com") || site.includes("3000")) {
      basename = "/";
    }

    else if (site.includes("devtest")) {
      basename = "/devtest/icab_frontend2/publication/";
    }
    else if (site.includes("icab_reactjs")) {
      basename = "/icab_reactjs/";
    }
    else if (site.includes("icab")) {
      basename = "/icab/";
    }
    else if (site.includes("testicab")) {
      basename = "/testicab/";
    }
    else if (site.includes("live_test")) {
      basename = "/live_test/";
    }
    else {
      basename = "/";
    }
    //  console.log(" Hello process.env.PUBLIC_URL =",process.env.PUBLIC_URL);
    return (
      <Router basename={basename}>
        {/* <Router basename={'/publication/'}> */}
        <Fragment>
          {/* <HeaderLoad /> */}
          <Notifications />
          <CommonHeader />
          <Htmlhelmet />
          <Switch>
            <Route exact path={`/`} >
              <HomeLayout />
            </Route>
            
            <Route path={`${process.env.PUBLIC_URL}/login`}>
              <Login />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/register`}>
              <Register />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/member-dashboard`}>
              <MemberDesboard />
            </Route>

            <Route exact path={`${process.env.PUBLIC_URL}/page/find-member`}>
              <FindCA />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/page/find-ca-firms`}>
              <FindCAFirm />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/page/article-submission-form`}>
              <AricleSubmissionForm />
            </Route>

            <Route path={`${process.env.PUBLIC_URL}/page/:slug`}>
              <PageLayout />
            </Route>

            <Route path={`${process.env.PUBLIC_URL}/news/:slug`}>
              <NewsLayout />
            </Route>


            <Route path={`${process.env.PUBLIC_URL}/search/:slug`}>
              <SearchLayout />
            </Route>

            <Route exact path={`${process.env.PUBLIC_URL}/videos/:id/album`}>
              <VideoGallery />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/photos/:id/album`}>
              <PhotoGallery />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/committe/:slug`}>
              <GroupOfCommitte />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/committe/single/:slug`}>
              <SingleCommitte />
            </Route>


            <Route exact path={`${process.env.PUBLIC_URL}/member/dashboard`}>
              <MemberDashbaord />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/member/profile`}>
              <MemberProfile />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/member/billing-statement`}>
              <BillingStatement />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/member/onlinepayment`}>
              <OnlinePayment />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/member/member-directory`}>
              <MemberDirectory />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/member/cpd-credit-hours`}>
              <CpdCreditHours />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/member/cliam-cpd-credit-hours`}>
              <ClaimCPDCreditHours />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/member/fca-admission-process`}>
              <FcaAdmissionProcess />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/member/cop-certificate`}>
              <CopCertificate />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/member/permission-to-train-articled-student`}>
              <PermissionTrainArticledStudent />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/member/cop-renewal`}>
              <CopRenewal />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/member/good-standing-certificate`}>
              <GoodStandingCertificate />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/member/others`}>
              <Others />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/logout`}>
              <MemberDashbaord />
            </Route>

            <Route exact path={`${process.env.PUBLIC_URL}/member/change-password`}>
              <ChangePassword />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/forgot-password`}>
              <ForgotPassword />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/reset-password`}>
              <ResetPassword />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/user-login-check-from-outside`} component={User}>

            </Route>

            


            {/* <Route exact path="/">
              <PublicationHome />
            </Route> */}


            {/* <Route exact path={`/news/:id/details`} >
              <NewsDetails/>
            </Route> */}



            {routemenu.map((menu, index, { length }) => {
              if (menu.layout) {
                //if(index + 1 === length) { 
                if (menu.layout.name.toLowerCase() == 'home') {
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <HomeLayout />} />
                }
                if (menu.layout.name.toLowerCase() == 'common landing') {

                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <CommonLanding />} />

                }

                if (menu.layout.name.toLowerCase() == 'faq') {
                  
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <Faq />} />


                }
                if (menu.layout.name.toLowerCase() == 'gallery') {
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <CommonLanding />} />


                }


                if (menu.layout.name.toLowerCase() == 'contact us') {
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <Contactus />} />
                }


                if (menu.layout.name.toLowerCase() == 'member') {
                  console.log(this.props);
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <Member />} />
                }


              }
              else {
               
                
                return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <PageNotFound />} />
              }




              // if(index + 1 === length) { 
              //   return <Fragment key={index}>
              //     <Route key={menu.id} exact path={menu.slug} render={() => <CommonComponent />}/>
              //     {/* <Route path="*" component={() => "404 NOT FOUND"} /> */}
              //   </Fragment>
              // }
              // else {
              //   return <Route key={menu.id} exact path={menu.slug} render={() => <CommonComponent />}/>
              // }

            })}

            {pubroutemenu.map((menu, index, { length }) => {
              // console.log('pubroutemenu =',menu);
              if (menu.id === 1) {
                return <Route key={menu.id} exact path={`/${menu.slug}`} render={() => <PublicationHome />} />
              }
              if (menu.id === 3) {
                return <Route key={menu.id} exact path={`/${menu.slug}`} render={() => <NewsBulletin />} />
              }
              if (menu.id === 6) {
                return <Route key={menu.id} exact path={`/${menu.slug}`} render={() => <AnnualReport />} />
              }
              if (menu.id !== 1 || menu.id !== 3 || menu.id !== 6) {
                return <Route key={menu.id} exact path={`/${menu.slug}`} render={() => <CommonList />} />
              }



            })}
            
            <Route path="*" render={() => <PageNotFound />} />
            
          </Switch>
          <Footer />
          {/* <FooterLoad /> */}
        </Fragment>
      </Router>
    );
  }
}

export default App;

