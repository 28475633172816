import axios from 'axios';

var token = localStorage.getItem("token")?'Token '+localStorage.getItem("token"):'';
// export default axios.create({
//   // baseURL: `http://jsonplaceholder.typicode.com/`
//   baseURL: `http://127.0.0.1:8000/`,
//   headers: {'Authorization': token}
// });

const axiosInstance = axios.create();

// axiosInstance.defaults.baseURL = 'http://localhost/icab_admin/api/';
// axiosInstance.defaults.baseURL = 'http://192.168.10.116/icab_admin/api/';
// axiosInstance.defaults.baseURL = 'http://192.168.10.106:8081/icab_admin/api/';
// axiosInstance.defaults.baseURL = 'http://202.191.121.20/devtest/icab_admin2/api/';
 axiosInstance.defaults.baseURL = 'https://www.icab.org.bd/icabadmin/api/';
 //axiosInstance.defaults.baseURL = 'http://localhost:400/icab_admin/api/';
// axiosInstance.defaults.baseURL = 'https://36.255.69.193/icabadmin/api/';

export const casInstance = axios.create();
casInstance.defaults.baseURL = 'https://api.icab.org.bd/api/';
export const caversion = 'v1/';
casInstance.defaults.headers.common['Authorization'] = 'Basic Y2xpZW50LXdlYjpVQTAxMjM0NTY=';


export const icabInstance = axios.create();
icabInstance.defaults.baseURL = 'https://webapi.icab.org.bd/';
// icabInstance.defaults.baseURL = 'http://36.255.68.140:2023/';
icabInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const cslDheerajApi = axios.create();
//cslDheerajApi.defaults.baseURL = 'http://36.255.69.225:5090/ICAB/';
cslDheerajApi.defaults.baseURL = 'https://erp.icab.org.bd/ICAB/';

cslDheerajApi.defaults.headers.common['Authorization'] = 'Basic RGhlZXJhakFwaTplZmViOTUwOTYzMWZkZjcxOTVhYzY1MTU1NDRjNmQ3Mg==';

export const cpdAPI = axios.create();
cpdAPI.defaults.baseURL = 'https://adminapi.icab.org.bd';

// axiosInstance.defaults.headers.common['Authorization'] = token;
// axiosInstance.defaults.headers.common['User-ID'] = '';

export const version = 'v1/';
export const imagePath = 'v1/';


export const processMaker = axios.create();
processMaker.defaults.baseURL = 'https://studentservices.icab.org.bd/sysworkflow/en/neoclassic/services/soap2';
processMaker.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// export const image_url = 'http://192.168.10.116/icab_admin/';
export const image_url = 'https://www.icab.org.bd/icabadmin/';
 //export const image_url = 'http://localhost:400/icab_admin/';
// export const image_url = 'http://192.168.10.106:8081/icab_admin/';
// export const image_url = 'http://202.191.121.20/devtest/icab_admin2/';
// export const image_url = 'http://192.168.10.116/icab_admin/';

// export const base_path = 'http://192.168.10.116/icab_frontend/';
// export const base_path = 'http://localhost:8081/icab_front_end/';
// export const base_path = 'http://192.168.10.116/icab_admin/';
// export const base_path = 'http://192.168.10.106/icab_front_end/';
// export const base_path = 'http://192.168.10.106:3000';


export const base_path = '/';
// export const base_path = 'https://www.icab.org.bd/live_test/';
export const host_name = 'https://www.icab.org.bd/';
//export const host_name = 'http://localhost:3000/';
// export const base_path = 'http://202.191.121.20/devtest/icab_frontend2/';

export const encryption_salt="ICAB@2021";
export const google_recaptcha_site_key = '6Le2E5gaAAAAADPCWtOtzWkaBwQ1jAlPtjP7XRuK';

export default axiosInstance;

 