import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { parseString } from 'xml2js';
import { getSingleOfCommitte } from '../../actions/Utils';
import { processMaker } from '../../config/Api';
import MemberLeftsidebar from './MemberLeftsidebar';
class PermissionTrainArticledStudent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      content_loading: true,
      is_open_modal: false,
      modal_content: null,
      show_all_member: false,
      show_all_tor: false,
      current_tab: 'contactdetails-data',
      session_id:null,
      case_id:null,
      iframe_url:null
    }

  }

  componentDidMount = () => {

    const slug = this.props.match.params.slug;
    const requestData = {
      slug: slug
    }
    if(localStorage.getItem("token") == null) {
      this.props.history.push(`${process.env.PUBLIC_URL}/login`);
    }
    this.props.getSingleOfCommitte(requestData).then((data) => {

      this.setState({
        content: data,
        content_loading: false,
      })
    });
    var process_maker_login_xml_body='<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:www="http://www.processmaker.com">\
      <x:Header/>\
      <x:Body>\
      <www:login>\
      <www:userid>'+localStorage.getItem('member_id')+'</www:userid>\
      <www:password>'+localStorage.getItem('member_pass')+'</www:password>\
      </www:login>\
      </x:Body>\
    </x:Envelope>\
    ';
    processMaker.post('',
      process_maker_login_xml_body,
    {headers:
      {'Content-Type': 'text/xml; charset=utf-8','soapAction':'http://www.processmaker.com/login'}
    }).then(res=>{
      
      parseString(res.data,(err,result)=>{
        var status=result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:loginResponse']['0']['ns1:status_code']['0'];
        if(status==0)
        {
            var session_id=result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:loginResponse']['0']['ns1:message']['0'];
            this.setState({
              session_id: session_id
            });
            window.location.href = 'https://memberservices.icab.org.bd/process/train-articled-students.php?sid='+session_id;
           /*  var xml_for_case_id='<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:www="http://www.processmaker.com">\
            <x:Header/>\
            <x:Body>\
            <www:newCaseRequest>\
            <www:sessionId>'+this.state.session_id+'</www:sessionId>\
            <www:processId>941741813610d57555eabf6049384168</www:processId>\
            <www:taskId>558278033610ec1e20f27b5036346525</www:taskId>\
            </www:newCaseRequest>\
            </x:Body>\
            </x:Envelope>';
            processMaker.post('',
            xml_for_case_id,
                {headers:
                  {'Content-Type': 'text/xml; charset=utf-8','soapAction':'urn:newCase'}
                }).then(res=>{
                  
                  parseString(res.data,(err,result)=>{
                    var status=result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:newCaseResponse']['0']['ns1:status_code']['0'];
                    if(status==0)
                    {
                      this.setState({
                        case_id: result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:newCaseResponse']['0']['ns1:caseId']['0']
                      });
                      this.setState({
                        iframe_url: 'http://studentservices.icab.org.bd/sysworkflow/en/neoclassic/cases/cases_Open?APP_UID='+this.state.case_id+'&DEL_INDEX=1&action=draft&sid='+this.state.session_id
                        
                      });
                      // window.open('http://studentservices.icab.org.bd/sysworkflow/en/neoclassic/cases/cases_Open?APP_UID='+this.state.case_id+'&DEL_INDEX=1&action=draft&sid='+this.state.session_id);
                      window.location.href = 'http://studentservices.icab.org.bd/sysworkflow/en/neoclassic/cases/cases_Open?APP_UID='+this.state.case_id+'&DEL_INDEX=1&action=draft&sid='+this.state.session_id;
                    }
                  })
                }).catch(err=>{ console.log('process maker error');console.log(err)}); */
              }
            })
          }).catch(err=>{ console.log('process maker error');console.log(err)});
  }



  static getDerivedStateFromProps(props, state) {
    return {
      committe: props.committe
    }
  }

  handleActivityModal = (content) => {
    this.setState({
      modal_content: content,
      is_open_modal: true
    })
  }

  handleCloseModal = (status) => {

    this.setState({
      modal_content: null,
      is_open_modal: false
    })

  }
  handleMemberShowHide = (status) => {

    this.setState({
      show_all_member: status
    })

  }
  handleTorShowHide = (status) => {

    this.setState({
      show_all_tor: status
    })

  }

  scrollToTestDiv = (id) => {
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });

    this.setState({
      current_tab: id
    })
  }


  render() {


    const { content_loading, is_open_modal, show_all_member, show_all_tor, current_tab } = this.state;

    const content_committee = this.props.committe;
    const content = content_committee.content;

    var breadcurmbs = [];

    if (Object.keys(content).length) {
      breadcurmbs = [
        {
          url: content.committee.slug,
          title: content.committee.name,
        },
        {
          url: '/',
          title: 'Committee',
        },
        {
          url: '/',
          title: 'Home',
        },
      ]
    }



    return (

      <Fragment>


        <section className="dashboard">
          <div className="container">

            <div className="row">

              <div className="col-md-3">

                <div className="member-dashboard-menu mb-5">

                  <MemberLeftsidebar activemenu="permission-to-train-articled-student" />

                </div>

              </div>

              <div className="col-md-9">

                <div className="member-dashboard-content">

                  <div className="members-title">

                    <h3>Apply FCA</h3>

                  </div>

                  <div className="row">


                    <div className="col-md-12">
                      {
                        /* this.state.iframe_url ?

                        <iframe
                          src={this.state.iframe_url}
                          width="100%"
                          height="700"
                          onLoad={this.hideSpinner}
                          frameBorder="0"
                          marginHeight="0"
                          marginWidth="0"
                          title="Fca Admission Process"
                        ></iframe>:
                        '' */
                      }
                    </div>



                  </div>





                </div>

              </div>


            </div>

          </div>
        </section>

      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  committe: state.contents.committe_content.singlecommitte,
})

const mapDispatchToProps = (dispatch) => ({
  getSingleOfCommitte: (requestData) => dispatch(getSingleOfCommitte(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PermissionTrainArticledStudent));