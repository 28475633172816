const FooterReducer = {}

export default(state = FooterReducer,action) =>{
    switch (action.type) {
        case 'INIT_FOOTER':
            return {
                ...state,
                ...action.data
            }
        default:
            return state;
            
    }
}