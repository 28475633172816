import React, { Fragment } from 'react';
import BannerLeft from '../BannerLeft';
import BreadCrumbs from '../BreadCrumbs';
import Content from '../Content';



const Common=({page_data})=> {
    const {title}=page_data;
    /* const {contents :{short_description=""}={}}=page_data; */
    const {bread_curmbs}=page_data;
    const{image:banner_img=""}=page_data;
    const{contents}=page_data;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    return (
        <Fragment>
            <BannerLeft title={title}  menu_name={bread_curmbs[0].title} banner={banner_img}/>
            <BreadCrumbs bread_curmbs={bread_curmbs} />
            <section className="who-we-are-page">
                <div className="container">
                    <div className="inner-content">
                        <h1>{title}</h1>
                        <Content content={contents}/>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Common
