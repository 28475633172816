import React, { Fragment } from 'react';
import BannerLeft from '../BannerLeft';
import BreadCrumbs from '../BreadCrumbs';
import CAFirm from '../CAFirm';
import Pagination from '../Pagination';


const Common=({page_data,firm,per_page,page_no,prev,next,page_no_click,Search,Search_text_change})=> {
  
    const {title}=page_data;
    const {bread_curmbs}=page_data;
    const{image:banner_img=""}=page_data;
    const{contents}=page_data;
    const {short_description=" "}=contents!=null?contents:{};
    const {firminfos={}}=firm;
    return (
        <Fragment>
            <BannerLeft title={title} short_description={short_description} menu_name={bread_curmbs[0].title} banner={banner_img}/>
            <BreadCrumbs bread_curmbs={bread_curmbs} />
            <section className="member-list inner-content">

                <div className="container">

                    <div className="member-search-box">
                    
                        <form onSubmit={Search}>
                            <input type="text" placeholder="Enter Firm Name" name="" onChange={Search_text_change.bind(this)}/>
                            <input type="submit" className="btn btn-primary" value="Search" onClick={Search}/>
                        </form>

                    </div>

                    <div className="clearfix"></div>
                    <div className="inner-content">
                        <h1>All CA Firms</h1>
                        {
                            firminfos.length>0?
                            <CAFirm firm={firminfos}/>
                            :
                            "No Member Found"
                        }
                        <Pagination total_data={firm.datacount} per_page={per_page} page_no={page_no} prev={prev} next={next} page_no_click={page_no_click}/>
                    </div>

                </div>

                </section>
        </Fragment>
    )
}

export default Common
