import React from 'react';
import { Link } from "react-router-dom";

const PageNotFound = () => {
    return <section class="page_404">

    <div class="container">

      <div class="text-center">
        
        <img src="https://www.icab.org.bd/css/img/404.png" alt=""/>
        
        <h1>404</h1>

        <h4>Page not found!</h4>

        <a href="https://www.icab.org.bd/" class="btn">Go To Home</a>

      </div>

    </div>

  </section>
}

export default PageNotFound;