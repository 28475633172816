import React, { Fragment, Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Htmlcontent from '../Htmlcontent';
import { Link } from 'react-router-dom';
import { image_url } from '../../config/Api';
import ModalOne from './ModalOne'

import { getHomePageModal,setModalPopupStatus } from "../../actions/Utils";


class Sitemaintenance extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message: '',
      message_type: '',
      home_modal: '',
      modal_show: false,
      popupmodalads:{}
    }
  }

  componentDidMount = () => {

 
  }

  static getDerivedStateFromProps(props, state) {

 
  }
  
  closeModal() {
    //
  }


  render() {
  

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height:'300px',
        width:'500px',
        textAlign:'center',
        paddingTop: '100px'
      },
    };

    return (
      <Fragment>
      <div className="homepopup-modal">

<Modal
  isOpen={true}
  style={customStyles}
  contentLabel="homepopup Modal"
  className="homepopup-modal-body"
>
  
 <div className="homepopup-description-image">
    <Htmlcontent data={''} />
  </div>

 
</Modal>
</div>
      </Fragment>

    )
  }

}



export default connect(null, null)(Sitemaintenance);