import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { image_url } from '../../../config/Api';
import { getCategoryNews } from '../../actions/Publication';
import Timestamp from 'react-timestamp';

class SecondSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      slug:'',
      loading:false,
    }
    this.contentLoad = this.contentLoad.bind(this);

  }

  componentDidMount = () => {
    var slug = this.props.slug;
    if(slug.charAt(0) == '/') {
      slug = slug.substring(1,slug.length);
    }
    const requestData = {
      page: 1
    }

    const req = this.props.getCategoryNews(slug, requestData).then((data) => {
      this.setState({
        content: data,
        slug:slug
      })
    });


  }

  contentLoad = () => {
    const {content,slug} = this.state;
     const current_page = content.current_page;
     const to = content.to;
     const total = content.total;
     const requestData = {
       page: current_page+1
     }
     const req = this.props.getCategoryNews(slug, requestData).then((data) => {
      
     
      const getcontent = data;
      const newdata = data.data;
      delete data.data;
  
      var newstate = {
        ...this.state,
        content:{
          ...data,
          data:[
            ...this.state.content.data,
            ...newdata
          ]
        }
      }
      this.setState({
        ...newstate
      })

     });
  }



  // static getDerivedStateFromProps(props, state) {


  // }

  render() {

    const { content } = this.state;

    // console.log(" content state = ", this.state);
    return (
      <section className="news-bulletin">

        <div className="container">

          <div className="news-bulletin-title">

            <h1>News Bulletin</h1>

          </div>


          {Object.keys(content).length ? <Fragment>
            {content.data.map((row, key) => {
              return (
                <Fragment key={key}>
                  <div className="news-bulletin-single">

                    <div className="row">

                      <div className="col-md-7">

                        <h3>



                          {row.title.length > 75 &&
                            <Link to={`news/${row.categories[0]['id']}/${row.id}/details`}>{row.title.substring(0, 72)}...</Link>
                          }
                          {row.title.length <= 75 &&
                            <Link to={`news/${row.categories[0]['id']}/${row.id}/details`}>{row.title}</Link>
                          }


                        </h3>

                        {row.published_time&&<p><span><Timestamp relative date={row.published_time} format="full" /></span></p>}



                        {row.short_description.length > 150 && <p>
                          {row.short_description.substring(0, 147)}... <Link to={`news/${row.categories[0]['id']}/${row.id}/details`} > Read More</Link></p>
                        }
                        {row.short_description.length <= 150 && <p>
                          {row.short_description} <Link to={`news/${row.categories[0]['id']}/${row.id}/details`} > Read More</Link></p>
                        }

                      </div>

                      <div className="col-md-5">


                        {row.image && <Fragment>
                          <img src={`${row.image.feature_image}`} />
                        </Fragment>}

                      </div>

                    </div>

                  </div>
                </Fragment>
              );
            })}
          </Fragment> : ''}

          {Object.keys(content).length?<Fragment>
              {content.to < content.total&&<Fragment>
              
                <button className="btn btn-primary" onClick={this.contentLoad}>Load More News</button>
                </Fragment>}
          </Fragment>:''}
         

        </div>

      </section>

    );
  }
}


const mapStateToProps = (state) => ({
  setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
  getCategoryNews: (slug, requestData) => dispatch(getCategoryNews(slug, requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SecondSection);