import React, { Fragment,useRef,useState,useEffect } from 'react';
import BannerLeft from '../BannerLeft';
import Content from '../Content';
import { image_url } from '../../../config/Api';
import NoImage from '../../../img/no_image.jpeg';
import { Link } from "react-router-dom";
import InnerHTML from 'dangerously-set-inner-html';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const NewsCommon=(props)=> {

    const [from_startDate, setfromStartDate] = useState(new Date());
    const [to_startDate, settoStartDate] = useState(new Date());
    const [categoryidddddd, setCategoryid] = useState(null);
  
   



    const news_data=props.news_data;
    const category=props.category;

    const {title}=news_data;
    const {contents :{short_description=""}={}}=news_data;
    const {bread_curmbs}=news_data;
    const{banner_image:banner_img=""}=news_data;
    const{description}=news_data;

    const from_date = useRef(null);
    const to_date = useRef(null);
    const categoryid = useRef(null);
    const searchkey = useRef(null);
   
    var catid = props.userfilterdata.categoryid?props.userfilterdata.categoryid:'';

    function onChangeValue(event) {
       var categoryid = event.target.querySelector('input').value;
       
    }

    // useEffect(() => {
       

    //     function onChangeValue(event) {
    //         var categoryid = event.target.querySelector('input').value;
    //         setCategoryid(categoryid.categoryidddddd);
    //      }
    // });
   
    function onChangeValue(event) {
        catid = event.target.querySelector('input').value;
    }
   

    function handleClick() {
        const formdata = {
            from_date:from_startDate,
            to_date:to_startDate,
            categoryid:catid,
            searchkey:searchkey.current.value,
            
        }
        props.filter(formdata);
      }
    function handleContentLoad() {
        props.handleContentLoad();
      }


    return (
        <Fragment>
            <BannerLeft banner={banner_img}/>
          
            <section className="faq">

      <div className="container  inner-content">

      
      {props.is_filter === false&&<h1>{title}</h1>}
      {props.is_filter === true&&<h1>Filter</h1>}

        <div className="row">

          <div className="col-md-9">

            <div className="commettess-details">
                {props.filter_loading &&<div className="loader">

                <div className="sp sp-wave"></div>

                </div>}

                {props.is_filter === false&&<Content content={description} />}
                {props.is_filter &&<div className="news-filter-wrap">
                       {props.filterdata.length?<div className="row">
                        {props.filterdata.map((filterrow,fkey)=>{
                            return(
                                <Fragment key={fkey}>
                                <div className="col-md-4">
            
                                  <div className="our-latest-news-single">
            
                                    <img src={filterrow.image? `${image_url}${filterrow.image}` : NoImage} />
            
                                    <div className="news-date">
            
                                      <h3>{filterrow.publish_date_format}</h3>
            
                                    </div>
            
                                    <div className="our-latest-news-content">
            
                                      <h6>{filterrow.category.name}</h6>
            
                                    <h3>
                                      <Link to={{
                                          pathname: `${process.env.PUBLIC_URL}/news${filterrow.slug}`
                                        }} onClick={handleContentLoad}>
                                          {filterrow.title.length >60&&<Fragment>{filterrow.title.substring(0,60)}...</Fragment>}
                                          {filterrow.title.length <60&&<Fragment>{filterrow.title.substring(0,60)}</Fragment>}
                                        
                                          </Link>
            
                                      </h3>
            
                                    
                                      <InnerHTML html={filterrow.short_description.substring(0,200)} />
            
                                    </div>
            
                                  </div>
            
                                </div>
                              </Fragment>
                            )
                        })}
                       </div>:<h2>No record found</h2>}
                </div>}

            </div>

          </div>

          <div className="col-md-3">
            
            <div className="news-filtering">
              
              <form>
                
                <h3>Date</h3>

                <DatePicker placeholderText={'To Date'} ref={to_date} selected={to_startDate} onChange={(date) => settoStartDate(date)} dateFormat={'yyyy-MM-dd'} className="filter-date to-date"/>
                <DatePicker placeholderText={'From Date'} ref={from_date} selected={from_startDate}  onChange={(date) => setfromStartDate(date)} dateFormat={'yyyy-MM-dd'} className="filter-date from-date"/>

    
                <h3>Filter by Category</h3>

                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    {category.length&&category.map((row,key)=>{
                        return(
                            <label className="btn btn-primary" key={key} onClick={onChangeValue}>
                                <input type="radio" ref={categoryid} autocomplete="off"  value={row.id} /> {row.name}
                            </label>
                        )
                    })}
                   
                </div>

                <h3>Search by Keyword</h3>

                <div className="keyword-search">

                  <input type="text" placeholder="search"  ref={searchkey}/>

                  <button type="button" onClick={handleClick} className="btn submit-button btn-primary" name="Search">Search</button>

                </div>

               

              </form>

            </div>

          </div>


        </div>

      </div>

    </section>
        </Fragment>
    )
}

export default NewsCommon
