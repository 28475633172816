import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
// import { image_url } from '../../config/Api';
// import NoImage from '../../img/no_image.jpeg';



class Sitemap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      content_loading: true,
      albumid: '',
      sec_content: {},
      page_content: {},
    }


  }

  componentDidMount = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    var id = this.props.match.params.id;

    this.setState({
      sec_content: this.props.sec_content,
      page_content: this.props.page_content
    })
  }


  static getDerivedStateFromProps(props, state) {

    return true;

  }


  render() {


    const { content, content_loading } = this.state;



    const sitemap = this.state.sec_content;

    const ListItem = ({ data, key }) => {
      console.log("data.submenu =",data.submenu);
      return (
       
        <Fragment>
          {data.link_type == 1 && <li key={key}>
            <Link to={{ pathname: data.page ? data.page.slug : '' }}>{data.title}</Link>
            {data.submenu && data.submenu.length ? <Fragment>
            <SubMenu 
              data = {data.submenu}
              key = {key}
            />
          </Fragment>:''}
          </li>}
          {data.link_type == 0 && <li key={key}>
            <Link to={{ pathname: data.url ? data.url : '' }} target={data.url_target}>{data.title}</Link>
         
            {data.submenu && data.submenu.length ? <Fragment>
            <SubMenu 
              data = {data.submenu}
              key = {key}
            />
          </Fragment>:''}
          </li>}
         
        </Fragment>


      )
    }
    const ListItemWithoutTag = ({ data, key }) => {
      return (
        <Fragment>
          {data.link_type == 1 &&
            <Link to={{ pathname: data.page ? data.page.slug : '' }}>{data.title}</Link>
          }
          {data.link_type == 0 &&
            <Link to={{ pathname: data.url ? data.url : '' }} target={data.url_target}>{data.title}</Link>
          }
        </Fragment>
      )
    }

    const SubMenu = ({ data, key }) => {


      return (
        <ul>
          {data.map((menu, index) => {
            return (
              <ListItem
                data={menu}
                key = {index}
              />
            );
          })}
        </ul>
      );
    };

    return (

      <Fragment>

        <section className="faq">

          <div className="container">

            <div className="inner-content">

              <ul className="icab-sitemap">

                {sitemap && sitemap.section && <Fragment>
                  {sitemap.section.contents.map((row, key) => {
                    return (
                      <Fragment key={key}>
                        <li>


                          <ListItemWithoutTag data={row} data_key={key} />
                          {row.submenu.length > 0 && <ul>
                            {row.submenu.map((srow, skey) => {
                              return (
                                <ListItem data={srow} data_key={skey} />
                              )
                            })}
                          </ul>}


                        </li>

                      </Fragment>
                    )
                  })}
                </Fragment>}



              </ul>

            </div>

          </div>

        </section>
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
})

const mapDispatchToProps = (dispatch) => ({
  // getGallerys: (requestData) => dispatch(getGallerys(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sitemap));