import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { withRouter } from "react-router-dom";
import { getSingleOfCommitte } from '../../actions/Utils';
import { image_url } from '../../config/Api';
import Htmlcontent from '../Htmlcontent';
import BannerCenter from '../page/BannerCenter';
import BreadCrumbs from '../page/BreadCrumbs';
import ActivityModal from './ActivityModal';


class SingleCommitte extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      content_loading: true,
      is_open_modal: false,
      modal_content: null,
      show_all_member: false,
      show_all_tor: false,
      current_tab: 'contactdetails-data'
    }

  }

  componentDidMount = () => {

    const slug = this.props.match.params.slug;
    const requestData = {
      slug: slug
    }
    this.props.getSingleOfCommitte(requestData).then((data) => {

      this.setState({
        content: data,
        content_loading: false,
      })
    });
  }



  static getDerivedStateFromProps(props, state) {
    return {
      committe: props.committe
    }
  }

  handleActivityModal = (content) => {
    this.setState({
      modal_content: content,
      is_open_modal: true
    })
  }

  handleCloseModal = (status) => {

    this.setState({
      modal_content: null,
      is_open_modal: false
    })

  }
  handleMemberShowHide = (status) => {

    this.setState({
      show_all_member: status
    })

  }
  handleTorShowHide = (status) => {

    this.setState({
      show_all_tor: status
    })

  }

  scrollToTestDiv = (id) => {
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });
    // window.scrollTo(0, divElement.offsetTop+600);
    if(isMobile)
      window.scrollTo(0, divElement.offsetTop-document.getElementById("main-nav").offsetHeight+200);
    else
        window.scrollTo(0, divElement.offsetTop-document.getElementById("main-nav").offsetHeight+490);
    this.setState({
      current_tab: id
    })
  }


  render() {


    const { content_loading, is_open_modal, show_all_member, show_all_tor, current_tab } = this.state;

    const content_committee = this.props.committe;
    const content = content_committee.content;

    var breadcurmbs = [];

    if (Object.keys(content).length) {
      breadcurmbs = [
        {
          url: content.committee.slug,
          title: content.committee.name,
        },
        {
          url: '/',
          title: 'Committee',
        },
        {
          url: '/',
          title: 'Home',
        },
      ]
    }



    return (

      <Fragment>

        <BannerCenter title={''} menu_name="" banner="" />
        <BreadCrumbs bread_curmbs={breadcurmbs} />
        <section className="who-we-are-page">
          <div className="container">

            {content_loading === true && <div className="loader">

              <div className="sp sp-wave"></div>

            </div>}

            {(content_loading == false && Object.keys(content).length) ?


              <div className="inner-content" id="contactdetails-data">


                {Object.keys(content.committee).length && <h1>{content.committee.name}</h1>}
                <div className="committee-tabmenu">
                  <ul className="nav nav-pills committe-nav" id="pills-tab" role="tablist">
                    <li class={current_tab === 'contactdetails-data' ? 'nav-item active' : 'nav-item'}>
                      <a className="nav-link" onClick={() => this.scrollToTestDiv('contactdetails-data')}>Contact Details</a>
                    </li>
                    <li class={current_tab === 'member-data' ? 'nav-item active' : 'nav-item'} >
                      <a className="nav-link" onClick={() => this.scrollToTestDiv('member-data')}>Member</a>
                    </li>
                    <li class={current_tab === 'tor-data' ? 'nav-item active' : 'nav-item'}>
                      <a className="nav-link" onClick={() => this.scrollToTestDiv('tor-data')}>TOR</a>
                    </li>
                  </ul>
                </div>



                <div className="tabcontent">

                  {content.chairman &&<div className="commitee-single-row">

                    <div className="row profile-committee" >
                      <div className="col-md-3">
                        {content.chairman.image && <img src={`${image_url}${content.chairman.image}`} />}
                      </div>
                      <div className="col-md-6">
                        <h3 className="committee-member-name">
                          {content.chairman.name} 
                        </h3>
                        <h4 className="committee-member-designation">
                          {content.chairman.designation}
                        </h4>

                        <h3 className="border-title">Contact Details</h3>

                        <div className="committee-contact-details">
                          <Htmlcontent data={content.chairman.contact_details} />
                        </div>

                      </div>
                      <div className="col-md-3">
                        <div className="committe-whats-news">
                          <h3 className="border-title">What's New </h3>
                          <ul className="committee-activity">

                            {content.committee_activity.length ? <Fragment>
                              {content.committee_activity.map((row, key) => {
                                return (
                                  <Fragment>
                                    {row.content_type == 1 && <li key={key}>
                                      <i className="fas fa-angle-double-right"></i>
                                      <a className="activity-pdf-link" href={`${image_url}${row.pdf}`} target="_blank">{row.title} </a>
                                    </li>}
                                    {row.content_type == 0 && <li key={key} onClick={() => this.handleActivityModal(row)}>
                                      <i className="fas fa-angle-double-right"></i>
                                      {row.title}
                                    </li>}

                                  </Fragment>
                                )

                              })}

                            </Fragment> : ''}
                          </ul>
                        </div>

                      </div>

                    </div>
                  </div>}


                  {content.co_chairman ? <div className="table-style commitiee-table table-responsive">
                    <table className="table cochairmantable" id="cochairman-data">
                      <thead>
                        <tr>
                          <th className="text-center">Co-Chairman</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>

                            {content.co_chairman.image && <div className="co-chairman-image"><img src={`${image_url}${content.co_chairman.image}`} /></div>}


                            {content.co_chairman.name}

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> : ''}

                  {content.member.length ? <div className="table-style commitiee-table table-responsive">
                    <table className="table" id="member-data">
                      <thead>
                        <tr>
                          <th colspan="2">Members</th>
                        </tr>
                      </thead>
                      <tbody>
                        {content.member.map((row, key) => {
                          return (
                            <Fragment>
                              {key < 4 && show_all_member == false && <Fragment>
                                <tr>
                                  <td>{key + 1}</td>
                                  <td>

                                  <div className="committee-member-td">
                                      <div className="name">{row.name}</div>
                                      {row.contact_details&&<div className="address">
                                        
                                      <Htmlcontent data={row.contact_details} />
                                        
                                        </div>}
                                    </div>
                                  </td>
                                </tr>
                              </Fragment>}
                              {show_all_member == true && <Fragment>
                                <tr>
                                  <td>{key + 1}</td>
                                  <td>
                                    <div className="committee-member-td">
                                      <div className="name">{row.name}</div>
                                      {row.contact_details&&<div className="address">
                                        
                                       
                                        <Htmlcontent data={row.contact_details} />
                                        
                                        </div>}
                                    </div>



                                  </td>
                                </tr>
                              </Fragment>}

                            </Fragment>
                          )
                        })}


                      </tbody>
                    </table>
                  </div> : ''}
                  {content.member.length > 3 && show_all_member == false && <div className="show-more" onClick={() => this.handleMemberShowHide(true)}>See More</div>}

                  {show_all_member == true && <div className="show-less" onClick={() => this.handleMemberShowHide(false)}>See Less</div>}

                  {content.secretary.length ? <p className="mt-3"><strong>Member Secretary : </strong>
                    {content.secretary.map((row, key) => {
                      
                      return (
                        <Fragment>
                          {row.name},&nbsp;{row.designation} &nbsp;
                        </Fragment>
                      )
                      return false;
                    })}
                  </p> : ''}
                </div>


                {(Object.keys(content.committee).length && content.committee.tor)? <div id="tor-data" class={show_all_tor == true ? 'tor-wrap tor-load-more' : 'tor-wrap tor-load-less'}>

                  <h3 className="border-title">TERMS OF REFERENCE (TOR)</h3>
                  <div className="committee-tor">
                    <Htmlcontent data={content.committee.tor} />
                  </div>

                  {content.committee.tor.length > 700 && <Fragment>
                    {show_all_tor == false && <div className="show-more" onClick={() => this.handleTorShowHide(true)}>See More</div>}

                    {show_all_tor == true && <div className="show-less" onClick={() => this.handleTorShowHide(false)}>See Less</div>}
                  </Fragment>}

                </div> : ''}


              </div> : ''}
          </div>
        </section>
        {is_open_modal && <ActivityModal handleCloseModal={this.handleCloseModal} content={this.state.modal_content} />}
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  committe: state.contents.committe_content.singlecommitte,
})

const mapDispatchToProps = (dispatch) => ({
  getSingleOfCommitte: (requestData) => dispatch(getSingleOfCommitte(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleCommitte));