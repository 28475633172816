import InnerHTML from 'dangerously-set-inner-html';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setLayout, setPath, setPathLayout } from '../../actions/Utils';
import { host_name } from '../../config/Api';
import NoImage from '../../img/no_image.jpeg';

class MostRecentStories extends Component {

  constructor(props) {
    super(props);
    this.state = {
        home_layout: {
            content: null
        },

    }

}

// componentDidMount = () => {



// }

static getDerivedStateFromProps(props, state) {

    return {
        home_layout: props.home_layout
    }
}


    render() {

      const content = this.state.home_layout.content;
      var list = [];
      if (content.page_data) {

          var page_section = content.page_data.section;

          var section_data = page_section.find((row) => {
              if (row.name.toLowerCase() == 'most recent stories') {
                  return true;
              }
              else {
                  return false;
              }
          });
          if (section_data) {
              var section_content = section_data.contents;            
          }
      }
  
       
        return (
          
          section_content && section_content.length >0 ?
              <section className="recent-stories">

              <div className="container">
        
                <div className="section-title-one">
        
                  <h1> <InnerHTML html={section_data.title} /></h1>
        
                </div>
        
                <div className="row">
                  {
                    section_content && section_content.map((row,key)=>{

                      return (
                        <Fragment key={key}>

                          <div className="col-md-4">
                          
                            <div className="our-latest-news-single">
                                    
                              <img src={row.image && row.image.feature_image?row.image.feature_image:NoImage} />
                
                              <div className="our-latest-news-content">
                                
                                <h6>{row.categories[0].title} <span>{row.publish_date_format}</span></h6>
                
                                <h3>
                                  
                                <Link to={{pathname:`${host_name}publication/news/${row.categories[0]['id']}/${row.id}/details`}} target="_blank">
                                      {
                                        row.title.length>65?row.title.substring(0,65)+"...":row.title
                                      }
                                </Link>
                
                                </h3>
                
                                <p>
                                  
                                  {
                                          row.short_description.length>125 ? row.short_description.substring(0,125)+"...":row.short_description
                                  }
                
                                </p>
                
                              </div>
                
                            </div>
                
                          </div>
                          
                        </Fragment>
                        )
            
                    })
                  }
                </div>
                <Link to={{pathname:`${host_name}publication/`}}  target="_blank" className="btn-primary">See all Stories</Link>
              </div>
            </section>
            :
            " "
          
          );
    }
}

const mapStateToProps = (state) => ({
  home_layout: state.contents.home_layout,
})

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(MostRecentStories);