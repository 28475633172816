import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from 'react-router-dom';
import { setLayout, setPath, setPathLayout } from '../../actions/Utils';
import { image_url } from '../../config/Api';

class CarouselSlider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            home_layout: {
                content: null
            },

        }

    }

    // componentDidMount = () => {



    // }

    static getDerivedStateFromProps(props, state) {

        return {
            home_layout: props.home_layout
        }
    }

    render() {
      
        const content = this.state.home_layout.content;
        var is_video= 0;
        var slider = [];
        var video_url='';
        if (content.page_data) {

            var page_section = content.page_data.section;

            var section_data = page_section.find((row) => {
                if (row.name.toLowerCase() == 'home slider') {
                    return true;
                }
                else {
                    return false;
                }
            });
            if (section_data) {
                var section_content = section_data.contents;
                if (section_content.length) {
                    slider = section_content[0].slider;
                    is_video=slider[0].is_video;
                    video_url=slider[0].video;
                }
                // console.log(" section_content = ", section_content);
            }
        }

        return (
            
            <section>
                {
                    is_video==1 ?
                    <div className="video-slider" >
                        <h1>{section_data.title}</h1>
                        <div>
                
                            <video loop={true} autoPlay={true}  controls={false} muted>
                                <source src={image_url+video_url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                
                        </div>
                    </div>
                    :
                    <div className="main-slider">
                        <div>
                            <Carousel
                                autoPlay={true}
                                showThumbs={false}
                                infiniteLoop
                                showArrows={false}
                                selectedItem={false}
                                showStatus={false}
                            >
                                {slider.length && slider.map((row, key) => {

                                    return (
                                        <Fragment key={key}>

                                        {
                                        

                                            <div>

                                                <div className="container">

                                                    <div className="row">

                                                        <div className="col-md-6">

                                                            <div className="main-slider-content">

                                                                <h1>
                                                                    {row.title}
                                                                </h1>

                                                                <p>{row.short_descripton} </p>

                                                            
                                                                {row.external_link && <Link to={{
                                                                    pathname: row.external_link,

                                                                }}>Explore More</Link>}

                                                                {row.internal_link && <Link to={{
                                                                    pathname: row.menu?row.menu.slug:'',

                                                                }}>Explore More</Link>}
                                                            </div>

                                                        </div>

                                                        <div className="col-md-6">

                                                            <div className="main-slider-banner">


                                                                <img src={`${image_url}${row.long_image}`} />

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        }
                                        </Fragment>

                                    )


                                })}


                            </Carousel>
                        </div>
                    </div>
                }
            </section>
        );
    }
}


const mapStateToProps = (state) => ({
    headers: state.header,
    setting: state.utils.setting,
    home_layout: state.contents.home_layout,
})

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(CarouselSlider);