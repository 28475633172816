import axios from 'axios';
import * as crypto from 'crypto-js';
import Cookies from 'js-cookie';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { parseString } from 'xml2js';
import { casInstance, caversion, encryption_salt, processMaker } from '../config/Api';
import {SetMetaData} from '../actions/Utils'


class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = 
    {
      content: {},
      content_loading: true,
      validator: {
        newPassword: '',
        confirmPassword: '',
      },
      message: '',
      message_type: '',
    }
    this.refs = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {

    const metadata = {
      meta_title:'ICAB | Reset passwrod',
      meta_description:'',
      meta_keywords:'ICAB,Reset passwrod',
    }
    this.props.SetMetaData(metadata);

    var forgot_user_cookies_header = window.btoa("ForgotUserID"+encryption_salt).toString();
    var cookies_forgot_random=Cookies.get(forgot_user_cookies_header);
    var forgot_user_cookies_header_user = window.btoa("ForgotUserIDUser"+encryption_salt).toString();
    var cookies_forgot_user=Cookies.get(forgot_user_cookies_header_user);
    
    var forgot_user_otp_varrified = window.btoa("ForgotUserIDOTPVarrified"+encryption_salt).toString();
    var varified_otp=Cookies.get(forgot_user_otp_varrified);
    if(varified_otp)
    {

      var varified_otp_dycrypt = crypto.AES.decrypt(varified_otp, encryption_salt);
      varified_otp_dycrypt=varified_otp_dycrypt.toString(crypto.enc.Utf8);
    }

    if (!cookies_forgot_random || !cookies_forgot_user || !varified_otp || varified_otp_dycrypt!=1) {
      const { history } = this.props;
      history.push(`${process.env.PUBLIC_URL}/forgot-password`);
    }
  }



  handleMessageClose = () => {
    this.setState({ message: '', message_type: '' })
  }

  handleSubmit = (event) => {
   
  
    event.preventDefault();
    const userFormData = {
      newPassword: this.newPassword.value,
      confirmPassword: this.confirmPassword.value,
    }
    const resetValidator = {
      newPassword: '',
      confirmPassword: '',
    }
    var validation = {};
    if (userFormData.newPassword == '') {
      validation.newPassword = 'New password field is required.';
    }
    if (userFormData.confirmPassword == '') {
      validation.confirmPassword = 'Confirm password field is required.';
    }
    
    if (userFormData.newPassword && userFormData.confirmPassword && userFormData.newPassword != userFormData.confirmPassword) {
      validation.confirmPassword = 'Password not match';
    }


    if (Object.keys(validation).length) {
      this.setState({ validator: { ...resetValidator, ...validation }, message: '' });

    }
    else {
      
      var user_cookie_name = window.btoa("ForgotUserIDUser"+encryption_salt).toString();
      var cookie_user_id=Cookies.get(user_cookie_name);
      var user_id_dycrypt = crypto.AES.decrypt(cookie_user_id, encryption_salt);
      user_id_dycrypt=user_id_dycrypt.toString(crypto.enc.Utf8);
      
      const post_data={
        'newpass':userFormData.newPassword,
        'EnRl':user_id_dycrypt
      };

      var bodyFormData = new FormData();
      bodyFormData.append('newpass', userFormData.newPassword);
      bodyFormData.append('EnRl', user_id_dycrypt);
      /* const headers = {
        'AuthorizationHeader': 'Bearer '+localStorage.getItem('token')
      } */
      casInstance.post(caversion + 'doResetpassc',bodyFormData).then(({data})=>{
        if(data.status==200)
        {
          console.log(data);
          this.newPassword.value='';
          this.confirmPassword.value='';
          var process_maker_login_xml_body='<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:www="http://www.processmaker.com">\
          <x:Header/>\
          <x:Body>\
          <www:login>\
          <www:userid>test</www:userid>\
          <www:password>asd123</www:password>\
          </www:login>\
          </x:Body>\
        </x:Envelope>\
        ';
        processMaker.post('',
          process_maker_login_xml_body,
        {headers:
          {'Content-Type': 'text/xml; charset=utf-8','soapAction':'http://www.processmaker.com/login'}
        }).then(res=>{
          
          parseString(res.data,(err,result)=>{
            var status=result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:loginResponse']['0']['ns1:status_code']['0'];
            if(status==0)
            {
              var session_id=result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:loginResponse']['0']['ns1:message']['0'];
              var bodyFormDatad = new FormData();
              bodyFormDatad.append('sessionID', session_id);
              bodyFormDatad.append('passWord', userFormData.newPassword);
              bodyFormDatad.append('userName', user_id_dycrypt);

              axios.post('https://studentservices.icab.org.bd/change-password.php',bodyFormDatad).then((data)=>{
                alert('Password change successfully');
                var user_cookie_name = window.btoa("ForgotUserIDUser"+encryption_salt).toString();
                Cookies.remove(user_cookie_name);

                var randorm_number_name = window.btoa("ForgotUserID"+encryption_salt).toString();
                Cookies.remove(randorm_number_name);

                var varify_token_name = window.btoa("ForgotUserIDOTPVarrified"+encryption_salt).toString();
                Cookies.remove(varify_token_name);
                const { history } = this.props;
                history.push(`${process.env.PUBLIC_URL}/login`);
              });
            }
          })
        }).catch(err=>{ console.log('process maker error');console.log(err)});
       
        this.setState({ message: data.message, message_type: 'success', validator: { ...resetValidator } });
        }
        else{
          this.setState({ message: 'Something wrong! please try again... ', message_type: 'danger', validator: { ...resetValidator } });
        }
      }).catch(error => {

        if (error.response) {
          var html = '';
          if (Object.keys(error.response.data).length) {

            for (const [key, value] of Object.entries(error.response.data)) {
              //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
              html += `${value}`;
            }

          }

        }
        if (html) {
          // this.setState({ message: error.response.data.message, message_type: 'danger', validator: { ...resetValidator } })
          this.setState({ message: 'Something wrong! please try again... ', message_type: 'danger', validator: { ...resetValidator } })
        }

      });
    }
  }

  render() {
    const { validator } = this.state;
    return (
      <Fragment>
        <section className="sign-in">
          <div className="container">
            <div className="form-title">
              <h3>Forgot Password</h3>
            </div>
            <div className="form-title">
              {this.state.message != '' && this.state.message_type == 'success' && <div className="col-sm-12" >
              <div className="alert  alert-success alert-dismissible fade show" role="alert">
                  {this.state.message}
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>}
              {this.state.message != '' && this.state.message_type == 'danger' && <div className="col-sm-12">
                <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                  {this.state.message}
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>}
            </div>
            <div className="sign-in-form">
              <form className="">
                
                <div className="form-group">
                  <label>New Password <span>*</span></label>
                  <input type="password" ref={(input) => this.newPassword = input} className={validator.newPassword ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="Password" />

                  {validator.newPassword && <span className="help-block form-text text-danger">{validator.newPassword}</span>}
                </div>
                <div className="form-group">
                  <label>Confirm Password <span>*</span></label>
                  <input type="password" ref={(input) => this.confirmPassword = input} className={validator.confirmPassword ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="Password" />

                  {validator.confirmPassword && <span className="help-block form-text text-danger">{validator.confirmPassword}</span>}
                </div>
                <input
                  type="submit"
                  value="Change"
                  className="btn"
                  onClick={this.handleSubmit}
                />
              </form>
            </div>
          </div>
        </section>
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  committe: state.contents.committe_content.singlecommitte,
})

const mapDispatchToProps = (dispatch) => ({
  SetMetaData : (requestData) =>dispatch(SetMetaData(requestData))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));