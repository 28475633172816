import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from 'react-router-dom';
import { image_url } from '../../../config/Api'
import { getCategoryMostPopular } from '../../actions/Publication';

class FourthSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: []
    }
  }


  componentDidMount = () => {
    const requestData = {
      take: 4,
      skip: '',
    };
    const req = this.props.getCategoryMostPopular(requestData).then((data) => {
      this.setState({
        content: data
      })
    });

  }

  // static getDerivedStateFromProps(props, state) {

  //    return false;
  // }

  render() {

    const { content } = this.state;
    return (

      <section className="message-publication">

        <div className="container">

          <div className="publications">

            <div className="section-title-two">

              <h1>Most Popular <i className="fa fa-long-arrow-right"></i></h1>

            </div>

            <div className="row">


              {content.length ? <Fragment>

                {content.map((row, key) => {
                  return <Fragment key={key}>

                    <div className="col-md-3">

                      <div className="publications-single">

                        <div className="publications-banner">
                          {row.feature_image && <Fragment>
                            <img src={`${image_url}${row.feature_image}`} />
                          </Fragment>}

                          <p className="p-category"> {row.category_name}</p>

                        </div>



                        <h4>
                          {row.title.length > 66 &&
                            <Link to={`/news/${row.news_category_id}/${row.id}/details`}>{row.title.substring(0, 63)}...</Link>
                          }
                          {row.title.length <= 66 &&
                            <Link to={`/news/${row.news_category_id}/${row.id}/details`}>{row.title}</Link>
                          }
                        </h4>

                      </div>

                     
                      <Link to={`/${row.category_slug}`} className="btn btn-primary">See More </Link>

                    </div>



                  </Fragment>
                })}
              </Fragment> : ''}














            </div>

          </div>

        </div>

      </section>
    );
  }
}


const mapStateToProps = (state) => ({
  setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
  getCategoryMostPopular: (requestData) => dispatch(getCategoryMostPopular(requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FourthSection);