import React, { Fragment, useRef, useState, useEffect } from 'react';
import Modal from 'react-modal';
import Htmlcontent from '../Htmlcontent';
import { Link } from 'react-router-dom';
import { image_url } from '../../config/Api';
import Logo from '../../img/logo.svg';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ModalOne = (props) => {


  const [modalIsOpen, setIsOpen] = React.useState(false);
  console.log(" modal one props =",props);

  function openModal() {
    setIsOpen(true);
  }



  function closeModal() {
    props.handleCloseModal(false);
  }

  return (
    <div className="homepopup-modal">

      <Modal
        isOpen={true}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="homepopup Modal"
        className="homepopup-modal-body modal1"
      >
        <i className="far fa-times-circle" onClick={closeModal}></i>
        
        <div className="logo"><img src={Logo} /></div>
        {props.data.description && <div className="homepopup-description-image">
          <Htmlcontent data={props.data.description} />
        </div>}

       
      </Modal>
    </div>
  )
}

export default ModalOne;