import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getSetting } from "../../actions/Utils";

import FirstSection from '../components/home/FirstSection';
import SecondSection from '../components/home/SecondSection';
import ThirdSection from '../components/home/ThirdSection';
import FourthSection from '../components/home/FourthSection';
import FifthSection from '../components/home/FifthSection';



// console.log(" locationpath =",locationpath());

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
        };
    }
    // componentDidMount = () => {
       
     
    // }

    setPathLayout = (parms) => {
       this.props.setPathLayout(parms);
    }
    getSetting = async () => {
       let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
         return false;
    }
   

    render() {
        
      
        return (
            <Fragment>
             <FirstSection/>
             <SecondSection />
             <ThirdSection />
             {/* <FourthSection /> */}
             <FifthSection />
          
           </Fragment> 
        );
    }
}

const mapStateToProps = state => ({
    utils:state.utils,
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Home));