import React, { Fragment } from 'react';
import BannerCenter from '../BannerCenter';
import BreadCrumbs from '../BreadCrumbs';
import Content from '../Content';
import Sidebar from '../SideBar';

const CommonWithSidebar=({page_data})=> {
    const {title}=page_data;
    /* const {contents :{short_description=""}={}}=page_data; */
    const {bread_curmbs}=page_data;
    const{image:banner_img=""}=page_data;
    const{side_bar={}}=page_data;
    const{contents={}}=page_data;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    return (
        <Fragment>
              <BannerCenter title={title}  menu_name={bread_curmbs[0].title} banner={banner_img}/>
              <BreadCrumbs bread_curmbs={bread_curmbs} />
              <section className="faq">

                    <div className="container">

                        <div className="row">
                        
                            <div className="col-md-3">
                                {
                                    side_bar?<Sidebar side_bar={side_bar}/>:""
                                }
                                
                            </div>

                            <div className="col-md-9 inner-content">
                                <div className="commettess-details">
                                    {
                                        contents ?<h1>{title}</h1>
                                        :""
                                    }
                                    {contents ?<Content content={contents}/> :''}
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
        </Fragment>
    )
}

export default CommonWithSidebar
