import React, { Fragment, Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Htmlcontent from '../Htmlcontent';
import { Link } from 'react-router-dom';
import { image_url } from '../../config/Api';
import ModalOne from './ModalOne'
import Modal2 from './Modal2'
import Modal3 from './Modal3'
import Modal4 from './Modal4'
import Modal6 from './Modal6'
import Modal9 from './Modal9'
import { getHomePageModal,setModalPopupStatus } from "../../actions/Utils";


class HomeModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message: '',
      message_type: '',
      home_modal: '',
      modal_show: false,
      popupmodalads:{}
    }
  }

  componentDidMount = () => {
    const statethis = this;
    
      this.props.getHomePageModal().then((data) => {
        console.log("modal componentdidmount=",data);
        this.setState({
          home_modal: data,
          modal_show: data ? true : false,
        })
      });
 
  }

  static getDerivedStateFromProps(props, state) {

    return {
      popupmodalads: props.popupmodalads
    }
  }

  handleCloseModal = () => {
    console.log("modal handle modal close")
    this.setState({
      modal_show: false,
    })
    this.props.setModalPopupStatus({status:true});
  }

  render() {
    const { modal_show, home_modal } = this.state;
    const popupmodalads  = this.props.popupmodalads;
    console.log("hello home modal");
    return (
      <Fragment>
        {(modal_show && popupmodalads.modal_template_id==1) &&<ModalOne handleCloseModal={() => this.handleCloseModal()} data={home_modal} />}
        {(modal_show && popupmodalads.modal_template_id==2) &&<Modal2 handleCloseModal={() => this.handleCloseModal()} data={home_modal} />}
        {(modal_show && popupmodalads.modal_template_id==3) &&<Modal3 handleCloseModal={() => this.handleCloseModal()} data={home_modal} />}
        {(modal_show && (popupmodalads.modal_template_id==4 || popupmodalads.modal_template_id==5 || popupmodalads.modal_template_id==7 || popupmodalads.modal_template_id==8 || popupmodalads.modal_template_id==10)) &&<Modal4 handleCloseModal={() => this.handleCloseModal()} data={home_modal} />}
        {(modal_show && popupmodalads.modal_template_id==6 ) &&<Modal6 handleCloseModal={() => this.handleCloseModal()} data={home_modal} />}
        {(modal_show && popupmodalads.modal_template_id==9 ) &&<Modal9 handleCloseModal={() => this.handleCloseModal()} data={home_modal} />}
      </Fragment>

    )
  }

}

const mapStateToProps = (state) => ({
  popupmodalads: state.header.popupmodalads,
})

const mapDispatchToProps = (dispatch) => ({
  getHomePageModal: (requestData) => dispatch(getHomePageModal(requestData)),
  setModalPopupStatus: (requestData) => dispatch(setModalPopupStatus(requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeModal);