import qs from 'qs';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter,Redirect } from "react-router-dom";
import { getSetting, setPathLayout, userLogin } from "../actions/Utils";
import '../App.css';
import axiosInstance, { version } from '../config/Api';

class MemberDesboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validator: {
        userid: '',
        usertype: '',
        password: '',
      },
      message: '',
      message_type: '',
      member_token:''
    };
    this.refs = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    console.log(this.props);
  }
  handleMessageClose = () => {
    this.setState({ message: '', message_type: '' })
  }

  static getDerivedStateFromProps(props, state) {
    return {
      member_token: props.member_token
    }
  }


  handleSubmit = (event) => {
    event.preventDefault();
    const userFormData = {
      userid: this.userid.value,
      usertype: 10,
      password: this.password.value,
    }

    const resetValidator = {
      userid: '',
      password: '',
    }
    var validation = {};

    if (userFormData.userid == '') {
      validation.userid = 'The member field is required.';
    }

    if (userFormData.password == '') {
      validation.password = 'The password field is required.';
    }


    if (Object.keys(validation).length) {
      this.setState({ validator: { ...resetValidator, ...validation }, message: '' });

    }
    else {

      this.props.userLogin(userFormData).then((res) => {
        this.userid.value = '';
        this.password.value = '';
        localStorage.setItem('token', res.accesstoken);
        this.setState({ message: 'Login Successful.', message_type: 'success', validator: { ...resetValidator } })
      })
        .catch(error => {

          if (error.response) {
            var html = '';
            if (Object.keys(error.response.data).length) {

              for (const [key, value] of Object.entries(error.response.data)) {
                //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                html += `${value}`;
              }

            }

          }
          if (html) {
            this.setState({ message: html, message_type: 'danger', validator: { ...resetValidator } })
          }

        })
    }


  }

  setPathLayout = (parms) => {
    this.props.setPathLayout(parms);
  }
  getSetting = async () => {
    let data = await this.props.getSetting();
  }


  render() {
    const { validator } = this.state;
    if (!localStorage.getItem('token')) {
      return <Redirect to='login' />
    }
    // if (this.props.member_token != localStorage.getItem('token')) {
    //   return <Redirect to='login' />
    // }
    return (
      <Fragment>
        <section className="member-dashboard-in" style={{minHeight: "300px"}}>
          <div className="container">

            <div className="form-title">
              <h3>Dashboard for member</h3>
            </div>

            <div className="alert  alert-success alert-dismissible fade show" role="alert">
                 Welcome Member Dashboard
                </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  setting: state.utils.setting,
  member_token:state.utils.member_token
})

const mapDispatchToProps = dispatch => ({
  userLogin: (requestData) => dispatch(userLogin(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberDesboard));