import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { CAMemberData, getSetting, PageContent, setPathLayout } from "../actions/Utils";
import Common from '../components/FindCA/layout/Common';




class FindCA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
            header:{},
            container:{},
            footer:{},
            layout_id:'',
            page_content:{},
            member_data:{},
            ca_member_query:{
                stext:'',
                pageno:'1',
                itemno:'10'
            }
        };
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        console.log('hello ca menu');
        const {location} = this.props.history;
        const slug = this.props.match.path.replace('/page/','');

        this.props.PageContent(slug).then((res)=>{

        });

        this.props.CAMemberData(this.state.ca_member_query).then((res)=>{
            this.setState({
                member_data:res
              })
        });
    }
    prev=()=>
    {
        var page_no=this.state.ca_member_query;
        page_no.pageno=this.state.ca_member_query.pageno-1;
        this.setState(page_no);
        this.props.CAMemberData(this.state.ca_member_query).then((res)=>{
            this.setState({
                member_data:res
              })
        });
    }

    next=()=>
    {
        var page_no=this.state.ca_member_query;
        page_no.pageno=parseInt(this.state.ca_member_query.pageno)+1;
        this.setState(page_no);
        this.props.CAMemberData(this.state.ca_member_query).then((res)=>{
            this.setState({
                member_data:res
              })
        });
    }

    page_no_click=(page_no_click)=>
    {
        var page_no=this.state.ca_member_query;
        page_no.pageno=page_no_click;
        this.setState(page_no);
        this.props.CAMemberData(this.state.ca_member_query).then((res)=>{
            this.setState({
                member_data:res
              })
        });
    }

    Search = event => {
        event.preventDefault();
        var page_no=this.state.ca_member_query;
        page_no.pageno=1;
        this.setState(page_no);

        this.props.CAMemberData(this.state.ca_member_query).then((res)=>{
            this.setState({
                member_data:res
              })
        });
    };
    Search_text_change=event=>{
        
        var search_text=this.state.ca_member_query;
        search_text.stext=event.target.value;
        this.setState(search_text);

    }
    render() {
        const {page_data}=this.props.page_content.content;
        const member_data=this.state.member_data;
       
        if(page_data)
        {
            return (
                <Fragment>
                    {
                        
                        <Common page_data={page_data} member={member_data} per_page={this.state.ca_member_query.itemno} page_no={this.state.ca_member_query.pageno} prev={this.prev} next={this.next} page_no_click={this.page_no_click} Search={this.Search} Search_text_change={this.Search_text_change}/>
                    }
                    
                </Fragment>
            )
        }
        return (
            <Fragment>
                
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content,
    utils:state.utils,
    header:state.header,
    contents:state.contents,
    footer:state.footer,
    layout_id:state.utils.layout_id,
    routemenu:state.utils.routemenu,
    setting:state.utils.setting,
    
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    CAMemberData: (requestData) => dispatch(CAMemberData(requestData)),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(FindCA));