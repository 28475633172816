import React, { Fragment } from 'react';
import BannerLeft from '../BannerLeft';
import BreadCrumbs from '../BreadCrumbs';
import Member from '../Member';



const Common=({page_data,member})=> {
    const {title}=page_data;
    const {contents :{short_description=""}={}}=page_data;
    const {bread_curmbs}=page_data;
    const{image:banner_img=""}=page_data;
    const{contents}=page_data;
    return (
        <Fragment>
            <BannerLeft title={title} short_description={short_description} menu_name={bread_curmbs[0].title} banner={banner_img}/>
            <BreadCrumbs bread_curmbs={bread_curmbs} />
            <section className="council-members-page">

                <div className="container">
                    
                    <h1>{title} </h1>
                    <div className="row">
                        
                        <Member member={member}/>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Common
