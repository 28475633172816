import React, { Fragment, useRef, useState, useEffect } from 'react';
import Modal from 'react-modal';
import Htmlcontent from '../Htmlcontent';
import { Link } from 'react-router-dom';
import { image_url } from '../../config/Api';
import Logo from '../../img/logo.svg';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Modal3 = (props) => {


  const [modalIsOpen, setIsOpen] = React.useState(false);


  function openModal() {
    setIsOpen(true);
  }



  function closeModal() {
    props.handleCloseModal(false);
  }

  return (
    <div className="homepopup-modal">

      <Modal
        isOpen={true}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="homepopup Modal"
        className="homepopup-modal-body modal3"
      >
        <i className="far fa-times-circle" onClick={closeModal}></i>
      
        <div className="logo"><img src={Logo} /></div>

        {props.data.description && <div className="homepopup-description-image">
          <Htmlcontent data={props.data.description} />
        </div>}
        {props.data.link_button && <div className="btn btn-primary homepopup-button">
          {props.data.external_link && <Link to={{ pathname: props.data.link_button }} target="_blank" onClick={closeModal}>{props.data.link_button}</Link>}

          {(props.data.page_id && props.data.external_link === null) && <Link to={{ pathname: props.data.page ? `/page${props.data.page.slug}` : '#' }} onClick={closeModal}>{props.data.link_button}</Link>}
        </div>}

      </Modal>
    </div>
  )
}

export default Modal3;