import React, { Fragment } from 'react';
const CaFirm =({firm}) =>{
    return (

        <div class="table-style">
        <div class="table-responsive">
            <table class="table ca-firm-table">
                <thead>
                    <tr>
                        <td>Firm Name &amp; Address</td>
                        
                    </tr>
                </thead>
                <tbody>
                    <Fragment>
                    {
                        firm.length>0? 

                            firm.map((row,key)=>{
                            
                                return(
                                            <Fragment key={key}>
                                                
                                                
                                                <tr>
                                                    <td>
                                                        <strong>{row.firmname}</strong>
                                                        <p>Chartered Accountants</p>
                                                        <p>{row.firmsregisteredaddress}</p>
                                                        {
                                                            row.fax!=null ?
                                                            <p>
                                                                (F) {
                                                                       row.fax
                                                                    
                                                                    }
                                                            </p>
                                                            :
                                                            ''
                                                        }
                                                        {row.fixedphone ? <p>(O) {row.fixedphone}</p> :''}
                                                        {
                                                            row.mobileno!=null || row.contactpermobileno!=null?
                                                            <p>
                                                                (M) {
                                                                    (row.mobileno!=null ? row.mobileno:'') +(row.mobileno!=null && row.contactpermobileno!=null ?',':'')+(row.contactpermobileno!=null ?row.contactpermobileno:'')
                                                                    
                                                                    }
                                                            </p>:
                                                            ''
                                                        }
                                                        {
                                                            row.email!=null || row.contactperemail!=null?
                                                            <p>
                                                                (E) {
                                                                        (row.email!=null ? row.email:'') +(row.email!=null && row.contactperemail!=null ?',':'')+(row.contactperemail!=null ?row.contactperemail:'')
                                                                        
                                                                    }
                                                            </p>
                                                            :
                                                            ''
                                                        }
                                                        {
                                                            row.website!=null ?
                                                            <p>
                                                                (W) {
                                                                       row.website
                                                                    
                                                                    }
                                                            </p>
                                                            :
                                                            ''
                                                        }
                                                        
                                                    </td>
                                                </tr>
                                    
                                
                                        </Fragment>
                                )
                            })
                        :
                        ''
                    }
                    </Fragment>
                </tbody>
            </table>
        </div>
    </div>
    )
}

export default CaFirm
