import InnerHTML from 'dangerously-set-inner-html';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import { setLayout, setPath, setPathLayout } from '../../actions/Utils';
import { image_url } from '../../config/Api';


class OurServices extends Component {

  constructor(props) {
    super(props);
    this.state = {
        home_layout: {
            content: null
        },

    }

}

// componentDidMount = () => {



// }

static getDerivedStateFromProps(props, state) {

    return {
        home_layout: props.home_layout
    }
}


    render() {

      const content = this.state.home_layout.content;
      var list = [];
      if (content.page_data) {

          var page_section = content.page_data.section;

          const section_data = page_section.find((row) => {
              if (row.name.toLowerCase() == 'home sevice') {
                  return true;
              }
              else {
                  return false;
              }
          });
          if (section_data) {
              var section_content = section_data.contents;
              
              var title_description_html = section_content[0]['description'];
              if (section_content.length) {
                list = section_content[0].list;
              }
            
          }
      }
       
        return (
            <section className="our-service">

            <div className="container">
            <div className="section-title-one">

            {title_description_html!=null && <InnerHTML  html={title_description_html}/>}

            </div>
              <div className="row">
                
                {list && list.map((row,key)=>{
                  return(
                    <Fragment key={key}>
                      <div className="col-md-4">
                      
                          <div className="our-service-single">
                            
                          
                            <h3>

                            {row.external_link&& <Link to={{pathname:row.external_link}} target="_blank">{row.title}</Link>}
                            {row.internal_link&& <Link to={{pathname:row.menu?`${process.env.PUBLIC_URL}${row.menu.slug}`:''}}>{row.title}</Link>}

                            {(row.internal_link == null && row.external_link == null)&& <Link to={{pathname:`${process.env.PUBLIC_URL}/`}}>{row.title}</Link>}
                            </h3>
                            <img src={`${image_url}${row.image}`} />
              
                            <p>
                              {row.description}
                            </p>
              
                          </div>
          
                    </div>
                    </Fragment>
                  )
                })}
             
      
               
      
             
      
              </div>
      
              
      
              
              
            </div>
      
          </section>
        );
    }
}

const mapStateToProps = (state) => ({
  home_layout: state.contents.home_layout,
})

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(OurServices);