import axios from 'axios';
import Cookies from 'js-cookie';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from "react-router-dom";
import { parseString } from 'xml2js';
import { memberDetails, userLogin } from "../actions/Utils";
import '../App.css';
import HtmlHelmet from '../components/common/HtmlHelmet';
import { processMaker,image_url,host_name } from '../config/Api';



class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validator: {
        userid: '',
        usertype: '',
        password: '',
      },
      message: '',
      message_type: '',
      is_login: false,
      memberinfo: []
    };
    this.refs = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleMessageClose = () => {
    this.setState({ message: '', message_type: '' })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const userFormData = {
      userid: this.userid.value,
      usertype: 10,
      password: this.password.value,
    }

    const resetValidator = {
      userid: '',
      password: '',
    }
    var validation = {};

    if (userFormData.userid == '') {
      validation.userid = 'The member ID field is required.';
    }

    if (userFormData.password == '') {
      validation.password = 'The password field is required.';
    }


    if (Object.keys(validation).length) {
      this.setState({ validator: { ...resetValidator, ...validation }, message: '' });

    }
    else {

      this.props.userLogin(userFormData).then((res) => {
        this.userid.value = '';
        this.password.value = '';
        localStorage.setItem('token', res.accesstoken);
        localStorage.setItem('member_id', userFormData.userid);
        localStorage.setItem('member_pass', userFormData.password);
        Cookies.set('login_token', res.accesstoken);
        /*------------- member input system for Divergent:start ----------- */
        const requestData = {
          EnNo: localStorage.getItem('member_id'),
          
        }
        this.props.memberDetails(requestData).then((data) => {
        
          const  memberinfo  = data;
          const memberdata = Object.keys(memberinfo).length ? memberinfo.payload[0] : false;
          if(memberdata){
            var process_maker_login_xml_body='<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:www="http://www.processmaker.com">\
            <x:Header/>\
            <x:Body>\
            <www:login>\
            <www:userid>test</www:userid>\
            <www:password>asd123</www:password>\
            </www:login>\
            </x:Body>\
          </x:Envelope>\
          ';
          processMaker.post('',
            process_maker_login_xml_body,
          {headers:
            {'Content-Type': 'text/xml; charset=utf-8','soapAction':'http://www.processmaker.com/login'}
          }).then(res=>{
            
            parseString(res.data,(err,result)=>{
              var status=result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:loginResponse']['0']['ns1:status_code']['0'];
              if(status==0)
              {
                  var session_id=result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:loginResponse']['0']['ns1:message']['0'];
                  var xml_for_case_id='<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:www="http://www.processmaker.com">\
                  <x:Header/>\
                  <x:Body>\
                  <www:createUserRequest>\
                  <www:sessionId>'+session_id+'</www:sessionId>\
                  <www:userId>'+localStorage.getItem('member_id')+'</www:userId>\
                  <www:firstname>'+memberdata.part_Name+'</www:firstname>\
                  <www:lastname>'+memberdata.surName+'</www:lastname>\
                  <www:email>'+memberdata.emailAddress1+'</www:email>\
                  <www:password>'+localStorage.getItem('member_pass')+'</www:password>\
                  <www:dueDate>2031-10-21</www:dueDate>\
                  <www:role>PROCESSMAKER_OPERATOR</www:role>\
                  </www:createUserRequest>\
                  </x:Body>\
                  </x:Envelope>';
                processMaker.post('',
                xml_for_case_id,
                    {headers:
                      {'Content-Type': 'text/xml; charset=utf-8','soapAction':'urn:createUser'}
                    }).then(res=>{
                      
                      parseString(res.data,(err,result)=>{
                        var status=result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:createUserResponse']['0']['ns1:status_code']['0'];
                        if(status==0)
                        {
                          const params={username:localStorage.getItem('member_id'),type:'member'}
                          axios.get('https://memberservices.icab.org.bd/assign-user-to-group.php',{params:params}).then((data)=>{
                            console.log(data);
                          });
                        }
                      })
                    }).catch(err=>{ console.log('process maker error 1');console.log(err)}); 
                
              }
              })
            }).catch(err=>{ console.log('process maker error 0');console.log(err)});
          }
        });
        /*------------- member input system for Divergent:end ----------- */
        this.setState({ message: 'Login Successful.', message_type: 'success', validator: { ...resetValidator }, is_login: true })
      })
        .catch(error => {

          if (error.response) {
            var html = '';
            if (Object.keys(error.response.data).length) {

              for (const [key, value] of Object.entries(error.response.data)) {
                //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                html += `${value}`;
              }

            }

          }
          if (html) {
           
            // this.setState({ message: error.response.data.message, message_type: 'danger', validator: { ...resetValidator } })
            this.setState({ message: 'Member ID or Password is not valid', message_type: 'danger', validator: { ...resetValidator } })
          }

        })
    }


  }
  Forgotpassword=(e)=>{
    const { history } = this.props;
    history.push(`${process.env.PUBLIC_URL}/forgot-password`);
  }
  setPathLayout = (parms) => {
    this.props.setPathLayout(parms);
  }
  getSetting = async () => {
    let data = await this.props.getSetting();
  }


  render() {
    const { validator } = this.state;

    if (this.state.is_login) {
      return <Redirect to={`${process.env.PUBLIC_URL}/member/dashboard`} />
    }

      const meta_data = {
        meta_title:'Member Login',
        meta_description:'',
        meta_keywords:'ICAB, ICAB Member Login',
      }
    return (
      <Fragment>
        <HtmlHelmet custom_meta = {meta_data} />
        <section className="sign-in">
          <div className="container">

            <div className="form-title">
              <h3>Member Login</h3>
            </div>
            <div className="form-title">
              {this.state.message != '' && this.state.message_type == 'success' && <div className="col-sm-12" >
                <div className="alert  alert-success alert-dismissible fade show" role="alert">
                  {this.state.message}
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>}
              {this.state.message != '' && this.state.message_type == 'danger' && <div className="col-sm-12">
                <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                  {this.state.message}
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>}
            </div>
            <div className="sign-in-form">
              <form onSubmit={this.handleSubmit} id="user-form">
                <div className="form-group">
                  <label>Member ID (4 Digit) <span>*</span></label>
                  <input id="user-id" type="text" ref={(input) => this.userid = input} className={validator.userid ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="e.g. (0078, 1819)" />

                  {validator.userid && <span className="help-block form-text text-danger">{validator.userid}</span>}
                </div>

                <div className="form-group">
                  <label>Password <span>*</span></label>
                  <input id="user-pass" type="password" ref={(input) => this.password = input} className={validator.password ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="Password" />

                  {validator.password && <span className="help-block form-text text-danger">{validator.password}</span>}
                </div>




                <input type="submit" value="Login" className="btn" id="user-login-submit"/>               
                  <Link className="forgot-password-msg" to={`${process.env.PUBLIC_URL}/forgot-password`}><span>Forgot Password / Reset Password</span></Link>
                  <br/>
                  {/* <a target={'_blank'} href={image_url +'uploads/file/User_guide_for_setting_Website_Login_Password_14.11.2021.pdf'}><span>User Guideline for Password Changing / Resetting  </span></a> */}

                  <Link to={{pathname:host_name+'file/User_guide_for_setting_Website_Login_Password_14.11.2021.pdf'}} target={'_blank'} download="Password Reset User Guideline-ICAB"><span>User Guideline for Password Changing / Resetting  </span></Link>
                
              </form>


            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  setting: state.utils.setting,

})

const mapDispatchToProps = dispatch => ({
  userLogin: (requestData) => dispatch(userLogin(requestData)),
  memberDetails: (requestData) => dispatch(memberDetails(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));