
import * as crypto from 'crypto-js';
import $ from 'jquery';
import Cookies from 'js-cookie';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { memberDetails,SetMetaData } from "../actions/Utils";
import '../App.css';
import axiosInstance, { caversion, encryption_salt } from '../config/Api';
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validator: {
        userid: '',
        usertype: '',
        random_number:'',
        user_email:'',
        user_mobile:'',
      },
      varify_otp_input:[
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        ' '
      ],
      message: '',
      message_type: '',
      otp_sent: false,
      btn_show:false
    };
    this.refs = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleMessageClose = () => {
    this.setState({ message: '', message_type: '' })
  }
  componentDidMount=()=>
  {

    const metadata = {
      meta_title:'ICAB | Forgot passwrod',
      meta_description:'',
      meta_keywords:'ICAB,Forgot passwrod',
    }
    this.props.SetMetaData(metadata);



    $(function() {
      var body = $('body');
      function goToNextInput(e) {
        var key = e.which,
          t = $(e.target),
          sib = t.next('.forgot-password-form input');
        if (key != 9 && (key < 48 || key > 57) && (key < 96 || key > 105)) {
          e.preventDefault();
          return false;
        }
        if (key === 9) {
          return true;
        }
        if (!sib || !sib.length) {
          sib = body.find('.forgot-password-form input').eq(0);
        }
        sib.select().focus();
      }
      function onKeyDown(e) {
        var key = e.which;
        if (key === 9 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
          return true;
        }
        e.preventDefault();
        return false;
      }
      function onFocus(e) {
        $(e.target).select();
      }
    
      body.on('keyup', '.forgot-password-form input', goToNextInput);
      body.on('keydown', '.forgot-password-form input', onKeyDown);
      body.on('click', '.forgot-password-form input', onFocus);
    
    });
    
    var forgot_user_cookies_header = window.btoa("ForgotUserID"+encryption_salt).toString();
    var cookies_forgot_random=Cookies.get(forgot_user_cookies_header);
    var forgot_user_cookies_header_user = window.btoa("ForgotUserIDUser"+encryption_salt).toString();
    var cookies_forgot_user=Cookies.get(forgot_user_cookies_header_user);
    if (cookies_forgot_random && cookies_forgot_user) {
        this.setState({ otp_sent: true });
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    var random_number=Math.floor(100000 + Math.random() * 900000);
    const userFormData = {
      userid: this.userid.value,
      usertype: 10,
      random_number:random_number,
      user_email:''
    }

    const resetValidator = {
      userid: ''
    }
    var validation = {};

    if (userFormData.userid == '') {
      validation.userid = 'The member ID field is required.';
    }

    if (Object.keys(validation).length) {
      this.setState({ validator: { ...resetValidator, ...validation }, message: '' });

    }
    else {
      const requestData = {
        EnNo: userFormData.userid,
      }
      this.props.memberDetails(requestData).then((data) => {
        userFormData.user_email=data.payload[0].emailAddress1?data.payload[0].emailAddress1:data.payload[0].emailAddress2?data.payload[0].emailAddress2:'';
        var user_mobile=data.payload[0].cellPhone?data.payload[0].cellPhone:data.payload[0].cellPhone2?data.payload[0].cellPhone2:'';
        userFormData.user_mobile=user_mobile;
        if(userFormData.user_email){
          axiosInstance.post(caversion+'forgot-password-mail-sent',userFormData).then((res) => {
            var forgot_user_cookies_header = window.btoa("ForgotUserID"+encryption_salt).toString();
            var random_number_encrypt = crypto.AES.encrypt("'"+random_number+"'", encryption_salt).toString();
            Cookies.set(forgot_user_cookies_header, random_number_encrypt);
            
            var forgot_user_cookies_header_user = window.btoa("ForgotUserIDUser"+encryption_salt).toString();
            var user_id_encrypt = crypto.AES.encrypt(""+userFormData.userid+"", encryption_salt).toString();
            Cookies.set(forgot_user_cookies_header_user, user_id_encrypt);
            this.setState({ message: 'Email sent sucessfully.', message_type: 'success', validator: { ...resetValidator }, otp_sent: true })
          })
          .catch(error => {
  
            if (error.response) {
              var html = '';
              if (Object.keys(error.response.data).length) {
                for (const [key, value] of Object.entries(error.response.data)) {
                  //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                  html += `${value}`;
                }
  
              }
  
            }
            if (html) {
              // this.setState({ message: error.response.data.message, message_type: 'danger', validator: { ...resetValidator } })
              this.setState({ message: 'Something wrong! Please try again.', message_type: 'danger', validator: { ...resetValidator } })
            }
          })
        }
        

      }).catch(error => {
        validation.userid = 'Member id not match';
        this.setState({ validator: { ...resetValidator, ...validation }, message: '' });
      });
    }
  }
  reset_password=(e)=>{
    e.preventDefault();
    var forgot_user_otp_varrified = window.btoa("ForgotUserIDOTPVarrified"+encryption_salt).toString();
    var flag_encrypt = crypto.AES.encrypt("1", encryption_salt).toString();
    Cookies.set(forgot_user_otp_varrified, flag_encrypt);
    const { history } = this.props;
    history.push(`${process.env.PUBLIC_URL}/reset-password`);
  }
  change_varify_otp_input=(e,sl)=>
  {
    var forgot_user_cookies_header = window.btoa("ForgotUserID"+encryption_salt).toString();
    var random_number_cookie=Cookies.get(forgot_user_cookies_header);
    var random_dycrypt = crypto.AES.decrypt(random_number_cookie, encryption_salt);
    random_dycrypt=random_dycrypt.toString(crypto.enc.Utf8);
    const { varify_otp_input} = this.state;
    const working_variffy = [...varify_otp_input];
    working_variffy[sl]=e.target.value;
    this.setState((prevState) => {
        return {
          varify_otp_input: working_variffy,
        };
    },()=>{
      const token=this.state.varify_otp_input;
      let text = "'";
      for (let x in token) {
        text += token[x];
      }
      text+="'";
      if(text===random_dycrypt)
      {
        this.setState({ btn_show: true});
      }
      else{
        this.setState({ btn_show: false});
      }
    });
  }
  goto_forgot_page=(e)=>{
    var user_cookie_name = window.btoa("ForgotUserIDUser"+encryption_salt).toString();
    Cookies.remove(user_cookie_name);

    var randorm_number_name = window.btoa("ForgotUserID"+encryption_salt).toString();
    Cookies.remove(randorm_number_name);
    this.setState({ otp_sent: false });
  }
  setPathLayout = (parms) => {
    this.props.setPathLayout(parms);
  }
  getSetting = async () => {
    let data = await this.props.getSetting();
  }
  render() {
    const { validator } = this.state;
    
    return (
      <Fragment>
        <section className="sign-in">
          {
            !this.state.otp_sent?
          
            <div className="container">
              <div className="form-title">
                <h3>Forgot Password</h3>
              </div>
              <div className="form-title">
                {this.state.message != '' && this.state.message_type == 'success' && <div className="col-sm-12" >
                  <div className="alert  alert-success alert-dismissible fade show" role="alert">
                    {this.state.message}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>}
                {this.state.message != '' && this.state.message_type == 'danger' && <div className="col-sm-12">
                  <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                    {this.state.message}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>}
              </div>
              <div className="sign-in-form">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label>Member ID (4 Digit) <span>*</span></label>
                    <input type="text" ref={(input) => this.userid = input} className={validator.userid ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="e.g. (0078, 1819)" />
                    {validator.userid && <span className="help-block form-text text-danger">{validator.userid}</span>}
                  </div>
                  <input type="submit" value="Submit" className="btn" />
                </form>
              </div>
            </div>
            :
            <div className="forgot-password-wrapper">
              <div className="forgot-password-dialog">
                <h3>Please enter the 6-digit verification code we sent via SMS/Email:</h3>
                <div className="forgot-password-form">
                  <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onChange={(e)=>this.change_varify_otp_input(e,0)}/>
                  <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onChange={(e)=>this.change_varify_otp_input(e,1)}/>
                  <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onChange={(e)=>this.change_varify_otp_input(e,2)}/>
                  <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onChange={(e)=>this.change_varify_otp_input(e,3)}/>
                  <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onChange={(e)=>this.change_varify_otp_input(e,4)}/>
                  <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onChange={(e)=>this.change_varify_otp_input(e,5)}/>
                  {
                    this.state.btn_show ?
                    <button className="btn btn-primary btn-embossed" onClick={(e)=>this.reset_password(e)}>Reset Password</button>
                    :''
                  }
                </div>
                <div>
                  Didn't receive the code?<br />
                  <a href="" onClick={(e)=>this.goto_forgot_page(e)}>Send code again</a><br />
                </div>
              </div>
            </div>
          }
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  setting: state.utils.setting,

})

const mapDispatchToProps = dispatch => ({
  memberDetails: (requestData) => dispatch(memberDetails(requestData)),
  SetMetaData: (requestData) => dispatch(SetMetaData(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));