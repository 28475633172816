import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getNews } from '../../actions/Publication';
import { image_url } from '../../../config/Api'

import Timestamp from 'react-timestamp';

class FirstSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content: []
        }

    }

    componentDidMount = () => {
        const requestData = {
            take: 2,
            skip: '',
        };
        const req = this.props.getNews(requestData).then((data) => {
            this.setState({
                content: data
            })
        });

    }

    // static getDerivedStateFromProps(props, state) {


    // }

    render() {

        const { content } = this.state;

        return (

            <section className="latest-news-last-two">

                <div className="container">

                    <div className="row">


                        {content.length ? <Fragment>

                            {content.map((row, key) => {
                                return <Fragment key={key}>
                                    {key === 0 && <Fragment>

                                        <div className="col-md-8">

                                            <div className="latest-news-last-six-single">

                                                <div className="row">


                                                    <div className="col-md-6">
                            
                                                        <h3>
                                                            {row.title.length > 84 &&
                                                                <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`}>{row.title.substring(0, 82)}...</Link>
                                                            }
                                                            {row.title.length <= 84 &&
                                                                <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`}>{row.title}</Link>
                                                            }
                                                        </h3>

                                                        {row.short_description.length > 123 && <p>
                                                            {row.short_description.substring(0, 120)}... <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`} > Read More</Link></p>
                                                        }
                                                        {row.short_description.length <= 123 && <p>
                                                            {row.short_description} <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`} > Read More</Link></p>
                                                        }


                                                        {row.published_time &&<p> <Timestamp relative date={row.published_time} autoUpdate /></p>}

                                                    </div>

                                                    <div className="col-md-6">


                                                        {row.image && <Fragment>
                                                            <img src={`${row.image.feature_image}`} />
                                                        </Fragment>}

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    </Fragment>}

                                    {key !== 0 && <Fragment>
                                        <div className="col-md-4">

                                            <div className="latest-news-last-six-single">

                                                <h3>
                                                    {row.title.length > 84 &&
                                                        <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`}>{row.title.substring(0, 82)}...</Link>
                                                    }
                                                    {row.title.length <= 84 &&
                                                        <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`}>{row.title}</Link>
                                                    }
                                                </h3>

                                                {row.short_description.length > 123 && <p>
                                                    {row.short_description.substring(0, 120)}... <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`} > Read More</Link></p>
                                                }
                                                {row.short_description.length <= 123 && <p>
                                                    {row.short_description} <Link to={`/news/${row.categories[0]['id']}/${row.id}/details`} > Read More</Link></p>
                                                }



                                               {row.published_time&&<p> <Timestamp relative date={row.published_time} autoUpdate /></p>}

                                            </div>

                                        </div>
                                    </Fragment>}

                                </Fragment>
                            })}
                        </Fragment> : ''}






                    </div>

                </div>

            </section>
        );
    }
}


const mapStateToProps = (state) => ({
    setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
    getNews: (requestData) => dispatch(getNews(requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FirstSection);