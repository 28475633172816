import React, { Fragment,useRef,useState,useEffect } from 'react';
import Modal from 'react-modal';
import Htmlcontent from '../Htmlcontent';


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

 const ActivityModal =(props) =>{

  
    const [modalIsOpen, setIsOpen] = React.useState(false);
  
    
    function openModal() {
      setIsOpen(true);
    }
  
  
  
    function closeModal() {
        props.handleCloseModal(false);
    }
    
    return (
        <div className="activity-modal">
       
        <Modal
          isOpen={true}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          className="committee-activity-modal"
        >
          <i className="far fa-times-circle" onClick={closeModal}></i>
          <div className="activity-title">{props.content.title}</div>
          <div className="activity-description">
              <Htmlcontent data={props.content.description} />
          </div>
        </Modal>
      </div>
    )
}

export default ActivityModal;