// import styles
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lightgallery.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import LightGallery from 'lightgallery/react';
import React from 'react';
import { image_url } from '../../config/Api';

export default function ImageGallery(props) {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <div className="image-preview">
            <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
            >


                {props.content.map((row, key) => {
                    return (
                        <a href={`${image_url}${row.preview_image}`} className="gallery-grid">
                                <div className="gallery-single">
                                    <img alt={row.caption} src={`${image_url}${row.preview_image}`} />
                                    <p>{row.caption}</p>
                                </div>
                        </a>
                    )
                })}

            </LightGallery>
        </div>
    );
}



