import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getSetting, sectionandContent, setPathLayout } from "../../actions/Utils";

import FirstSection from '../components/news_bulletin/FirstSection';
import SecondSection from '../components/news_bulletin/SecondSection';

class NewsBulletin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
            slug:this.props.match.path
        };
    }
    componentDidMount = () => {
        // console.log("Mobarak hossen slug =",this.props.match);
        const {slug} = this.props.match.path;
        this.setState({
            slug:slug
        })
    } 
   
    render() {
      
        return (
            <Fragment>
             <FirstSection slug={this.props.match.path}/>
             <SecondSection slug={this.props.match.path}/>
           
           </Fragment> 
        );
    }
}

const mapStateToProps = state => ({
    utils:state.utils,
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(NewsBulletin));