import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
const search = ({title,data}) => {
   // console.log("search datass");
    return (
        <div className="search-content">

          <h4>{title.length>0 ? title :''}</h4>
            {
                data.map((rows,keys)=>{
                    return(
                        <Fragment key={keys}>
                            <div className="search-single" >
                    
                                <h3><Link to={{pathname:`${process.env.PUBLIC_URL}${rows.slug}`}} replace > {rows.title} </Link></h3>

                                <p>
                                    {rows.short_description} 
                                </p>

                            </div>
                        </Fragment>
                    )

                })
            }
          
        </div>
    )
}

export default search
