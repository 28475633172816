const utilsReducer = {
    path: '/',
    layout_id: '',
    routemenu: [],
    pubroutemenu: [],
    setting: {},
    header_loading: true,
    footer_loading: true,
    breadcrumb: [],
    member_token:'',
    meta_data:{},
}

export default (state = utilsReducer, action) => {
    switch (action.type) {
        case 'SET_PATH':
            return {
                ...state,
                path: action.path
            }
        case 'SET_LAYOUT_ID':
            return {
                ...state,
                layout_id: action.id
            }
        case 'SET_PATH_LAYOUT':

            return {
                ...state,
                path: action.data.path,
                layout_id: action.data.id,
            }
        case 'INIT_ROUTES':
            return {
                ...state,
                routemenu: action.data
            }
        case 'SET_HEADER_LOADING':
            return {
                ...state,
                header_loading: action.status
            }
        case 'SET_FOOTER_LOADING':
            
            return {
                ...state,
                footer_loading: action.status
            }
        case 'SET_SETTING':
            console.log("Hello Mobarak=",action.data);
            return {
                ...state,
                setting: action.data
            }
        case 'INIT_PUBLICATION_ROUTES':
            return {
                ...state,
                pubroutemenu: action.data
            }
        case 'SET_MEMBER_TOKEN':
            return {
                ...state,
                member_token: action.data
            }
        case 'INIT_META_DATA':
            return {
                ...state,
                meta_data: action.data
            }
        default:
            return state;

    }
}