import qs from 'qs';
import axiosInstance, { version } from '../../config/Api';


export const initPubTopHeader = (data) => ({
  type: 'PUB_INIT_TOPMENU',
  data
});


export const getTopMenu = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'categories').then(res => {
      if ((res.data).length) {
        dispatch(initPubTopHeader(res.data));
      }
      return res.data;
    });
  }
}
export const initPubHomeSection = (data) => ({
  type: 'PUB_INIT_TOPMENU',
  data
});


export const getNews = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'latest/news', {
      params: parms
    }).then(({ data }) => {
      return data
    });
  }
}
export const getMostPopularNews = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'popular/news', {
      params: parms
    }).then(({ data }) => {
      return data
    });
  }
}
export const getCategoryMostPopular = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'category/popular', {
      params: parms
    }).then(({ data }) => {
      return data
    });
  }
}
export const getCategoryNews = (slug, parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'news/' + slug, {
      params: parms
    }).then(({ data }) => {
      return data
    });
  }
}


export const setPubDetails = (data) => ({
  type: 'PUB_ADD_DETAILS',
  data
});
export const getNewsDetails = (catid, slug) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'news/' + catid + '/' + slug + '/details').then(({ data }) => {
      dispatch(setPubDetails(data));
      return data;
    });
  }
}

export const setSearchKey = (search) => ({
  type: 'SET_SEARCH_KEY',
  search
});

export const Search = (params) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'search', {
      params: params
    }).then(({ data }) => {

      return data;

    });
  }
}
export const getRelatedNews = (params) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'get/tagnews', qs.stringify(params)).then(({ data }) => {

      return data;

    });
  }
}
export const getTagNews = (params) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/tagwisenews', {
      params: params
    }).then(({ data }) => {
      return data;
    });
  }
}



