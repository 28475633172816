import moment from 'moment';
import React, { Fragment } from 'react';
const Member =({member}) =>{
    return (
        <Fragment>
            {
                member.length>0? 
                    member.map((row,key)=>{
                        var name_split=row.username.replace(/\s\s+/g, ' ').split(" ");
                        name_split.splice(name_split.length-1,1);
                        
                        var last_name=name_split[name_split.length-1];
                        name_split.splice(name_split.length-1,1);
                        var other_name=name_split.join(" ");
                        return(
                            <Fragment key={key}>
                                <div className="member-list-single">
                    
                                    <div className="row">
                                        
                                        <div className="col-md-3">
                                        
                                        <img src={row.srvhost+(row.memberimg==null?'demo.jpg':row.memberimg)} alt="Member"/>

                                        </div>

                                        <div className="col-md-9">
                                        
                                        <h3>{last_name}, <span>{other_name}</span></h3>
                                        {
                                            row.educationinfo!=null?
                                            <p>{row.educationinfo}</p>
                                            :''
                                        }

                                        <p>{row.acafca} ({moment(row.acafca==='FCA'?row.fcadate:row.acadate).format('DD/MM/YYYY')}) ({row.userkeyid})</p>

                                        <hr/>
                                        {
                                            row.userposition?
                                            <p>{row.userposition}</p>
                                            :''
                                        }
                                        {
                                            row.userorgname?
                                            <h4>{row.userorgname}</h4>
                                            :''
                                        }
                                        {
                                            row.useraddress?
                                            <p>
                                                {row.useraddress}
                                            </p>
                                            :''
                                        }

                                        {/* <p>
                                            (O) +880-2-58152251
                                        </p> */}
                                        {
                                            row.cellphone1!=null || row.cellphone2!=null?
                                            <p>
                                                (M) {
                                                      (row.cellphone1!=null ? row.cellphone1:'') +(row.cellphone1!=null && row.cellphone2!=null ?',':'')+(row.cellphone2!=null ?row.cellphone2:'')
                                                      
                                                    }
                                            </p>:
                                            ''
                                        }
                                        {
                                            row.emailid1!=null || row.emailid2!=null?
                                            <p>
                                                (E) {
                                                        (row.emailid1!=null ? row.emailid1:'') +(row.emailid1!=null && row.emailid2!=null ?',':'')+(row.emailid2!=null ?row.emailid2:'')
                                                        
                                                    }
                                            </p>
                                            :
                                            ''
                                        }

                                        {/* <p>
                                            (W) www.asr-ca.com
                                        </p> */}

                                        </div>

                                    </div>

                                </div>
                                    
                           </Fragment>
                        )
                    })
                :
                ''
            }
        </Fragment>
    )
}

export default Member
