import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { faqLayoutContent, getSetting, homeLayoutContent, setPathLayout } from "../actions/Utils";
import '../App.css';
import Htmlcontent from '../components/Htmlcontent';
import BreadCrumbs from '../components/page/BreadCrumbs';
import {image_url} from '../config/Api';



class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '',
      header: {},
      container: {},
      footer: {},
      layout_id: '',
      faqlayout: null,
    };
  }
  componentDidMount = () => {

    // console.log('here component');
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const { location } = this.props.history;
    var { pathname } = location

    this.props.homeLayoutContent().then((res) => {

    })

    this.props.faqLayoutContent({ 'slug': pathname });

  }

  setPathLayout = (parms) => {
    this.props.setPathLayout(parms);
  }
  getSetting = async () => {
    let data = await this.props.getSetting();
  }
  static getDerivedStateFromProps(props) {
    return {
      path: props.utils.path,
      header: props.header,
      container: props.container,
      footer: props.footer,
      layout_id: props.utils.layout_id,
      faqlayout: props.faqlayout
    };
  }
  getContentData = (menu_id) => {

    const requestData = {
      menu_id: menu_id,
    }
    this.props.sectionandContent(requestData);
  }

  render() {

   
    const faqlayout = this.props.faqlayout.content;
    var top_html = '';
    var question = '';
    var browse_otherfaq = '';
    if (faqlayout) {
      const pagesection = faqlayout.pagesection;


      if (pagesection) {

        console.log('pagesection=',pagesection);
        const section_data = pagesection.filter((row, key) => {

          
          if (row.section.section_type.toLowerCase() == 'module faq') {
            question = row.section;
          }
          
          else if (row.section.section_type.toLowerCase() == 'list') {
            if(row.section.contents.listcontent.get_list_type.name == 'Browse More FAQ Page'){
              browse_otherfaq = row.section;

            }
            // console.log('section value=', row.section.contents.listcontent.get_list_type.name == 'Browse More FAQ Page')
            
          }
          else {
            return false;
          }

        });
      }
      console.log('other faq =',browse_otherfaq);

      
    }

    return (
      <Fragment>
        {/* <section className="innerpage-header">
          {top_html && <Fragment>
            <Htmlcontent data={top_html.contents.description} />

          </Fragment>}

        </section> */}

        {faqlayout.image && <section className="innerpage-header">

          <img src={`${image_url}${faqlayout.image}`} />

        </section>}


        {faqlayout.bread_cumbs&&<BreadCrumbs bread_curmbs={faqlayout.bread_cumbs} />}

        <section className="faq">

          <div className="container">


            {question && <h3>{question.title}</h3>}
            {question && <Fragment><p>{question.sec_description}</p></Fragment>}



            <div className="row">

              {question.contents && <Fragment>

                {question.contents.map((row, key) => {
                  return (
                    <Fragment key={key}>
                      <div className="col-md-6">
                        <div className="accordion">
                          <div className="card">
                            <div id="headingOne">
                              <h2 className="mb-0">
                                <a data-toggle="collapse" data-target={`#collapse_${key}`}>{row.question}
                                  <i className="fa fa-plus"></i> </a>
                              </h2>
                            </div>
                            <div id={`collapse_${key}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                              <div className="card-body">
                                <p>{row.answer}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </Fragment>
                  )

                })}
              </Fragment>}





            </div>

            <div className="browse-more-faq">

              {browse_otherfaq && <h3>{browse_otherfaq.title}</h3>}


              <div className="row">

                {browse_otherfaq && <Fragment>
                  {browse_otherfaq.contents.list && browse_otherfaq.contents.list.map((row, key) => {
                    return (
                      <Fragment>
                        <div className="col-md-3">

                          <div className="browse-single">

                            <h3>
                              
                              {row.external_link&& <Link to={{pathname:row.external_link}} target="_blank">{row.title}<i className="fa fa-long-arrow-right"></i></Link>}
                              {row.internal_link&& <Link to={{pathname:row.menu?`${process.env.PUBLIC_URL}${row.menu.slug}`:''}}>{row.title}<i className="fa fa-long-arrow-right"></i></Link>}

                              {(row.internal_link == null && row.external_link == null)&& <Link to={{pathname:`${process.env.PUBLIC_URL}/`}}>{row.title}<i className="fa fa-long-arrow-right"></i></Link>}

                            </h3>

                          </div>

                        </div>
                      </Fragment>
                    )

                  })}
                </Fragment>}


              </div>

            </div>

          </div>

        </section>


      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  faqlayout: state.contents.faq_layout,
  utils: state.utils,
  header: state.header,
  contents: state.contents,
  footer: state.footer,
  layout_id: state.utils.layout_id,
  routemenu: state.utils.routemenu,
  setting: state.utils.setting,

})

const mapDispatchToProps = dispatch => ({
  getSetting: () => dispatch(getSetting()),
  setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
  homeLayoutContent: (requestData) => dispatch(homeLayoutContent(requestData)),
  faqLayoutContent: (requestData) => dispatch(faqLayoutContent(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Faq));