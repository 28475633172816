import InnerHTML from 'dangerously-set-inner-html';
import Cookies from 'js-cookie';
import qs from 'qs';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import { FacebookShareButton } from 'react-share';
import { homeLayoutContent, setLayout, setPath, setPathLayout } from '../../actions/Utils';
import axiosInstance, { image_url, version } from '../../config/Api';
class ExploreSelf extends Component {

  constructor(props) {
    super(props);
    this.state = {
        home_layout: {
            content: null
          },
          message:'',
          message_type:'',
          vote_ans:null,
          votted:false
      }

  }
  handleMessageClose = () => {
    this.setState({message:'',message_type:''})
  }
  vote=(question_id)=>{

    const userFormData = {
      question_id:question_id,
      answer_id:this.state.vote_ans,
      
      
    }
    
    axiosInstance.post(version+'vote',qs.stringify(userFormData)).then((res) => {

      this.setState({message:res.data,message_type:'success'});
      Cookies.set('pole_question_id',question_id);
      Cookies.set('pole_answer_id',this.state.vote_ans);
      this.setState({vote_ans:this.state.vote_ans,votted:true});
      this.props.homeLayoutContent().then((res)=>{});
    }).catch(error=>{
      this.setState({message:"Something Wrong! Please Try Again.",message_type:'danger'});
    })
  }

  change_vote=(question_id)=>{

    const userFormData = {
      question_id:question_id,
      answer_id:this.state.vote_ans,
      
    }

    axiosInstance.post(version+'change_vote',qs.stringify(userFormData)).then((res) => {

      this.setState({message:res.data,message_type:'success'});
      Cookies.set('pole_question_id',question_id);
      Cookies.set('pole_answer_id',this.state.vote_ans);
      this.setState({vote_ans:this.state.vote_ans,votted:true});
      this.props.homeLayoutContent().then((res)=>{});
    }).catch(error=>{
      this.setState({message:"Something Wrong! Please Try Again.",message_type:'danger'});
    })
    
    axiosInstance.get(version+'get/getAllNewsEvent',{params:userFormData}).then((res) => {

      this.setState({message:'',message_type:'success'});
          Cookies.set('pole_question_id','');
          Cookies.set('pole_answer_id','');
          this.setState({vote_ans:null,votted:false});
          this.props.homeLayoutContent().then((res)=>{});
      }).catch(error=>{
          this.setState({message:"Something Wrong! Please Try Again.",message_type:'danger'});
      })

      

        
  }

  vote_answer_change=(event)=>
  {
    this.setState({vote_ans:event.target.value});
  }
  static getDerivedStateFromProps(props, state) {

      return {
          home_layout: props.home_layout
      }
  }

    render() {
      const content = this.state.home_layout.content;
      var list = [];
      var pole_section_data=[];
      var pole_section_content=[];
      if (content.page_data) {

          var page_section = content.page_data.section;

          var section_data = page_section.find((row) => {
              if (row.name.toLowerCase() == 'explore portal') {
                  return true;
              }
              else {
                  return false;
              }
          });
          if (section_data) {
              var section_content = section_data.contents;
              if (section_content.length) {
                list = section_content[0].list;
              }
              // console.log(" section_content = ", section_content);
          }


           pole_section_data = page_section.find((row) => {
            if (row.name.toLowerCase() == 'pole') {
                return true;
            }
            else {
                return false;
            }
        });
        if (pole_section_data) {
           pole_section_content = pole_section_data.contents;
           var cookies_question_id=Cookies.get('pole_question_id');
           var cookies_answer_id=Cookies.get('pole_answer_id');
        
          if( this.state.vote_ans==null && pole_section_content && pole_section_content.id==cookies_question_id)
          {
           
            this.setState({vote_ans:cookies_answer_id,votted:true});
          }
          else{
           
           if(this.state.vote_ans==null && pole_section_content && pole_section_content.pole_question_answwer)
             this.setState({vote_ans:pole_section_content.pole_question_answwer[0].id});
          }
            // console.log(" section_content = ", section_content);
        }
      }
     
      
      
        return (
            <section className="update-area">

            <div className="container">
      
              <div className="section-title-one text-center">
                
               {
                 section_data && section_data.title &&
                <h1> <InnerHTML html={section_data.title}/></h1>
               } 
      
                
                  
                 
               { section_data &&  section_data.sec_description && <InnerHTML html={section_data.sec_description}/>}
                 
      
                
      
              </div>
      
              <div className="row">
                <div className={"col-md-"+(pole_section_content && Object.keys(pole_section_content).length  > 0 ?9:12)  }>
                  <div className="update-area-content">
                    <div className="row">
                      {list && list.map((row,key)=>{
                        return(
                          <Fragment key={key}>
                                <div className="col-md-4">
                                  
                                    <div className="update-area-single">
                                      
                                    
                                      <img src={`${image_url}${row.image}`} />
                        
                                      <h3>
                                      
                                        
                                        {row.external_link&& <Link to={{pathname:row.external_link}} target="_blank">{row.title}</Link>}
                                        {row.internal_link&& <Link to={{pathname:row.menu?`${process.env.PUBLIC_URL}${row.menu.slug}`:''}}>{row.title}</Link>}

                                        {(row.internal_link == null && row.external_link == null)&& <Link to={{pathname:`${process.env.PUBLIC_URL}/`}}>{row.title}</Link>}
                                      </h3>
                        
                                    </div>
                      
                                </div>
                          </Fragment>
                        )
                      })}
                    </div>
                  
                  </div>
                </div>
                {
                  pole_section_content && Object.keys(pole_section_content).length >0 ?
                  <div className="col-md-3">

                    <div className="pool-section">
                    {pole_section_content.image ? <img src={image_url+pole_section_content.image} />:''}

                      <h4>

                        {pole_section_content.question}

                      </h4>

                      <ul className="navbar-nav">
                        {
                          
                          pole_section_content.pole_question_answwer.map((row,key)=>{
                            var vote=(parseFloat(parseFloat(row.total_vote)/parseFloat(pole_section_content.total_vote))*100);
                            return(
                                <li>
                              
                                <div className="form-check">
      
                                  <input className="form-check-input" type="radio" name="pole_vote" id={"exampleRadios"+key} value={row.id} checked={this.state.vote_ans == row.id} disabled={this.state.votted} onChange={this.vote_answer_change.bind(this)} />
                                  <label className="form-check-label" for={"exampleRadios"+key}>
                                    {
                                      row.answer
                                    }
                                  </label>
      
                                  <span>
                                    {
                                     
                                     vote ?vote.toFixed(2):0
                                    }
                                  %</span>
      
                                </div>
      
                              </li>
                            )
                          })
                        }

                        

                        

                      </ul>

                      <div className="share-vote">
                        {/* <FacebookShareButton url={`${process.env.PUBLIC_URL}`}>  */}
                        <FacebookShareButton  url={'https://www.icab.org.bd/'} className="btn btn-outline-secondary"> 
                          <i className="fa fa-share-alt"></i> Share
                          {/* <a  className="btn btn-outline-secondary"> </a> */}
                        </FacebookShareButton>
                        {
                          !this.state.votted ?
                            <a className="btn btn-primary" onClick={()=>this.vote(pole_section_content.id)}>Vote</a>
                          :
                          <a className="btn btn-primary" onClick={()=>this.change_vote(pole_section_content.id)}>Change Vote</a>
                        }

                      </div>

                    </div>
                    <div className="row">
                      {this.state.message !='' && this.state.message_type =='success' && <div className="col-sm-12" >
                              <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                  {this.state.message}
                                  <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                              </div>
                          </div>}
                          {this.state.message !='' && this.state.message_type =='danger' && <div className="col-sm-12">
                              <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                  {this.state.message}
                                  <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                              </div>
                          </div>}
                      </div>
                    
                  </div>
                  :
                  ''
                }
                
      
              </div>
              
            </div>
      
          </section>
        );
    }
}

const mapStateToProps = (state) => ({
  home_layout: state.contents.home_layout,
})

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
    homeLayoutContent: (requestData) => dispatch(homeLayoutContent(requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ExploreSelf);