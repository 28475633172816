import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { parseString } from 'xml2js';
import { memberDetails } from '../../actions/Utils';
import { processMaker } from '../../config/Api';
import MemberLeftsidebar from './MemberLeftsidebar';
class MemberProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberinfo: [],
      session_id:null,
      case_id:null,
      iframe_url:null
    }
  }

  componentDidMount = () => {


    const requestData = {
      EnNo: localStorage.getItem('member_id'),
      
    }
    this.props.memberDetails(requestData).then((data) => {
      
      this.setState({
        memberinfo: data
      })
    });

   
  }



  static getDerivedStateFromProps(props, state) {
    return {
      committe: props.committe
    }
  }

  handleActivityModal = (content) => {
    this.setState({
      modal_content: content,
      is_open_modal: true
    })
  }

  handleCloseModal = (status) => {

    this.setState({
      modal_content: null,
      is_open_modal: false
    })

  }
  handleMemberShowHide = (status) => {

    this.setState({
      show_all_member: status
    })

  }
  handleTorShowHide = (status) => {

    this.setState({
      show_all_tor: status
    })

  }
  handleEditProfile=()=>{
    
    var process_maker_login_xml_body='<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:www="http://www.processmaker.com">\
      <x:Header/>\
      <x:Body>\
      <www:login>\
      <www:userid>'+localStorage.getItem('member_id')+'</www:userid>\
      <www:password>'+localStorage.getItem('member_pass')+'</www:password>\
      </www:login>\
      </x:Body>\
    </x:Envelope>\
    ';
    processMaker.post('',
      process_maker_login_xml_body,
    {headers:
      {'Content-Type': 'text/xml; charset=utf-8','soapAction':'http://www.processmaker.com/login'}
    }).then(res=>{
      
      parseString(res.data,(err,result)=>{
        var status=result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:loginResponse']['0']['ns1:status_code']['0'];
        if(status==0)
        {
            var session_id=result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:loginResponse']['0']['ns1:message']['0'];
            this.setState({
              session_id:session_id
            });
            window.location.href = 'https://memberservices.icab.org.bd/process/update-member.php?sid='+session_id;
            /* var xml_for_case_id='<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:www="http://www.processmaker.com">\
            <x:Header/>\
            <x:Body>\
            <www:newCaseRequest>\
            <www:sessionId>'+this.state.session_id+'</www:sessionId>\
            <www:processId>273782217614a110661bd44095486855</www:processId>\
            <www:taskId>29029882460fd18fa92c948063211299</www:taskId>\
            </www:newCaseRequest>\
            </x:Body>\
            </x:Envelope>';
            processMaker.post('',
            xml_for_case_id,
                {headers:
                  {'Content-Type': 'text/xml; charset=utf-8','soapAction':'urn:newCase'}
                }).then(res=>{
                  
                  parseString(res.data,(err,result)=>{
                    var status=result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:newCaseResponse']['0']['ns1:status_code']['0'];
                    if(status==0)
                    {
                      this.setState({
                        case_id: result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['0']['ns1:newCaseResponse']['0']['ns1:caseId']['0']
                      });
                      this.setState({
                        iframe_url: 'http://studentservices.icab.org.bd/sysworkflow/en/neoclassic/cases/cases_Open?APP_UID='+this.state.case_id+'&DEL_INDEX=1&action=draft&sid='+this.state.session_id
                        
                      });
                      window.location.href = 'http://studentservices.icab.org.bd/sysworkflow/en/neoclassic/cases/cases_Open?APP_UID='+this.state.case_id+'&DEL_INDEX=1&action=draft&sid='+this.state.session_id;
                    }
                  })
                }).catch(err=>{ console.log('process maker error');console.log(err)}); */
        }
      })
    }).catch(err=>{ console.log('process maker error');console.log(err)});
  }

  
  scrollToTestDiv = (id) => {
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });

    this.setState({
      current_tab: id
    })
  }


  render() {
    const { memberinfo } = this.state;

    const memberdata = Object.keys(memberinfo).length ? memberinfo.payload[0] : false;
    

    return (

      <Fragment>


        <section className="dashboard">
          <div className="container">

            <div className="row">

              <div className="col-md-3">

                <div className="member-dashboard-menu mb-5">

                  <MemberLeftsidebar activemenu="memberprofile" />

                </div>

              </div>

              <div className="col-md-9">

                <div className="member-dashboard-content">

                  <div className="members-title">

                    <h3>Member’s Profile</h3>

                  </div>
                  
                  
                  
                  <div className="member-box member-edit-profile">
                    {memberdata&&<form>

                      <div className="for-mobile d-none row">
                        <div className="col-md-6">
                          <div className="btn-edit-profile text-right">
                            <div className="member-profile-img">

                              {memberdata.imG_PATH&&<Fragment>
                                <img src={memberdata.imG_PATH} />

                              </Fragment>}
                            </div>
                            <div className="member-profile-edit-btn">
                              <a className="btn btn-primary" onClick={()=>this.handleEditProfile()}>Edit Profile</a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label>Full Name</label>
                          <input type="text" placeholder="Name" defaultValue={memberdata.part_Name+" "+memberdata.surName+" "+memberdata.acA_FCA+" ("+memberdata.enNo+")"} className="form-control" name="name" disabled="disabled"/>
                        </div>
                      </div>
                      
                      <div className="for-desktop row">
                        <div className="col-md-6">
                          <label>Full Name</label>
                          <input type="text" placeholder="Name" defaultValue={memberdata.part_Name+" "+memberdata.surName+" "+memberdata.acA_FCA+" ("+memberdata.enNo+")"} className="form-control" name="name" disabled="disabled"/>
                        </div>
                        <div className="col-md-6">
                          <div className="btn-edit-profile text-right">
                            <div className="member-profile-img">

                              {memberdata.imG_PATH&&<Fragment>
                                <img src={memberdata.imG_PATH} />

                              </Fragment>}
                            </div>
                            <div className="member-profile-edit-btn">
                              <a className="btn btn-primary" onClick={()=>this.handleEditProfile()}>Edit Profile</a>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      

                      <div className="row">

                        
                        <div className="col-md-6">

                          <label>Member ID</label>

                          <input type="text" placeholder="Member ID" className="form-control" name="email1" defaultValue={memberdata.enNo?memberdata.enNo:'-'} disabled="disabled"/>

                        </div>
                        <div className="col-md-6">

                          <label>Membership Status</label>

                          <input type="text" placeholder="Member ID" className="form-control" name="email1" defaultValue={memberdata.membership_Status?memberdata.membership_Status:'-'} disabled="disabled"/>

                        </div>
                        <div className="col-md-6">

                          <label>Occupational category</label>

                          <input type="text" placeholder="Practice" className="form-control" name="email1" defaultValue={memberdata.occoupation_Status?memberdata.occoupation_Status:'-'} disabled="disabled"/>

                        </div>
                        <div className="col-md-6">
                          <label>COP</label>
                          <input type="text" placeholder="Practice" className="form-control" defaultValue={memberdata.coP_Status?memberdata.coP_Status:'-'} disabled="disabled"/>
                        </div>
                        <div className="col-md-12">
                          <h2>Office Address</h2>
                        </div>
                        <div className="col-md-6">

                          <label>Position</label>

                          <textarea type="text" placeholder="Member ID" className="form-control"  value={memberdata.position?memberdata.position:'-'} disabled="disabled"/>

                        </div>
                        <div className="col-md-6">

                          <label>Organization Name</label>

                          <textarea type="text" placeholder="Member ID" className="form-control"  value={memberdata.nameOfOrg?memberdata.nameOfOrg:'-'} disabled="disabled"/>

                        </div>

                        <div className="col-md-6">

                          <label>Address</label>

                          <textarea type="text" placeholder="Office Address" className="form-control" name="email1" disabled="disabled" defaultValue={memberdata.address?memberdata.address:'-'}></textarea>

                        </div>
                        <div className="col-md-6">

                          <label>District/ Area</label>

                          <input type="text" placeholder="District/Area" className="form-control" name="email1" defaultValue={memberdata.officE_AREA?memberdata.officE_AREA:'-'} disabled="disabled"/>

                        </div>
                        <div className="col-md-6">

                          <label>Country</label>

                          <input type="text" placeholder="Country" className="form-control" name="email1" defaultValue={memberdata.officE_COUNTRY?memberdata.officE_COUNTRY:'-'} disabled="disabled"/>

                        </div>
                        <div className="col-md-12">
                          <h2>Resident Address</h2>
                        </div>
                        <div className="col-md-6">

                          <label>Address</label>

                          <textarea type="text" placeholder="Office Address" className="form-control" name="email1" disabled="disabled" defaultValue={memberdata.reS_Addrress?memberdata.reS_Addrress:'-'}></textarea>

                        </div>
                        <div className="col-md-6">

                          <label>District/ Area</label>

                          <input type="text" placeholder="District/Area" className="form-control" name="email1" defaultValue={memberdata.reS_Area_Dist?memberdata.reS_Area_Dist:'-'} disabled="disabled"/>

                        </div>
                        <div className="col-md-6">

                          <label>Country</label>

                          <input type="text" placeholder="Country" className="form-control" name="email1" defaultValue={memberdata.reS_COUNTRY?memberdata.reS_COUNTRY:'-'} disabled="disabled"/>

                        </div>

                        <div className="col-md-12">
                          <h2>Contact Info</h2>
                        </div>
                        
                        <div className="col-md-6">
                          <label>Cell Phone</label>
                          <input type="text" placeholder="Mobile No" className="form-control" name="phone1" defaultValue={memberdata.cellPhone} disabled="disabled" />
                        </div>
                        <div className="col-md-6">
                          <label>Office Phone</label>
                          <input type="text" placeholder="Mobile No" className="form-control" defaultValue={memberdata.off_Fixed_phn_No?memberdata.off_Fixed_phn_No:'-'} disabled="disabled" />
                        </div>
                        <div className="col-md-6">
                          <label>Residence Phone</label>
                          <input type="text" placeholder="Residence Phone" className="form-control" defaultValue={memberdata.reS_PHONE?memberdata.reS_PHONE:'-'} disabled="disabled" />
                        </div>
                        <div className="col-md-6">
                          <label>Office Fax</label>
                          <input type="text" placeholder="Office Fax" className="form-control" defaultValue={memberdata.off_FAX?memberdata.off_FAX:'-'} disabled="disabled" />
                        </div>
                        <div className="col-md-6">
                          <label>Primary Email ID</label>
                          <input type="email" placeholder="Email" className="form-control" name="email1" defaultValue={memberdata.emailAddress1?memberdata.emailAddress1:'-'} disabled="disabled"/>
                        </div>
                         <div className="col-md-6">
                          <label>Alternate Email ID</label>
                          <input type="email" placeholder="Email" className="form-control" name="email1" defaultValue={memberdata.emailAddress2?memberdata.emailAddress2:'-'} disabled="disabled"/>
                        </div>
                        <div className="col-md-6">
                          <label>Website</label>
                          <input type="text" placeholder="Address" className="form-control" name="address"  defaultValue={memberdata.web_Address?memberdata.web_Address:'-'}/>
                        </div>

                        <div className="col-md-12">
                          <h2>Emergency Contact </h2>
                        </div>
                        
                        <div className="col-md-6">
                          <label>Name</label>
                          <input type="text" placeholder="Mobile No" className="form-control" name="phone1" defaultValue={memberdata.emr_contact_person?memberdata.emr_contact_person:'-'} disabled="disabled" />
                        </div>
                        <div className="col-md-6">
                          <label>Phone</label>
                          <input type="text" placeholder="Mobile No" className="form-control" name="phone1" defaultValue={memberdata.emR_CONTACT_No?memberdata.emR_CONTACT_No:'-'} disabled="disabled" />
                        </div>
                        <div className="col-md-6">
                          <label>Relation</label>
                          <input type="text" placeholder="Mobile No" className="form-control" name="phone1" defaultValue={memberdata.emR_CONTACT_RElation?memberdata.emR_CONTACT_RElation:'-'} disabled="disabled" />
                        </div>
                        


                      </div>

                      

                    </form>}


                  </div>

                </div>

              </div>


            </div>

          </div>
        </section>

      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  committe: state.contents.committe_content.singlecommitte,
})

const mapDispatchToProps = (dispatch) => ({
  memberDetails: (requestData) => dispatch(memberDetails(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberProfile));