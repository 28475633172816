import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, withRouter } from "react-router-dom";
import { getExploreUpdateContent, setLayout, setPath, setPathLayout } from '../../actions/Utils';
import { image_url } from '../../config/Api';
import NoImage from '../../img/no_image.jpeg';
import Htmlcontent from '../Htmlcontent';


class StudentLanding extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: [],
      content_loading: true,
    }

  }

  componentDidMount = () => {
   
    const section_content = this.props.sec_content;
    const requestData = {
      related_type: section_content.section.related_to ? section_content.section.related_to : ''
    }
    this.props.getExploreUpdateContent(requestData).then((data) => {

      this.setState({
        content: data,
        content_loading: false,
      })
    });
  }

  removeTags = (str) => {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    var tag_remove = str.replace(/(<([^>]+)>)/ig, '');

    if (tag_remove.length > 150) {
      return tag_remove.substring(0, 150) + ' ...';
    }
    else {
      return tag_remove;
    }

    return str.replace(/(<([^>]+)>)/ig, '');
  }

  static getDerivedStateFromProps(props, state) {

    return false;
  }
  render() {


    const { content, content_loading } = this.state;

    const sec_content = this.props.sec_content;
    const pathname = this.props.location.pathname;
    //console.log(" content =", content);

    return (

      <Fragment>


        {content.length?<section className="faq">

          <div className="container">

            <div className="inner-content">

              <div className="relavent-content">

                <h4>{sec_content.section.title}</h4>

                <div className="row">
                  {content_loading ? <Fragment>Loading...</Fragment> : <Fragment>
                    {content.length ? <Fragment>
                      {content.map((row, key) => {
                        return (
                          
                          <Fragment key={key}>{pathname != row.slug?<div className="col-md-4" key={key}>

                            <div className="our-latest-news-single">


                              {row.image && <img src={`${image_url}${row.image}`} />}
                              {row.image == '' && <img src={`${NoImage}`} />}


                              <div className="our-latest-news-content">

                                <h6>{row.category} <span>{row.publish_date_format}</span></h6>

                                <h3>
                                {row.category == 'Page'&&<Link to={{ pathname: `${process.env.PUBLIC_URL}/page${row.slug}` }}>
                                  
                                  {row.title.length >100&&<Fragment>{row.title.substring(0,100)}...</Fragment>}
                                  {row.title.length <100&&<Fragment>{row.title}</Fragment>}
                                
                                </Link>}
                                {row.category != 'Page'&&<Link to={{ pathname: `${process.env.PUBLIC_URL}/news${row.slug}` }}>
                                
                                  {row.title.length >100&&<Fragment>{row.title.substring(0,100)}...</Fragment>}
                                  {row.title.length <100&&<Fragment>{row.title}</Fragment>}
                                  
                                  </Link>}
                                  

                                </h3>

                                <p>

                                  <Htmlcontent data={this.removeTags(row.short_description)} />
                                </p>

                              </div>

                            </div>

                          </div>:''}</Fragment>
                        )
                      })}
                    </Fragment> : ''}

                  </Fragment>}
                </div>

              </div>

            </div>

          </div>

        </section>:''}
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
})

const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => { dispatch(setPath(requestData)) },
  setLayout: (requestData) => { dispatch(setLayout(requestData)) },
  setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
  getExploreUpdateContent: (requestData) => dispatch(getExploreUpdateContent(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentLanding));