import qs from 'qs';
import Cookies from 'js-cookie';
import axiosInstance, { casInstance, caversion, cslDheerajApi, cpdAPI, icabInstance, version } from '../config/Api';
import {AuthConfig as authconfig} from '../config/sso.config.ts';
export const setPath = (path) => ({
  type: 'SET_PATH',
  path
});
export const setLayout = (id) => ({
  type: 'SET_LAYOUT_ID',
  id
});
export const setPathLayout = (data) => ({
  type: 'SET_PATH_LAYOUT',
  data
});

export const initHeader = (data) => ({
  type: 'INIT_HEADER',
  data
});
export const setHeaderLoading = (status) => ({
  type: 'SET_HEADER_LOADING',
  status
});
export const setFooterLoading = (status) => ({
  type: 'SET_FOOTER_LOADING',
  status
});




export const getHeaderSection = () => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/header', qs.stringify()).then(res => {
      if (Object.keys(res.data).length) {
        dispatch(initHeader(res.data));
        dispatch(setHeaderLoading(false));
      }
      else {
        dispatch(setHeaderLoading(false));
      }
      return res.data;
    });
  }
}

// Assuming you have already imported the required dependencies:
// import axios from 'axios';
// import Cookies from 'js-cookie';

export const retrieveToken = (code) => {
  // Create a new URLSearchParams object to store the request parameters
  const params = new URLSearchParams();
  params.append('grant_type', 'authorization_code');
  params.append('scope', authconfig.scope);
  params.append('code', code);

  // Prepare the 'Authorization' header using the clientId and clientSecret
  const authHeader = 'Basic SUNBQi1XRUJTSVRFOmNwZF91c2VyX2ljYWI=';

  // Create the headers object to be sent with the request
  const headers = {
    'Authorization': authHeader,
  };

  // Assuming you have defined 'axiosInstance' elsewhere in your code with proper configuration
  // For example:
  // import axios from 'axios';
  // const axiosInstance = axios.create({
  //   baseURL: 'http://auth.icab.org.bd', // Set the base URL of your API server
  // });

  return (dispatch) => {
    return axiosInstance.post(authconfig.token_url, params, { headers })
      .then(response => {
        // Assuming the token is returned in the 'data' property of the response
        const token = response.data;
        return token;
      })
      .catch(err => {
        console.log("=============", err);
        return null;
      });
  };
};


export const initTopMenuHeader = (data) => ({
  type: 'INIT_TOPMENU',
  data
});

export const getTopMenu = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/topmenu', qs.stringify()).then(res => {
      if (Object.keys(res.data).length) {
        dispatch(initTopMenuHeader(res.data));
        dispatch(setHeaderLoading(false));
      }
      else {
        dispatch(setHeaderLoading(false));
      }
      return res.data;
    });
  }
}
export const initRightSiteMenuHeader = (data) => ({
  type: 'INIT_RIGHTSITE',
  data
});

export const getRightSiteMenu = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/rightmenu', qs.stringify()).then(res => {
      if (Object.keys(res.data).length) {
        dispatch(initRightSiteMenuHeader(res.data));
      }

      return res.data;
    });
  }
}

export const initFooter = (data) => ({
  type: 'INIT_FOOTER',
  data
});


export const sendContactus = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'sendContactus', qs.stringify(parms)).then(res => {

      return res;
    });
  }

}
export const getaQuote = (parms) => {


  return (dispatch) => {
    return axiosInstance.post(version + 'sendContactus', qs.stringify(parms)).then(res => {

      return res.data;
    });
  }
}
export const getFooterSection = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/footer_navigation', qs.stringify()).then(res => {
      // console.log("getFooterSection section = ",res.data);
      if (Object.keys(res.data).length) {
        dispatch(initFooter(res.data));
        dispatch(setFooterLoading(false));
      }
      else {
        dispatch(setFooterLoading(false));
      }
      return res.data;
    });
  }
}
export const getPubFooterSection = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/pubfooter_navigation').then(res => {
      // console.log("getFooterSection section = ",res.data);
      if (Object.keys(res.data).length) {
        dispatch(initFooter(res.data));
        dispatch(setFooterLoading(false));
      }
      else {
        dispatch(setFooterLoading(false));
      }
      return res.data;
    });
  }
}



export const contentAdded = (data, id) => ({
  type: 'ADDED_CONTENT',
  data: data,
  id: id
});

export const contentLoadStatus = (id) => ({
  type: 'CONTENT_LOAD_STATUS',
  id: id
});


export const sectionandContent = (parms) => {

  var string = '';
  var length = Object.entries(parms).length;
  Object.entries(parms).forEach((ele, key) => {
    string += ele[0] + '=' + ele[1]
    if (length - 1 !== key) {
      string += '&'
    }
  });

  return (dispatch) => {
    return axiosInstance.get(version + 'get/contents?' + string, qs.stringify()).then(res => {
      // console.log("sectionandContent section = ",res.data);
      if (Object.keys(res.data).length) {
        dispatch(contentAdded(res.data, parms.menu_id));
      }
      if (Object.keys(res.data).length === 0) {
        dispatch(contentLoadStatus(parms.menu_id));
      }
      return res;
    });
  }
}


export const initRoute = (data) => ({
  type: 'INIT_ROUTES',
  data
});

export const initpagename = (data) => ({
  type: 'INIT_PAGE_NAME',
  data
});


export const getRoutes = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/routes', qs.stringify()).then(res => {
      if ((res.data).length) {
        dispatch(initRoute(res.data));
        // console.log(" res.data = ",res.data);
        // dispatch(initpagename(res.data));
      }
      return res.data;
    });
  }
}

export const initPubRoute = (data) => ({
  type: 'INIT_PUBLICATION_ROUTES',
  data
});
export const getPubRoutes = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/pubroutes', qs.stringify()).then(res => {
      if ((res.data).length) {
        dispatch(initPubRoute(res.data));

      }
      return res.data;
    });
  }
}
export const setSetting = (data) => ({
  type: 'SET_SETTING',
  data
});
export const setSearchKey = (search) => ({
  type: 'SET_SEARCH_KEY',
  search
});


export const getSetting = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/setting').then(res => {
      if (Object.keys(res.data).length) {
        dispatch(setSetting(res.data));
      }
      return res.data;
    });
  }
}

export const setAllNavigation = (data) => ({
  type: 'INIT_HEADER',
  data
});

export const getAllNavigation = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/all_navigation').then(res => {
      if ((res.data).length) {
        dispatch(setAllNavigation(res.data));
      }
      return res.data;
    });
  }
}

export const setTopNavigation = (data) => ({
  type: 'INIT_TOPMENU',
  data
});


export const getTopNavigation = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/navigation', {params:parms }).then(res => {
      if ((res.data).length) {
        dispatch(setTopNavigation(res.data));
      }
      return res.data;
    });
  }
}
export const setHeaderNavigation = (data) => ({
  type: 'INIT_HEADERMENU',
  data
});


export const getHeaderNavigation = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/navigation', {params:parms }).then(res => {
      if ((res.data).length) {
        dispatch(setHeaderNavigation(res.data));
      }
      return res.data;
    });
  }
}

export const initSearchContent = (data) => ({
  type: 'INIT_SEARCH_CONTENT',
  data
});
export const addSearchContent = (data) => ({
  type: 'ADD_SEARCH_CONTENT',
  data
});

export const getsearch = (parms) => {

  var string = '';
  var length = Object.entries(parms).length;
  Object.entries(parms).forEach((ele, key) => {
    string += ele[0] + '=' + ele[1]
    if (length - 1 !== key) {
      string += '&'
    }
  });

  return (dispatch) => {
    return axiosInstance.get(version + 'get/contentsearch?' + string).then(res => {

      if (Array.isArray(res.data)) {
        if (parms.initial === true) {
          dispatch(initSearchContent(res.data));
        }
        else {
          dispatch(addSearchContent(res.data));
        }

      }
      return res.data;
    });
  }
}


export const detailsContent = (data) => ({
  type: 'SET_DETAILS_CONTENT',
  data
});

export const getContentDetails = (parms) => {

  var string = '';
  var length = Object.entries(parms).length;
  Object.entries(parms).forEach((ele, key) => {
    string += ele[1]
    if (length - 1 !== key) {
      string += '&'
    }
  });

  return (dispatch) => {
    return axiosInstance.get(version + 'get/content-details?' + string).then(res => {

      dispatch(detailsContent(res.data));
      return res.data;
    });
  }
}






//Mobarak Hossen

export const SetHomeContentAdded = (data) => ({
  type: 'INIT_HOME_LAYOUT',
  data: data,

});

export const homeLayoutContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/home').then(({ data }) => {
      // console.log("sectionandContent section = ", data);
      if (Object.keys(data).length) {
        dispatch(SetHomeContentAdded(data));

        if(data.page_data.page_meta_data) {
          dispatch(SetMetaData(data.page_data.page_meta_data));
        }
      
      }
      return data;

    });
  }
}
export const SetFaqContentAdded = (data) => ({
  type: 'INIT_FAQ_LAYOUT',
  data: data,

});

export const faqLayoutContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/sectionandContent', {params:parms}).then(({ data }) => {

      if (Object.keys(data).length) {
        dispatch(SetFaqContentAdded(data));
      }
      return data;

    });
  }
}
export const SetContactusContentAdded = (data) => ({
  type: 'INIT_CONTACTUS_LAYOUT',
  data: data,

});

export const contactusLayoutContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/sectionandContent', {params:parms}).then(({ data }) => {

      if (Object.keys(data).length) {
        dispatch(SetContactusContentAdded(data));
      }
      return data;

    });
  }
}

export const SetPageContent = (data) => ({
  type: 'INIT_PAGE_LAYOUT',
  data: data,

});

export const PageContent = (parms) => {

  let requestData = {
    slug: "/" + parms
  }
  return (dispatch) => {
    return axiosInstance.get(version + 'get/page', { params:requestData }).then(({ data }) => {
      console.log("data Mobarak=",data);
      if (Object.keys(data).length) {
        dispatch(SetPageContent(data));

        if(data.page_data.page_meta_data) {
          dispatch(SetMetaData(data.page_data.page_meta_data));
        }
        
      }
      return data;

    });
  }
}
export const getEvent = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/getEvent').then(({ data }) => {

      return data;

    });
  }
}
export const getExploreUpdateContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/getExploreUpdateContent',{params:parms}).then(({ data }) => {

      return data;

    });
  }
}

export const pageSectionContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/sectionandContent', {params:parms}).then(({ data }) => {
      return data;

    });
  }
}

export const newsSubscribe = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'newsSubscribe', qs.stringify(parms)).then(res => {
      return res;
    });
  }

}


export const SetNewsContent = (data) => ({
  type: 'INIT_NEWS_LAYOUT',
  data: data,

});

export const NewsContent = (parms) => {

  let requestData = {
    slug: "/" + parms
  }
  return (dispatch) => {
    return axiosInstance.get(version + 'get/web_news', { params:requestData }).then(({ data }) => {
      if (Object.keys(data).length) {
        dispatch(SetNewsContent(data));

        const metadata = {
          meta_title:data.news_data.title,
          meta_description:data.news_data.short_description,
          meta_keywords:'ICAB',
        }
        dispatch(SetMetaData(metadata));
      }
      return data;

    });
  }
}


export const getAllNewsEvent = (category) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/getAllNewsEvent', {params:{ 'category': category }}).then(({ data }) => {

      return data;

    });
  }
}

export const Search = (query) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/search', {params:{ 'search_query': query }}).then(({ data }) => {

      return data;

    });
  }
}

export const MemberData = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/council-member').then(({ data }) => {

      return data;

    });
  }
}

export const getAlbums = (parms) => {
    
  return (dispatch) => {
    return  axiosInstance.get(version+'get/getAlbums',{
      params: parms
    }).then(({data}) => {
     return data
    });
  }
}
export const getGallerys = (parms) => {
    
  return (dispatch) => {
    return  axiosInstance.get(version+'get/getgallerys',{
      params: parms
    }).then(({data}) => {
     return data
    });
  }
}


export const SetWebNewsCategory = (data) => ({
  type: 'INIT_WEB_NEWS_CATEGORY',
  data: data,
});

export const WebNewsCategory = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/getWebNewsCateogry').then(({ data }) => {
      
    dispatch(SetWebNewsCategory(data));
      
      return data;

    });
  }
}
export const SetWebNewsFilter = (data) => ({
  type: 'INIT_WEB_NEWS_FILTER',
  data: data,
});

export const WebNewsFilter = (parms) => {
  return (dispatch) => {
    return axiosInstance.post(version + 'get/getWebNewsFilter',qs.stringify(parms)).then(({ data }) => {
        dispatch(SetWebNewsFilter(data));
      // console.log("data yer =",data);
      if(data.length) {
        dispatch(SetWebNewsFilter(data));
      }
     
      return data;

    });
  }
}
export const SetGroupOfCommittet = (data) => ({
  type: 'INIT_GROUPOF_COMMITTE',
  data: data,
});

export const getGroupOfCommitte = (parms) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/getCommittee',{params:parms}).then(({ data }) => {
        dispatch(SetGroupOfCommittet(data));
      if(data.length) {
        dispatch(SetGroupOfCommittet(data));
      }

      const meta_data = {
        meta_title:'ICAB | Committees ',
        meta_description:'',
        meta_keywords:'ICAB, ICAB Committees',
      }
      dispatch(SetMetaData(meta_data));

      return data;
    });
  }
}


export const SetSingleOfCommittet = (data) => ({
  type: 'INIT_SINGLEOF_COMMITTE',
  data: data,
});

export const getSingleOfCommitte = (parms) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/getCommittee',{params:parms}).then(({ data }) => {
        dispatch(SetSingleOfCommittet(data));
     
      if(data.length) {
        dispatch(SetSingleOfCommittet(data));
      }

      const meta_data = {
        meta_title:'ICAB | '+parms.slug,
        meta_description:'',
        meta_keywords:'ICAB, ICAB Committees',
      }
      dispatch(SetMetaData(meta_data));
      
      return data;

    });
  }
}


export const CAMemberData = (requestData) => {
  
  return (dispatch) => {
    return casInstance.get(caversion + 'memberinfopagination',{params:requestData }).then(({ data }) => {

      return data;

    });
  }
}


export const memberDetails = (requestData) => {
  
  return (dispatch) => {
    return icabInstance.get('IcabApi/TWCPDevent/GetMemberDetails',{params:requestData }).then(({ data }) => {

      return data;

    });
  }
}

export const cpdInfo = (requestData) => {
  
  return (dispatch) => {
    return icabInstance.get('IcabApi/TWCPDevent/GetCPDinformation',{params:requestData }).then(({ data }) => {

      return data;

    });
  }
}

export const getCslDheerajapi = (requestData,actionMethod,methodname = 'get') => {
  
  return (dispatch) => {

  if(methodname == 'get') {
    return cslDheerajApi.get(actionMethod,{params:requestData }).then(({ data }) => {

      return data;

    });
  }
  else if (methodname == 'post') {

  }
    
  }
}

export const getCreditHours = (requestData, actionMethod, methodname = 'get') => {
  
  return (dispatch) => {

  if(methodname == 'get') {
    return cpdAPI.get(actionMethod, {params:requestData }).then(({ data }) => {

      return data;

    });
  }
  else if (methodname == 'post') {

  }
    
  }
}


export const CAFirmData = (requestData) => {
  
  return (dispatch) => {
    return casInstance.get(caversion + 'firminfo',{params:requestData }).then(({ data }) => {

      return data;

    });
  }
}

export const setModalPopupStatus = (data) => ({
  type: 'SET_MODAL_POPUPS',
  data: data,
});
export const initModalPopupAds = (data) => ({
  type: 'INIT_MODAL_POPUP_ADS',
  data: data,
});

export const getHomePageModal = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/modalPopup').then(({ data }) => {
      dispatch(initModalPopupAds(data));
      return data;

    });
  }
}



export const setMemberToken = (token) => ({
  type: 'SET_MEMBER_TOKEN',
  data: token,
});

export const userLogin = (requestData) => {
  
  return (dispatch) => {
    return casInstance.post(caversion + 'userlogin',requestData).then(({ data }) => {
      if(data.message){
        dispatch(setMemberToken(data.accesstoken));
      }
      else {
        dispatch(setMemberToken(''));
      }
      return data;

    });
  }
}
export const addPushWebToken = (requestData) => {
  
  return (dispatch) => {
    return axiosInstance.post(version + 'pushwebnitification',qs.stringify(requestData)).then(({ data }) => {
      return data;

    });
  }
}

export const SetMetaData = (data) => ({
  type: 'INIT_META_DATA',
  data: data,

});

export const getMetaData = (parms) => {

  return (dispatch) => {
    console.log("get meta data =",parms);
    // return axiosInstance.get(version + 'get/sectionandContent', {params:parms}).then(({ data }) => {

    //   if (Object.keys(data).length) {
    //     dispatch(SetMetaData(data));
    //   }
    //   return data;

    // });
  }
}