import InnerHTML from 'dangerously-set-inner-html';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { setLayout, setPath, setPathLayout } from '../../actions/Utils';
import { image_url } from '../../config/Api';

class WhoWeAre extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      home_layout: {
        content: null
      },

    }

  }

  // componentDidMount = () => {



  // }

  static getDerivedStateFromProps(props, state) {

    return {
      home_layout: props.home_layout
    }
  }

  render() {
    const content = this.state.home_layout.content;
    var who_we_are = [];
    var section_data=[];
    if (content.page_data) {

      var page_section = content.page_data.section;

      section_data = page_section.find((row) => {
        if (row.name.toLowerCase() == 'who we are') {
          return true;
        }
        else {
          return false;
        }
      });
      if (section_data) {
        var who_we_are = section_data.contents;
     
      }
    }

    //console.log(" who_we_are =",who_we_are);

    return (
      who_we_are.length>0 ? 
        <section className="who-we-are">

          <div className="section-title-one">

            <h1>{section_data.title}</h1>

          </div>

          
          <div className="who-we-are-main">

            <div className="container">

              <div className="row">
                
                <div className="col-md-6">
                  
                  <div className="who-we-are-banner">
                    
                    <img src={`${image_url}${who_we_are[0]['images'][0]['image']}`} />

                  </div>

                </div>

                <div className="col-md-6">
                  
                  <div className="who-we-are-content">

                  {who_we_are.length&&<Fragment>
                
                  {who_we_are[0] != null&&<InnerHTML html={who_we_are[0]['description']} />}
                  
                  </Fragment>}

                  </div>

                </div>

              </div>

            </div>

          </div>

        </section>
        :
        ''

      );
  }
}

const mapStateToProps = (state) => ({
  headers: state.header,
  setting: state.utils.setting,
  home_layout: state.contents.home_layout,
})

const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => { dispatch(setPath(requestData)) },
  setLayout: (requestData) => { dispatch(setLayout(requestData)) },
  setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(WhoWeAre);