import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getSingleOfCommitte } from '../../actions/Utils';
import MemberLeftsidebar from './MemberLeftsidebar';

class CopCertificate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      content_loading: true,
      is_open_modal: false,
      modal_content: null,
      show_all_member: false,
      show_all_tor: false,
      current_tab: 'contactdetails-data'
    }

  }

  componentDidMount = () => {

    const slug = this.props.match.params.slug;
    const requestData = {
      slug: slug
    }
    this.props.getSingleOfCommitte(requestData).then((data) => {

      this.setState({
        content: data,
        content_loading: false,
      })
    });
  }



  static getDerivedStateFromProps(props, state) {
    return {
      committe: props.committe
    }
  }

  handleActivityModal = (content) => {
    this.setState({
      modal_content: content,
      is_open_modal: true
    })
  }

  handleCloseModal = (status) => {

    this.setState({
      modal_content: null,
      is_open_modal: false
    })

  }
  handleMemberShowHide = (status) => {

    this.setState({
      show_all_member: status
    })

  }
  handleTorShowHide = (status) => {

    this.setState({
      show_all_tor: status
    })

  }

  scrollToTestDiv = (id) => {
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });

    this.setState({
      current_tab: id
    })
  }


  render() {


    const { content_loading, is_open_modal, show_all_member, show_all_tor, current_tab } = this.state;

    const content_committee = this.props.committe;
    const content = content_committee.content;

    var breadcurmbs = [];

    if (Object.keys(content).length) {
      breadcurmbs = [
        {
          url: content.committee.slug,
          title: content.committee.name,
        },
        {
          url: '/',
          title: 'Committee',
        },
        {
          url: '/',
          title: 'Home',
        },
      ]
    }



    return (

      <Fragment>


        <section className="dashboard">
          <div class="container">

            <div class="row">

              <div class="col-md-3">

                <div class="member-dashboard-menu mb-5">

                  <MemberLeftsidebar activemenu="copcertificate"/>

                </div>

              </div>

              <div class="col-md-9">

                <div class="member-dashboard-content">

                  <div class="members-title">

                    <h3>COP Certificate</h3>

                  </div>

                  <div class="row">


                    <div className="col-md-12">
                      {/* <iframe
                        src="https://cebudailynews.inquirer.net/"
                        width="100%"
                        height="700"
                        onLoad={this.hideSpinner}
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                      /> */}
                      <h2>Process Automation for application of COP in progress</h2>
                    </div>



                  </div>

                 

                  

                </div>

              </div>


            </div>

          </div>
        </section>

      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  committe: state.contents.committe_content.singlecommitte,
})

const mapDispatchToProps = (dispatch) => ({
  getSingleOfCommitte: (requestData) => dispatch(getSingleOfCommitte(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CopCertificate));