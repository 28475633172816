import InnerHTML from 'dangerously-set-inner-html';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { PageContent } from '../../actions/Utils';
import MemberLeftsidebar from './MemberLeftsidebar';
class MemberDirecotry extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      content_loading: true,
      is_open_modal: false,
      modal_content: null,
      show_all_member: false,
      show_all_tor: false,
      current_tab: 'contactdetails-data'
    }

  }

  componentDidMount = () => {

    const slug = this.props.match.params.slug;
    this.props.PageContent('members-directory').then((res)=>{
    });
  }



  static getDerivedStateFromProps(props, state) {
    return {
      committe: props.committe
    }
  }

  handleActivityModal = (content) => {
    this.setState({
      modal_content: content,
      is_open_modal: true
    })
  }

  handleCloseModal = (status) => {

    this.setState({
      modal_content: null,
      is_open_modal: false
    })

  }
  handleMemberShowHide = (status) => {

    this.setState({
      show_all_member: status
    })

  }
  handleTorShowHide = (status) => {

    this.setState({
      show_all_tor: status
    })

  }

  scrollToTestDiv = (id) => {
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });

    this.setState({
      current_tab: id
    })
  }


  render() {

    const {page_data}=this.props.page_content.content;

    const { content_loading, is_open_modal, show_all_member, show_all_tor, current_tab } = this.state;

   

    var breadcurmbs = [];




    return (

      <Fragment>


        <section className="dashboard">
          <div class="container">

            <div class="row">

              <div class="col-md-3">

                <div class="member-dashboard-menu mb-5">

                  <MemberLeftsidebar activemenu="member-directory"/>

                </div>

              </div>

              <div class="col-md-9">

                <div class="member-dashboard-content">

                  <div class="members-title">

                    <h3>Members Directory</h3>

                  </div>

                  <div class="row">


                    <div className="col-md-12">
                    
                      {
                        (page_data && page_data.contents)?
                        <InnerHTML html={page_data.contents.description} />
                        :''

                      }
                    </div>



                  </div>

                 

                  

                </div>

              </div>


            </div>

          </div>
        </section>

      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  page_content:state.contents.page_content,
})

const mapDispatchToProps = (dispatch) => ({
  PageContent: (requestData) => dispatch(PageContent(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberDirecotry));