import 'moment-timezone';
import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { setLayout, setPath, setPathLayout } from '../../actions/Utils';
import { image_url } from '../../config/Api';




  class MemberShip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            home_layout: {
                content: null
            },

        }

    }

    static getDerivedStateFromProps(props, state) {

        return {
            home_layout: props.home_layout
        }
    }

    render() {
        const content = this.state.home_layout.content;
        var sliderdata = [];
        if (content.page_data) {

            var page_section = content.page_data.section;

            const section_data = page_section.find((row) => {
                if (row.name.toLowerCase() == 'international membership') {
                    return true;
                }
                else {
                    return false;
                }
            });
            if (section_data) {
                var section_content = section_data.contents;
                if (section_content.length) {
                  sliderdata = section_content[0].slider;
                }
                // console.log(" section_content = ", section_content);
            }
        }

      var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        arrows:true,
      };

  return (
    
      sliderdata.length >0 ?
    
      <section className="global-network">

      <div className="container">
        <div className="section-title-one">
            
            <h1>Global Network</h1>

          </div>

        <div className="membership-slider">
          <Slider {...settings}>

            
            {sliderdata && sliderdata.map((row,key)=>{
              return(
                <Fragment key={key}>
                      <div>

                      <div className="global-network-single">

                          <img src={`${image_url}${row.long_image}`} />

                      </div>

                      </div>
                </Fragment>
              )
            })}
          </Slider>

        </div>
      </div>
    </section>
    :''
  );
}
  }

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  home_layout: state.contents.home_layout,
})


const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => { dispatch(setPath(requestData)) },
  setLayout: (requestData) => { dispatch(setLayout(requestData)) },
  setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberShip));

