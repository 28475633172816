import { createStore, applyMiddleware,combineReducers,compose } from 'redux';
import thunk from 'redux-thunk';
import Users from '../reducers/Users';
import Header from '../reducers/Header';
import Footer from '../reducers/Footer';
import Content from '../reducers/Content';
import Utils from '../reducers/Utils';

import PublicationHeader from '../publication/reducers/Header';
import PublicationFooter from '../publication/reducers/Footer';
import PublicationContent from '../publication/reducers/Content';
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__||compose;
const composeEnhancers = compose;

export default ()=>{
    const store = createStore(combineReducers({
        user:Users,
        header:Header,
        footer:Footer,
        contents:Content,
        utils:Utils,
        pubheader:PublicationHeader,
        pubfooter:PublicationFooter,
        pubcontent:PublicationContent,
    }),composeEnhancers(applyMiddleware(thunk)));
    return store;
}