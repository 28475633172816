import React, { Fragment } from 'react';
import { image_url } from '../../config/Api';
import defaultBanner from '../../img/banner/bg3.png';

export default   ({title,menu_name,banner})=> {
    return (
        <Fragment>
            <section className="innerpage-header header-left-align">

            <img src={banner ? image_url+banner : defaultBanner}  alt="Banner"/>

            {/* <div className="container">

            <div className="innerpage-header-content">

                <h5>{menu_name} </h5>

                <h1>{title}</h1>

                <p> {short_description}</p>

            </div>

            </div> */}

            </section>
        </Fragment>
    )
}
