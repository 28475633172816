import qs from 'qs';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getSetting, setPathLayout } from "../actions/Utils";
import '../App.css';
import HtmlHelmet from '../components/common/HtmlHelmet';
import axiosInstance, { version } from '../config/Api';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
        validator:{
            name:'',
            email:'',
            phone:'',
            /* designation:'',
            organization:'', */
        },
        message:'',
        message_type:'',
    };
    this.refs = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    console.log(this.props);
  }
  handleMessageClose = () => {
    this.setState({message:'',message_type:''})
  }

  handleSubmit= (event)=> {
    event.preventDefault();
      const userFormData = {
          name:this.name.value,
          email:this.email.value,
          phone:this.phone.value,
          designation:this.designation.value,
          organization:this.organization.value,
          address:this.address.value,
          comments:this.comments.value,
      }
    
      const resetValidator = {
          name:'',
          email:'',
          phone:'',
      }
      var validation = {};
    
      if(userFormData.name == '') {
          validation.name = 'The name field is required.';
      }
      
      if(userFormData.email == '') {
          validation.email = 'The email field is required.';
      }

      if(userFormData.phone == '') {
          validation.phone = 'The phone field is required.';
      }
      /* if(userFormData.designation == '') {
          validation.designation = 'The message field is required.';
      }
      if(userFormData.organization == '') {
          validation.organization = 'The message field is required.';
      }

      if(userFormData.organization == '') {
        validation.address = 'The message field is required.';
      } */
    
      if(Object.keys(validation).length) {
          this.setState({validator:{...resetValidator,...validation},message:''});
          
      }
      else {
      
        axiosInstance.post(version+'register_user',qs.stringify(userFormData)).then((res) => {
          this.name.value='';
          this.email.value='';
          this.phone.value='';
          this.designation.value='';
          this.organization.value='';
          this.address.value='';
          this.comments.value='';
              this.setState({message:res.data,message_type:'success',validator:{...resetValidator}})
          })
          .catch(error => {
            
          
              if(error.response) {
                var html = '';
                  if(Object.keys(error.response.data).length) {

                      for (const [key, value] of Object.entries(error.response.data)) {
                          //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                          html += `${value}`;
                      }
                      
                  }
                  // if(Object.keys(error.response.data).length) {
                  //     for (const [key, value] of Object.entries(error.response.data)) {
                  //         //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                  //         html += `${value}`;
                  //     }
                      
                  // }
                
              } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the 
                  // browser and an instance of
                  // http.ClientRequest in node.js
                  // console.log('error.request ',error.request);
              } else {
                  // Something happened in setting up the request that triggered an Error
                  // console.log('Error', error.message);
              }
              if(html){
                  this.setState({message:html,message_type:'danger',validator:{...resetValidator}})
              }
              
          })
      }
      
    
  }

  setPathLayout = (parms) => {
    this.props.setPathLayout(parms);
  }
  getSetting = async () => {
    let data = await this.props.getSetting();
  }
  

  render() {
    const {validator} = this.state;
    const meta_data = {
      meta_title:'Member Register',
      meta_description:'',
      meta_keywords:'ICAB, ICAB Member Register',
    }
    return (
      <Fragment>

        <HtmlHelmet custom_meta = {meta_data} />

        <section className="sign-in">
        <div className="container">
          
          <div className="form-title">
            <h3>Get Registered with ICAB</h3>
          </div>
          <div className="form-title">
            {this.state.message !='' && this.state.message_type =='success' && <div className="col-sm-12" >
                    <div className="alert  alert-success alert-dismissible fade show" role="alert">
                        {this.state.message}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>}
                {this.state.message !='' && this.state.message_type =='danger' && <div className="col-sm-12">
                    <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                        {this.state.message}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>}
          </div>
          <div className="sign-in-form">
            <form onSubmit={this.handleSubmit}>
              <input
                type="text"
                placeholder="Full Name"
                ref={(input) => (this.name = input)}
                className={
                  (validator.name ? "input-erro " : "") + "form-control"
                }
              />
              {validator.name && (
                <span className="help-block form-text text-danger">
                  {validator.name}
                </span>
              )}
              <input
                type="email"
                className={
                  (validator.email ? "input-erro " : "") + "form-control"
                }
                placeholder="Email Address"
                ref={(input) => (this.email = input)}
              />
              {validator.email && (
                <span className="help-block form-text text-danger">
                  {validator.email}
                </span>
              )}
              <input
                type="number"
                ref={(input) => (this.phone = input)}
                placeholder="Mobile Number"
                className={
                  (validator.phone ? "input-erro " : "") + "form-control"
                }
              />
              {validator.phone && (
                <span className="help-block form-text text-danger">
                  {validator.phone}
                </span>
              )}

              <input
                type="text"
                placeholder="Designation"
                ref={(input) => (this.designation = input)}
                className={
                  (validator.designation ? "input-erro " : "") + "form-control"
                }
              />
              {validator.designation && (
                <span className="help-block form-text text-danger">
                  {validator.designation}
                </span>
              )}

              <input
                type="text"
                placeholder="Organization"
                ref={(input) => (this.organization = input)}
                className={
                  (validator.organization ? "input-erro " : "") + "form-control"
                }
              />
              {validator.organization && (
                <span className="help-block form-text text-danger">
                  {validator.organization}
                </span>
              )}

              <input
                type="text"
                placeholder="Address"
                ref={(input) => (this.address = input)}
                className={
                  (validator.address ? "input-erro " : "") + "form-control"
                }
              />
              {validator.address && (
                <span className="help-block form-text text-danger">
                  {validator.address}
                </span>
              )}
              <textarea
                placeholder="Comments"
                ref={(input) => (this.comments = input)}
                className={
                  (validator.comments ? "input-erro " : "") + "form-control"
                }
              ></textarea>
              {validator.comments && (
                <span className="help-block form-text text-danger">
                  {validator.comments}
                </span>
              )}
              <input
                type="submit"
                value="Submit"
                className="btn"
                /* onClick={this.props.handleSubmit} */
              />
            </form>

            {/* <div className="dont-have-account">

            <p>Already have an account? <a href="#"> Sign In </a></p>

            </div> */}
          </div>
        </div>
      </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  utils: state.utils,
  header: state.header,
  contents: state.contents,
  footer: state.footer,
  layout_id: state.utils.layout_id,
  routemenu: state.utils.routemenu,
  setting: state.utils.setting,

})

const mapDispatchToProps = dispatch => ({
  getSetting: () => dispatch(getSetting()),
  setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));